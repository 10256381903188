import React from 'react'
import Team from "./sections/Team";
import Counts from "./sections/Counts";
import Services from "./sections/Services";
import Features from "./sections/Features";
import Testimonials from "./sections/Testimonials";
import AboutUs from "./sections/AboutUs";
import Hero from "./sections/Hero";
function MainContent() {
  return (
    <>
            <Hero />
            <AboutUs />
            <Counts />
            <Services />
            <Features />
            <Testimonials />
            <Team />
    </>
  )
}

export default MainContent