import { Dialog } from 'primereact/dialog';
import axios from 'axios';
import { useState } from 'react';
import { useEffect } from 'react';
export default function ApproveSludgePlanShow(props) {
    const [approveData, setApproveData] = useState([]);
    const [image, setImage] = useState(null);
    useEffect(() => {
        if (props.show === true) {
            try {
                async function getImage() {
                    const axiosConfigration = {
                        responseType: "arraybuffer", headers: {
                            Accept: "application/json",
                            Authorization: "Bearer " + props.token,
                        }
                    }
                    let res = await axios.get(axios.defaults.baseURL + `/sludgeApprove/getById/${props.id}`, axiosConfigration)

                    if (res.status === 200) {

                        setImage(URL.createObjectURL(new Blob([res.data])))
                    }
                }

                async function getData() {
                    const axiosConfigration = {
                        headers: {
                            Accept: "application/json",
                            Authorization: "Bearer " + props.token,
                        }
                    }
                    let res = await axios.get(axios.defaults.baseURL + `/sludgeApprove/getByIdData/${props.id}`, axiosConfigration)
                    if (res.status === 200) {
                        setApproveData(res.data)
                    }
                }

                getData();
                getImage();
                }catch(err) {

                }
            }

    }, [props.show])

    return (
        <>
            <Dialog header="بيانات اعتماد خطة" visible={props.show} style={{ maxWidth: '450px', direction: "rtl" }} onHide={props.close}>
                <div>
                    <div className="row p-2 border-bottom" style={{ marginBottom: "10px",textAlign:"center"}}>
                        <div className="col-md-12">
                            <div className="d-flex flex-column my-2">
                                <label htmlFor="commit_start" className="mb-2 fs-6"> تاريخ بداية الاعتماد</label>
                                <input type='date' value={approveData.commit_start} className='form-control text-center' readOnly />
                            </div>
                            <div className="d-flex flex-column my-2">
                                <label htmlFor="commit_end" className="mb-2 fs-6"> تاريخ نهاية الاعتماد</label>
                                <input type='date' value={approveData.commit_end} className='form-control text-center' readOnly />
                            </div>
                            <div className="d-flex flex-column my-2">
                                <label htmlFor="firstName">رقم الجلسة</label>
                                <input
                                    readOnly
                                    type="text"
                                    value={approveData.meet_num}

                                    className='form-control text-center'
                                />
                            </div>
                            <div className="d-flex flex-column my-2">
                                <label htmlFor="meet_date" className="mb-2 fs-6"> تاريخ الجلسة</label>
                                <input type='date' value={approveData.meet_date} className='form-control text-center' readOnly />
                            </div>
                        </div>
                        <div className="col-md-12"></div>
                        <div className="d-flex flex-column my-2">
                            <label htmlFor="doc" className='mb-2'>  شهادة الاعتماد</label>
                            <img src={image} alt="img" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </Dialog>
        </>
    )
}