
export default function CardItemN(props) {
    return <>
        <div className="col-sm-3 mb-4 my-0 " style={{margin:"-4px"}}>
            <div class="card-stats card">

                <div class="card-body">
                    <div class="row">
                        <div class="col">
                            <h6 class="text-uppercase text-muted mb-0 card-title py-2">{props.title}</h6>
                            <span class="h5 font-weight-bold mb-0 mx-3">{props.value}</span>
                        </div>
                        <div class="col-auto col">
                            {/* <div class={"icon icon-shape text-white rounded-circle shadow " + props.icon}>
                                <i class="fa-brands fa-reacteurope"></i>
                            </div> */}
                            <div class={"icon icon-shape text-white rounded-circle shadow "}>
                            <img src={props.image} style={{ width:"50px",height:"50px"}}/>
                            </div>
                            
                        </div>
                    </div>
                    {/* <p class="mt-3 mb-0 text-sm">
                        <span class="text-success mr-2">
                            <i class="fa fa-arrow-up"></i> 3.48%</span>
                        <span class="text-nowrap">Since last month</span>
                    </p> */}
                </div>
            </div>
        </div>
    </>
}