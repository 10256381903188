import React from 'react';
import ReactDOM from 'react-dom/client';
import './backend.css';
import './frontend.css'
import App from './App';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "primereact/resources/themes/lara-light-blue/theme.css";
import "primereact/resources/primereact.min.css";
import './assets/all.min.css'
import {BrowserRouter as Router} from "react-router-dom";
import UserProvider from './Context';
import { PrimeReactProvider } from 'primereact/api';
import Aos from 'aos';
import 'aos/dist/aos.css';
const root = ReactDOM.createRoot(document.getElementById('root'));
Aos.init({duration: 1000,
  easing: 'ease-in-out',
  once: true,
  mirror: false});
root.render(
  <UserProvider>
    <Router>
        <PrimeReactProvider>
        <App />
        </PrimeReactProvider>
    </Router>

  </UserProvider>
);

