
export default function Services() {
    return (
        <>
            {/*======= Services Section ======*/}
            <section id="services" className="services">
                <div className="container">

                    <div className="section-title" data-aos="fade-up">
                        <h2 className="text-center">المستهدفين ومجالات العمل</h2>
                        <p className="my-4">المستفيد الرئيسي من تطبيق خطط مأمونية المياه والصرف هم مقدمي خدمات المياه والصرف الصحي والتي تشمل ما يلي</p>
                    </div>

                    <div className="row">
                        <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                            <div className="icon-box" data-aos="fade-up" data-aos-delay="100">
                                <div className="icon"><i className="fa-solid fa-house-flood-water"></i></div>
                                <p className="description text-center">نظم إمداد مياه الشرب بتقنياته المختلفة (من المأخذ وحتى مجتمع المستهلكين)</p>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                            <div className="icon-box" data-aos="fade-up" data-aos-delay="200">
                                <div className="icon"><i className="fa-solid fa-droplet-slash"></i></div>
                                <p className="description text-center">أنظمة معالجة مياه الصرف الصحي بتقنياتها المختلفة (من شبكات التجميع إلى التخلص أو إعادة الاستخدام).</p>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                            <div className="icon-box w-100" data-aos="fade-up" data-aos-delay="300">
                                <div className="icon"><i className="fa-solid fa-bottle-water"></i></div>
                                <p className="description text-center">صناعة المياه المعدنية</p>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                            <div className="icon-box" data-aos="fade-up" data-aos-delay="400">
                                <div className="icon"><i className="fa-solid fa-city"></i></div>
                                <p className="description text-center">أنظمة المياه والصرف الصحي في المنشأت الطبية والمنتجعات الفندقية</p>
                            </div>
                        </div>

                    </div>

                </div>
            </section>
            {/*End Services Section*/}
        </>
    )
}