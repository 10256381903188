import FormItem from "./FormItemN";
import { Button } from "react-bootstrap";
import { Card } from "primereact/card";
export default function CompanyFormN(props) {
    return (
        <div className="mainN" style={{ backgroundColor: "transparent" }}>
            <Card title={"بيانات الشركات"} style={{ margin: "0 auto", width: "95%", overflow: "scroll", height: "600px" }}>
            <div className="row bg-white border border-light-2 rounded shadow-sm mx-auto">
                <form onSubmit={props.formik.handleSubmit}>
                    <div className="row p-3">
                        <div className="col-lg-4">
                            <label htmlFor="company" className="mb-2 fs-6">اسم الشركة</label>
                            <input
                                id="company"
                                name="company"
                                type="text"
                                className="form-control"
                                onChange={props.formik.handleChange}
                                onBlur={props.formik.handleBlur}
                                value={props.formik.values.company}
                            />
                            {props.formik.touched.company && props.formik.errors.company ? <div className="text-danger">{props.formik.errors.company}</div> : null}
                        </div>
                        <div className="col-lg-4">

                        </div>
                        <div className="col-lg-4"> </div>
                    </div>
                    <div className="row p-3">

                        <div className="col-md-4">
                            <FormItem label="الطاقة التصميمية لمحطات المياه"
                                name="water_design_power" handleChange={props.formik.handleChange}
                                handleBlur={props.formik.handleBlur} values={props.formik.values.water_design_power} />

                        </div>
                        <div className="col-md-4">
                            <FormItem label="الطاقة الفعلية لمحطات المياه"
                                name="water_real_power" handleChange={props.formik.handleChange}
                                handleBlur={props.formik.handleBlur} values={props.formik.values.water_real_power} />
                        </div>
                        <div className="col-md-4">
                            <FormItem label="عدد محطات المياه"
                                name="water_station_count" handleChange={props.formik.handleChange}
                                handleBlur={props.formik.handleBlur} values={props.formik.values.water_station_count} />

                        </div>
                    </div>
                    <div className="row px-3 py-1">

                        <div className="col-md-4">
                            <FormItem label="عدد محطات جوفى"
                                name="ground_stations_count" handleChange={props.formik.handleChange}
                                handleBlur={props.formik.handleBlur} values={props.formik.values.ground_stations_count} />

                        </div>
                        <div className="col-md-4">
                            <FormItem label="طاقة محطات جوفى"
                                name="ground_stations_power" handleChange={props.formik.handleChange}
                                handleBlur={props.formik.handleBlur} values={props.formik.values.ground_stations_power} />
                        </div>
                    </div>
                    <div className="row px-3 py-1">

                        <div className="col-md-4">
                            <FormItem label="عدد محطات مرشح سطحى"
                                name="filter_stations_count" handleChange={props.formik.handleChange}
                                handleBlur={props.formik.handleBlur} values={props.formik.values.filter_stations_count} />

                        </div>
                        <div className="col-md-4">
                            <FormItem label="طاقة محطة مرشح سطحى"
                                name="filter_stations_power" handleChange={props.formik.handleChange}
                                handleBlur={props.formik.handleBlur} values={props.formik.values.filter_stations_power} />
                        </div>
                    </div>
                    <div className="row px-3 py-1">

                        <div className="col-md-4">
                            <FormItem label="عدد محطات نقالى"
                                name="mobile_stations_count" handleChange={props.formik.handleChange}
                                handleBlur={props.formik.handleBlur} values={props.formik.values.mobile_stations_count} />

                        </div>
                        <div className="col-md-4">
                            <FormItem label="طاقة محطات نقالى"
                                name="mobile_stations_power" handleChange={props.formik.handleChange}
                                handleBlur={props.formik.handleBlur} values={props.formik.values.mobile_stations_power} />
                        </div>
                    </div>
                    <div className="row px-3 py-1">

                        <div className="col-md-4">
                            <FormItem label="عدد محطات مدمج"
                                name="compact_stations_count" handleChange={props.formik.handleChange}
                                handleBlur={props.formik.handleBlur} values={props.formik.values.compact_stations_count} />

                        </div>
                        <div className="col-md-4">
                            <FormItem label="طاقة محطات مدمج"
                                name="compact_stations_power" handleChange={props.formik.handleChange}
                                handleBlur={props.formik.handleBlur} values={props.formik.values.compact_stations_power} />
                        </div>
                    </div>
                    <div className="row px-3 py-1">

                        <div className="col-md-4">
                            <FormItem label="عدد محطات التحلية"
                                name="desalination_stations_count" handleChange={props.formik.handleChange}
                                handleBlur={props.formik.handleBlur} values={props.formik.values.desalination_stations_count} />

                        </div>
                        <div className="col-md-4">
                            <FormItem label="طاقة محطات التحلية"
                                name="desalination_stations_power" handleChange={props.formik.handleChange}
                                handleBlur={props.formik.handleBlur} values={props.formik.values.desalination_stations_power} />
                        </div>
                    </div>

                    <div className="row p-3">

                        <div className="col-md-4">
                            <FormItem label="الطاقة التصميمية لمحطات الصرف"
                                name="sanitation_design_power" handleChange={props.formik.handleChange}
                                handleBlur={props.formik.handleBlur} values={props.formik.values.sanitation_design_power} />

                        </div>
                        <div className="col-md-4">
                            <FormItem label="الطاقة الفعلية لمحطات الصرف"
                                name="sanitation_real_power" handleChange={props.formik.handleChange}
                                handleBlur={props.formik.handleBlur} values={props.formik.values.sanitation_real_power} />
                        </div>
                        <div className="col-md-4">
                            <FormItem label="عدد محطات الصرف الصحى"
                                name="sanitation_stations_power" handleChange={props.formik.handleChange}
                                handleBlur={props.formik.handleBlur} values={props.formik.values.sanitation_stations_power} />

                        </div>
                    </div>
                    <div className="row p-3">

                        <div className="col-md-4">
                            <FormItem label="اطوال شبكات المياه"
                                name="water_network_tall" handleChange={props.formik.handleChange}
                                handleBlur={props.formik.handleBlur} values={props.formik.values.water_network_tall} />

                        </div>
                        <div className="col-md-4">
                            <FormItem label="اطوال شبكات الصرف"
                                name="sanitation_network_tall" handleChange={props.formik.handleChange}
                                handleBlur={props.formik.handleBlur} values={props.formik.values.sanitation_network_tall} />
                        </div>
                        <div className="col-md-4">
                            <FormItem label="عدد محطات الرفع لكل نظام صرف"
                                name="sanitation_lift_count" handleChange={props.formik.handleChange}
                                handleBlur={props.formik.handleBlur} values={props.formik.values.sanitation_lift_count} />

                        </div>
                    </div>
                    <div className="row p-3">

                        <div className="col-md-4">

                            <label htmlFor="water_safety" className="mb-2">يوجد ادارة عامة لمأمونية المياه</label>
                            <select
                                id="water_safety"
                                name="water_safety"
                                onChange={props.formik.handleChange}
                                className="form-select" aria-label="Default select example"
                                value={props.formik.values.water_safety}>
                                <option value="1">يوجد</option>
                                <option value="0" >لايوجد</option>
                            </select>
                        </div>
                        <div className="col-md-4">
                            <FormItem label="نوع مواقع التعامل الامن مع الحمأة"
                                name="sludge_location_kind" handleChange={props.formik.handleChange}
                                handleBlur={props.formik.handleBlur} values={props.formik.values.sludge_location_kind} />
                        </div>
                        <div className="col-md-4">


                        </div>
                    </div>
                    <div className="row p-3">

                        <div className="col-md-4">
                            <FormItem label="عدد المستهلكين"
                                name="consumers_count" handleChange={props.formik.handleChange}
                                handleBlur={props.formik.handleBlur} values={props.formik.values.consumers_count} />

                        </div>
                        <div className="col-md-4">
                            <FormItem label="عدد مشتركين المياه"
                                name="water_subscribers" handleChange={props.formik.handleChange}
                                handleBlur={props.formik.handleBlur} values={props.formik.values.water_subscribers} />
                        </div>
                        <div className="col-md-4">
                            <FormItem label="عدد المشتركين صرف "
                                name="sanitation_subscribers" handleChange={props.formik.handleChange}
                                handleBlur={props.formik.handleBlur} values={props.formik.values.sanitation_subscribers} />

                        </div>
                    </div>
                    <div className="row p-3">

                        <div className="col-md-4">
                            <FormItem label="عدد محطات الترشيح الرملي البطيء"
                                name="slow_sand_filtration" handleChange={props.formik.handleChange}
                                handleBlur={props.formik.handleBlur} values={props.formik.values.slow_sand_filtration} />

                        </div>
                        <div className="col-md-4">
                            <FormItem label="طاقة محطات الترشيح الرملي البطيء م٣/ يوم"
                                name="slow_sand_filtration_power" handleChange={props.formik.handleChange}
                                handleBlur={props.formik.handleBlur} values={props.formik.values.slow_sand_filtration_power} />

                        </div>
                    </div>
                    <div className="row p-3">

                        <div className="col-md-4">
                            <FormItem label="عدد محطات الترشيح الفائق"
                                name="ultra_sand_filtration" handleChange={props.formik.handleChange}
                                handleBlur={props.formik.handleBlur} values={props.formik.values.ultra_sand_filtration} />

                        </div>
                        <div className="col-md-4">
                            <FormItem label="طاقة محطات الترشيح الفائق م٣/ يوم"
                                name="ultra_sand_filtration_power" handleChange={props.formik.handleChange}
                                handleBlur={props.formik.handleBlur} values={props.formik.values.ultra_sand_filtration_power} />

                        </div>
                    </div>
                    <div className="row p-3">

                        <div className="col-md-4">
                            <FormItem label="عدد محطة الترشيح الطبيعي "
                                name="natural_filtration" handleChange={props.formik.handleChange}
                                handleBlur={props.formik.handleBlur} values={props.formik.values.natural_filtration} />

                        </div>
                        <div className="col-md-4">
                            <FormItem label="طاقة محطات الترشيح الطبيعي م٣/ يوم"
                                name="natural_filtration_power" handleChange={props.formik.handleChange}
                                handleBlur={props.formik.handleBlur} values={props.formik.values.natural_filtration_power} />

                        </div>
                    </div>
                    <div className="row p-3">

                        <div className="col-md-4">
                            <FormItem label="عدد محطات الديناسند "
                                name="dynasend" handleChange={props.formik.handleChange}
                                handleBlur={props.formik.handleBlur} values={props.formik.values.dynasend} />

                        </div>
                        <div className="col-md-4">
                            <FormItem label="طاقة محطات الديناسند م٣/ يوم"
                                name="dynasend_power" handleChange={props.formik.handleChange}
                                handleBlur={props.formik.handleBlur} values={props.formik.values.dynasend_power} />

                        </div>
                    </div>
                    <div className="row p-3">

                        <div className="col-md-4">
                            <FormItem label="أطوال شبكات الإنحدار "
                                name="regression_networks_tall" handleChange={props.formik.handleChange}
                                handleBlur={props.formik.handleBlur} values={props.formik.values.regression_networks_tall} />

                        </div>
                        <div className="col-md-4">
                            <FormItem label="أطوال خطوط الطرد"
                                name="ejection_lines_tall" handleChange={props.formik.handleChange}
                                handleBlur={props.formik.handleBlur} values={props.formik.values.ejection_lines_tall} />

                        </div>
                    </div>
                    <Button size="lg" variant="success" type="submit" className="m-4">
                        {props.actionName}
                    </Button>
                </form>

            </div>
        </Card>
        </div>
    )
}