
import {Button} from "react-bootstrap";
import CompaniesOptions from "../companies/CompaniesOptionsN";
import { Calendar } from 'primereact/calendar';
export default function WaterSupplyFormN(props) {
    return (
        <div className="row bg-white border border-light-2 rounded shadow-sm mx-auto">
            <h3 className="fs-4 m-3 text-center">بيانات النظام</h3>
            <form onSubmit={props.formik.handleSubmit}>
                <div className="row p-3">
                    <div className="col-lg-4">
                        <label htmlFor="system_name" className="mb-2 fs-6">اسم نظام الامداد</label>
                        <input
                            id="system_name"
                            name="system_name"
                            type="text"
                            className="form-control"
                            onChange={props.formik.handleChange}
                            onBlur={props.formik.handleBlur}
                            value={props.formik.values.system_name}
                        />
                        {props.formik.touched.system_name && props.formik.errors.system_name ? <div className="text-danger">{props.formik.errors.system_name}</div> : null}
                    </div>
                    <div className="col-lg-4">

                    </div>
                    <div className="col-lg-4"> </div>
                </div>
                <div className="row p-3">

                    <div className="col-md-4">

                        <label htmlFor="system_kind" className="mb-2">نوع النظام</label>
                        <select
                            id="system_kind"
                            name="system_kind"
                            onChange={props.formik.handleChange}
                            className="form-select" aria-label="Default select example"
                            value={props.formik.values.system_kind}>
                            <option  disabled selected> اختر نوع النظام</option>
                            <option value="جوفى">جوفى</option>
                            <option value="سطحى" >سطحى</option>
                            <option value="نقالى" >نقالى</option>
                            <option value="مدمج" >مدمج</option>
                            <option value="تحليه" >تحليه</option>
                        </select>
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="system_dependency" className="mb-2">التبعية</label>
                        <select
                            id="system_dependency"
                            name="system_dependency"
                            onChange={props.formik.handleChange}
                            className="form-select" aria-label="Default select example"
                            value={props.formik.values.system_dependency}>
                            <option  disabled> اختر الجهة</option>
                            <option value="الشركة">الشركة</option>
                            <option value="الهيئة القومية" >الهيئة القومية</option>
                            <option value="الجهاز التنفيذي" >الجهاز التنفيذي</option>
                            <option value="هيئة المجتمعات العمرانية" >هيئة المجتمعات العمرانية</option>
                        </select>
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="company" className="mb-2">الشركة</label>

                        <select
                            id="company"
                            name="company"
                            onChange={props.formik.handleChange}
                            className="form-select" aria-label="Default select example">
                            <CompaniesOptions token={props.token} id={props.formik.values.company}></CompaniesOptions>
                        </select>

                    </div>
                </div>
                <div className="row p-3">
                    <div className="col-lg-4">
                        <label htmlFor="water_source" className="mb-2 fs-6">مصدر المياه</label>
                        <input
                            id="water_source"
                            name="water_source"
                            type="text"
                            className="form-control"
                            onChange={props.formik.handleChange}
                            onBlur={props.formik.handleBlur}
                            value={props.formik.values.water_source}
                        />
                    </div>
                    <div className="col-lg-4">
                        <label htmlFor="near_stations" className="mb-2 fs-6">المحطات القريبة التي تقع علي نفس المصدر</label>
                        <input
                            id="near_stations"
                            name="near_stations"
                            type="text"
                            className="form-control"
                            onChange={props.formik.handleChange}
                            onBlur={props.formik.handleBlur}
                            value={props.formik.values.near_stations}
                        />
                    </div>
                    <div className="col-lg-4">
                        <label htmlFor="water_alternative_source" className="mb-2 fs-6"> مصدر بديل للمياه في حال توقف المحطة</label>
                        <input
                            id="water_alternative_source"
                            name="water_alternative_source"
                            type="text"
                            className="form-control"
                            onChange={props.formik.handleChange}
                            onBlur={props.formik.handleBlur}
                            value={props.formik.values.water_alternative_source}
                        />
                    </div>
                </div>
                <div className="row p-3">
                    <div className="col-lg-4">
                        <label htmlFor="receiving_position" className="mb-2 fs-6"> موقف الاستلام </label>
                        <input
                            id="receiving_position"
                            name="receiving_position"
                            type="text"
                            className="form-control"
                            onChange={props.formik.handleChange}
                            onBlur={props.formik.handleBlur}
                            value={props.formik.values.receiving_position}
                        />
                    </div>
                    <div className="col-lg-4 d-flex flex-column">
                        <label htmlFor="date_created" className="mb-2 fs-6"> تاريخ الانشاء</label>
                        <Calendar dateFormat={"yy-mm-dd"} inputId={"date_created"}  name={"date_created"}
                                  value={props.formik.values.date_created && new Date(props.formik.values.date_created)} onChange={(e) => {
                            props.formik.setFieldValue('date_created', e.target.value);
                        }} />
                    </div>
                    <div className="col-lg-4">
                        <label htmlFor="construction_contractor" className="mb-2 fs-6">  مقاول الانشاء</label>
                        <input
                            id="construction_contractor"
                            name="construction_contractor"
                            type="text"
                            className="form-control"
                            onChange={props.formik.handleChange}
                            onBlur={props.formik.handleBlur}
                            value={props.formik.values.construction_contractor}
                        />
                    </div>
                </div>
                <div className="row p-3">
                    <div className="col-lg-4">
                        <label htmlFor="design_power" className="mb-2 fs-6"> طاقة المحطة التصميمية م3/يوم </label>
                        <input
                            id="design_power"
                            name="design_power"
                            type="text"
                            className="form-control"
                            onChange={props.formik.handleChange}
                            onBlur={props.formik.handleBlur}
                            value={props.formik.values.design_power}
                        />
                    </div>
                    <div className="col-lg-4">
                        <label htmlFor="real_power" className="mb-2 fs-6">طاقة المحطة الفعلية م3/يوم </label>
                        <input
                            id="real_power"
                            name="real_power"
                            type="text"
                            className="form-control"
                            onChange={props.formik.handleChange}
                            onBlur={props.formik.handleBlur}
                            value={props.formik.values.real_power}
                        />
                    </div>
                </div>
                <div className="row p-3">
                    <div className="col-lg-4">
                        <label htmlFor="emp_count" className="mb-2 fs-6"> عدد العمالة بالمحطة </label>
                        <input
                            id="emp_count"
                            name="emp_count"
                            type="text"
                            className="form-control"
                            onChange={props.formik.handleChange}
                            onBlur={props.formik.handleBlur}
                            value={props.formik.values.emp_count}
                        />
                    </div>
                    <div className="col-lg-4">
                        <label htmlFor="station_size" className="mb-2 fs-6">مساحة المحطة </label>
                        <input
                            id="station_size"
                            name="station_size"
                            type="text"
                            className="form-control"
                            onChange={props.formik.handleChange}
                            onBlur={props.formik.handleBlur}
                            value={props.formik.values.station_size}
                        />
                    </div>
                </div>
                <div className="row p-3">
                    <div className="col-lg-12">
                        <label htmlFor="station_components" className="mb-2 fs-6"> مكونات المحطة الرئيسية </label>
                        <input
                            id="station_components"
                            name="station_components"
                            type="text"
                            className="form-control"
                            onChange={props.formik.handleChange}
                            onBlur={props.formik.handleBlur}
                            value={props.formik.values.station_components}
                        />
                    </div>

                </div>
                <div className="row p-3">
                    <div className="col-lg-4">
                        <label htmlFor="power_alternative_source" className="mb-2 fs-6">  وجود مصدر بديل للطاقة </label>
                        <input
                            id="power_alternative_source"
                            name="power_alternative_source"
                            type="text"
                            className="form-control"
                            onChange={props.formik.handleChange}
                            onBlur={props.formik.handleBlur}
                            value={props.formik.values.power_alternative_source}
                        />
                    </div>
                    <div className="col-lg-4">
                        <label htmlFor="serviced_population" className="mb-2 fs-6">  عدد السكان المخدومين </label>
                        <input
                            id="serviced_population"
                            name="serviced_population"
                            type="text"
                            className="form-control"
                            onChange={props.formik.handleChange}
                            onBlur={props.formik.handleBlur}
                            value={props.formik.values.serviced_population}
                        />
                    </div>
                </div>
                <div className="row p-3">
                    <div className="col-lg-4">
                        <label htmlFor="net_tall" className="mb-2 fs-6">   اطوال الشبكات  </label>
                        <input
                            id="net_tall"
                            name="net_tall"
                            type="text"
                            className="form-control"
                            onChange={props.formik.handleChange}
                            onBlur={props.formik.handleBlur}
                            value={props.formik.values.net_tall}
                        />
                    </div>
                </div>
                <Button size="lg" variant="success" type="submit" className="m-4">
                    {props.actionName}
                </Button>
            </form>

        </div>
    )
}