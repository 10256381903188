import axios from 'axios';
import { Dialog } from 'primereact/dialog';
import { useState } from 'react';
import {Oval} from "react-loader-spinner";
export default function SeePlan(props) {
    const [loading,setLoading] = useState(false)
    const submitHandler = async (e) => {
        e.preventDefault();
        setLoading(true)
        try {
            let kind0 = 'water';
            if ( props.kind0 ) {
                kind0 = props.kind0
            }
            if ( props.kind === 1) {
                let res =await axios.post(`/${kind0}Plan/see_1/${props.id}`,null,{
                    headers : {
                        Accept : "application/json",
                        Authorization : "Bearer " + props.token,
                    }})
                props.run()
                props.close()
            }else {
                let res =await axios.post(`/${kind0}Plan/see_2/${props.id}`,null,{
                    headers : {
                        Accept : "application/json",
                        Authorization : "Bearer " + props.token,
                    }})
                props.run()
                props.close()
            }
        }catch (err) {
            console.error(err)
        }finally {
            setLoading(false)
        }
    }
    return (
        <>

            <Dialog header= {props.kind === 1 ? 'عرض الخطة للجهاز' : 'عرض الخطة للصحة'} visible={props.show} style={{ maxWidth: '80vw',direction:"rtl" }} onHide={props.close}>

                <div className="card border-0">
                    {loading && <Oval
                        height={80}
                        width={80}
                        color="blue"
                        wrapperStyle={{}}
                        wrapperClass="spinner_container"
                        visible={true}
                        ariaLabel='oval-loading'
                        secondaryColor="gray"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                    />}
                    <form onSubmit={submitHandler}>
                            <p className="p-2"> لن تستطيع التراجع بعد هذا الاجراء</p>
                       
                        <div className="card-footer">
                            <button type="submit" className="btn btn-warning">
                                عرض الخطة
                            </button>
                        </div>
                    </form>
                </div>
            </Dialog>
        </>
    )
}