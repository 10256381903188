import {useContext, useState} from "react";
import {User} from "../../Context";
import {useLocation, useNavigate} from "react-router-dom";
import {useFormik} from "formik";
import axios from "axios";
import CompanyForm from "./CompanyFormN";
import {Oval} from "react-loader-spinner";

export default function UpdateCompanyN(props) {
    const [loading,setLoading] = useState(false)
    const user = useContext(User)
    const token = user.auth.token
    const nav= useNavigate()
    const location = useLocation()
    const {data} = location.state

    const validate = values => {
        const errors = {};
        if (!values.company) {
            errors.company = 'حقل مطلوب';
        }

        return errors;
    }
    const formik = useFormik({
        initialValues: {
            company: data.company,
            water_design_power:data.water_design_power,
            water_real_power : data.water_real_power,
            water_station_count:data.water_station_count,
            ground_stations_count:data.ground_stations_count,
            ground_stations_power:data.ground_stations_power,
            filter_stations_count:data.filter_stations_count,
            filter_stations_power:data.filter_stations_power,
            mobile_stations_count:data.mobile_stations_count,
            mobile_stations_power:data.mobile_stations_power,
            compact_stations_count:data.compact_stations_count,
            compact_stations_power:data.compact_stations_power,
            desalination_stations_count:data.desalination_stations_count,
            desalination_stations_power:data.desalination_stations_power,
            sanitation_design_power:data.sanitation_design_power,
            sanitation_real_power:data.sanitation_real_power,
            sanitation_stations_power:data.sanitation_stations_power,
            sludge_location_count:data.sludge_location_count,
            water_network_tall:data.water_network_tall,
            sanitation_network_tall:data.sanitation_network_tall,
            consumers_count:data.consumers_count,
            water_subscribers:data.water_subscribers,
            sanitation_subscribers:data.sanitation_subscribers,
            sanitation_lift_count:data.sanitation_lift_count,
            water_safety:data.water_safety,
            sludge_location_kind:data.sludge_location_kind,
            slow_sand_filtration :data.slow_sand_filtration,
            slow_sand_filtration_power:data.slow_sand_filtration_power,
            ultra_sand_filtration:data.ultra_sand_filtration,
            ultra_sand_filtration_power:data.ultra_sand_filtration_power,
            natural_filtration:data.natural_filtration,
            natural_filtration_power:data.natural_filtration_power,
            dynasend:data.dynasend,
            dynasend_power:data.dynasend_power,
            regression_networks_tall:data.regression_networks_tall,
            ejection_lines_tall:data.ejection_lines_tall
        },
        validate,
        onSubmit: async values => {
            try {
                setLoading(true)
                let res = await axios.post(`/companies/update/${data.id}`,{
                        company : values.company,
                        water_design_power : values.water_design_power,
                        water_real_power: values.water_real_power,
                        water_station_count : values.water_station_count,
                        ground_stations_count:values.ground_stations_count,
                        ground_stations_power:values.ground_stations_power,
                        filter_stations_count:values.filter_stations_count,
                        filter_stations_power:values.filter_stations_power,
                        mobile_stations_count:values.mobile_stations_count,
                        mobile_stations_power:values.mobile_stations_power,
                        compact_stations_count:values.compact_stations_count,
                        compact_stations_power:values.compact_stations_power,
                        desalination_stations_count:values.desalination_stations_count,
                        desalination_stations_power:values.desalination_stations_power,
                        sanitation_design_power:values.sanitation_design_power,
                        sanitation_real_power:values.sanitation_real_power,
                        sanitation_stations_power:values.sanitation_stations_power,
                        sludge_location_count:values.sludge_location_count,
                        water_network_tall:values.water_network_tall,
                        sanitation_network_tall:values.sanitation_network_tall,
                        consumers_count:values.consumers_count,
                        water_subscribers:values.water_subscribers,
                        sanitation_subscribers:values.sanitation_subscribers,
                        sanitation_lift_count:values.sanitation_lift_count,
                        water_safety:values.water_safety,
                        sludge_location_kind:values.sludge_location_kind,
                        slow_sand_filtration :values.slow_sand_filtration,
                    slow_sand_filtration_power:values.slow_sand_filtration_power,
                    ultra_sand_filtration:values.ultra_sand_filtration,
                    ultra_sand_filtration_power:values.ultra_sand_filtration_power,
                    natural_filtration:values.natural_filtration,
                    natural_filtration_power:values.natural_filtration_power,
                    dynasend:values.dynasend,
                    dynasend_power:values.dynasend_power,
                    regression_networks_tall:values.regression_networks_tall,
                    ejection_lines_tall:values.ejection_lines_tall
                    },{
                        headers : {
                            Accept : "application/json",
                            Authorization : "Bearer " + token,
                        }}
                )
                if ( res.status === 200 ) {
                    nav('/controlPanelN/companies')
                }
            }catch(err) {
                console.error(err)
            }finally {
                setLoading(false)
            }
        },
    });
    return (
        <>

            {loading && <Oval
                height={80}
                width={80}
                color="blue"
                wrapperStyle={{}}
                wrapperClass="spinner_container"
                visible={true}
                ariaLabel='oval-loading'
                secondaryColor="gray"
                strokeWidth={2}
                strokeWidthSecondary={2}

            />}
            <CompanyForm formik={formik} title="تعديل شركة" actionName="تعديل"></CompanyForm>
        </>

    )
}