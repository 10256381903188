import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import { User } from "../../Context";
import { Oval } from "react-loader-spinner";
import { Card } from 'primereact/card';
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import ModalShow from "../../components/ModalShow";
import { Toast } from "primereact/toast";
import { FilterMatchMode} from 'primereact/api';
import { InputText } from 'primereact/inputtext';
export default function UsersN() {
    const toast = useRef(null);
    const [showDel, setShowDel] = useState(false)
    const [id, setId] = useState(0)
    const [users, setUsers] = useState([]);
    const [run, setRun] = useState(0)
    const [loading, setLoading] = useState(false)
    const user = useContext(User)
    const token = user.auth.token
    const nav = useNavigate()
    const [filters,setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const showSuccess = () => {
        toast.current.show({ severity: 'success', summary: 'Success', detail: 'تم الاجراء بنجاح', life: 3000 });
    }
    const openModal = (id) => {
        setShowDel(true)
        setId(id)
    }
    useEffect(() => {
        async function fetchData() {
            try {
                setLoading(true)
                await axios.get(axios.defaults.baseURL + "/user/show", {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + token,
                    }
                }).then((data) => setUsers(data.data))
            } catch (err) {
                console.error(err)
            } finally {
                setLoading(false)
            }
        }
        fetchData()
    }, [run])
    function handleRun() {
        setRun((prev) => prev + 1)
    }
    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <Button icon="fa-solid fa-pencil" style={{ marginLeft: "5px" }} rounded outlined className="mr-2" onClick={() => nav("/controlPanelN/editUser", {
                    state: { data: rowData }
                })} />
                <Button icon="fa-solid fa-trash" rounded outlined severity="danger" onClick={() =>
                    openModal(rowData.id)
                } />
            </>
        );
    };
    const authTemplate = (rowData) => {
        return (
            <>
                {
                    rowData.auth === 0 ?
                        <span className={"text-white bg-success px-1 rounded"}>Admin</span>
                        :
                        <span className={" bg-info px-1 rounded"}>User</span>
                }
            </>
        )
    }
    const roleTemplate = (rowData) => {
        return (
            <>
                {
                    rowData.role === 0 ?
                        <span className={"text-white bg-info px-1 rounded"}>مستخدم</span>
                        : rowData.role === 1 ?
                            <span className={" bg-danger text-white px-1 rounded"}>مراجع قابضة</span>
                            : rowData.role === 2 ?
                                <span className={" bg-primary text-white px-1 rounded"}>مراجع جهاز تنظيمي</span>
                                : rowData.role === 3 ?
                                    <span className={" bg-warning text-white px-1 rounded"}>مراجع وزارة صحة</span>
                                    : rowData.role === 4 ?
                                        <span className={" bg-secondary text-white px-1 rounded"}>مراجع شركة تابعة</span>
                                        : rowData.role === 5 ?
                                            <span className={" bg-secondary text-white px-1 rounded"}>مراجع قابضة </span>
                                            : ""
                }
            </>
        )
    }

    const msgTemplate = (rowData) => {
        return (
            <>
                {
                    rowData.msg === 0 ?
                        <span className={"text-white bg-info px-1 rounded"}>بدون رسائل</span>
                        : rowData.msg === 1 ?
                            <span className={" bg-warning text-white px-1 rounded"}>كل الرسائل</span>
                            : rowData.msg === 2 ?
                                <span className={" bg-warning text-white px-1 rounded"}>رسائل امداد مياه</span>
                                : rowData.msg === 3 ?
                                    <span className={" bg-warning text-white px-1 rounded"}>رسائل معالجة الصرف</span>
                                    : rowData.msg === 4 ?
                                        <span className={" bg-warning text-white px-1 rounded"}>رسائل الحمأة</span>
                                        : ""
                }
            </>
        )
    }
    const leftToolbarTemplate = () => {
        return (
            <div className="flex flex-wrap gap-2">
                <Button rounded aria-label="اضافة" icon="fa-solid fa-plus-circle" style={{ marginLeft: "10px" }} onClick={() => nav("/controlPanelN/addUser")} />
                <Button rounded aria-label="تصدير" icon="fa-solid fa-download" className="p-button-help" data-pr-tooltip="XLS" /></div>

        )
    };
    const allowExpansion = (rowData) => {
        return rowData.name.length > 0;
    };

    async function handelDeleteUser(id) {
        try {
            let res = await axios.delete(`/user/delete/${id}`, {
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + token,
                }
            })
            if (res.status === 200) {
                setRun(prev => prev + 1)
                setShowDel(false)
                showSuccess()
            }

        } catch (err) {
            console.error(err)
        }
    }
    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };
    const renderHeader = () => {
        return (
            <div className="flex justify-content-end">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
                </span>
            </div>
        );
    };
    const header = renderHeader();
    return (
        <div className="mainN" style={{ backgroundColor: "transparent" }}>
            <Toast ref={toast} position={"top-center"} />
            {loading && <Oval
                height={80}
                width={80}
                color="blue"
                wrapperStyle={{}}
                wrapperClass="spinner_container"
                visible={true}
                ariaLabel='oval-loading'
                secondaryColor="gray"
                strokeWidth={2}
                strokeWidthSecondary={2}

            />}
            <Card title={"بيانات المستخدمين"} style={{ margin: "0 auto", width: "95%",  }}>
                <Toolbar className="mb-1" end={leftToolbarTemplate}></Toolbar>
                <DataTable
                header={header}
                    filters={filters} filterDisplay="row"
                    globalFilterFields={['name','company']}
                    size="small"
                    value={users} paginator={true}
                    scrollable scrollHeight="400px"
                    rows={5} rowsPerPageOptions={[5, 10, 15, 50]}
                    tableStyle={{ width: "100%", margin: "0 auto", fontSize: "14px" }}>
                    <Column field="name" header="اسم المستخدم" style={{ textAlign: "right" }} sortable></Column>
                    <Column field="company" header="الجهة" style={{ textAlign: "right" }} sortable></Column>
                    <Column body={authTemplate} header="الصلاحية" style={{ textAlign: "right" }} sortable></Column>
                    <Column body={roleTemplate} header="الدور" style={{ textAlign: "right" }} sortable></Column>
                    <Column body={msgTemplate} header="الرسائل" style={{ textAlign: "right" }} sortable></Column>
                    <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
                </DataTable>
            </Card>
            <ModalShow show={showDel} operationName={"حذف مستخدم"}
                operationText={"حذف"} close={() => setShowDel(false)}
                action={handelDeleteUser}
                id={id}
            />
        </div>
    )
}