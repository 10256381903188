import axios from 'axios';
import { Dialog } from 'primereact/dialog';
import { useState } from 'react';
import {Oval} from "react-loader-spinner";
export default function FoldersDialog(props) {
    const [file,setFile] = useState("")
    const [loading,setLoading] = useState(false)
   const submitHandler = async (e) => {

        e.preventDefault();
        setLoading(true)
        const data = new FormData()
        data.append('file', file)
       if ( props.folderData ) {
           data.append('folderPath',props.folderData)
       }

        try {
           let kind = 'water';
           if ( props.kind ) {
               kind = props.kind
           }
            if ( props.level === 0 ) {
                let res = await axios.post(axios.defaults.baseURL + `/${kind}FolderFiles/upload/${props.id}/${props.idd}`, data, {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + props.token,
                    }
                })
                if (res.status === 200) {
                    props.run();
                    props.close();
                }
            }else if ( props.level === 1 ) {
                let res = await axios.post(axios.defaults.baseURL + `/${kind}FolderFilesNotes/upload/${props.idd}`, data, {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + props.token,
                    }
                })
                if (res.status === 200) {
                    props.run();
                    props.close();
                }
            }
            else if ( props.level === 2 ) {
                let res = await axios.post(axios.defaults.baseURL + `/${kind}FolderFilesNotes_01/upload/${props.idd}`, data, {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + props.token,
                    }
                })
                if (res.status === 200) {
                    props.run();
                    props.close();
                }
            }
            else if ( props.level === 3 ) {
                let res = await axios.post(axios.defaults.baseURL + `/${kind}FolderFilesNotes_02/upload/${props.idd}`, data, {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + props.token,
                    }
                })
                if (res.status === 200) {
                    props.run();
                    props.close();
                }
            }
        }catch (err) {
            console.error(err)
        }finally {
            setLoading(false)
        }
   }
    const handleChange = (e) => {
        setFile(e.target.files[0])
    }
    return (
        <>
        
        <Dialog header="رفع ملف للفولدر" visible={props.show} style={{ maxWidth: '80vw',direction:"rtl" }} onHide={props.close}>
       
            <div className="card">
            {loading && <Oval
            height={80}
            width={80}
            color="blue"
            wrapperStyle={{}}
            wrapperClass="spinner_container"
            visible={true}
            ariaLabel='oval-loading'
            secondaryColor="gray"
            strokeWidth={2}
            strokeWidthSecondary={2}
        />}
            <form onSubmit={submitHandler} encType="multipart/form-data" id="imageForm">
                <div className="form-group p-4">
                    <label htmlFor="file" className='mb-2'>اختر ملف لرفعه</label>
                    <input
                      type="file"
                      name="file"                      
                      onChange={handleChange}
                      className="form-control"
                    />
                  </div>
                  <div className="card-footer">
                    <button type="submit" className="btn btn-success">
                        Upload
                    </button>
                </div>
            </form>
            </div>
        </Dialog>
        </>
    )
}