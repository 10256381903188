import {Link} from "react-router-dom";
export default function SidebarItemN(props) {
    return <>
    <Link to={props.link}  >
            <div className={"d-flex container-fluid align-items-center p-2 sidenav-item " + props.class} style={{ cursor: "pointer" }} >
                <div style={{ width: "50px", textAlign: "center" }}>
                <i style={{ color: props.color, fontSize: '.9rem' }} className={props.icon}></i>
                
                    
                </div>
                <div className="sidenav-text" >{props.title}</div>
            </div>
            </Link>
    </>
}