
export default function Footer() {
    return (
        <>
            {/*======= Footer =======*/}
            <footer id="footer">
                <div className="container">
                    <div className="row d-flex align-items-center">
                        <div className="col-lg-6 text-lg-left text-center">
                            <div className="copyright">
                                &copy; Copyright <strong>HCWW</strong>. All Rights Reserved
                            </div>
                            <div className="credits">

                                Created by <a href="">Tarik</a>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            {/*<nav className="footer-links text-lg-right text-center pt-2 pt-lg-0">*/}
                            {/*    <a href="#intro" className="scrollto">Home</a>*/}
                            {/*    <a href="#about" className="scrollto">About</a>*/}
                            {/*    <a href="#">Privacy Policy</a>*/}
                            {/*    <a href="#">Terms of Use</a>*/}
                            {/*</nav>*/}
                        </div>
                    </div>
                </div>
            </footer>
            {/*End Footer*/}
        </>
    )
}