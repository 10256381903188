import SidebarItem from "./SidebarItemN";
import { useLocation } from 'react-router-dom'

export default function Sidebar(props) {
    const location = useLocation();
    return <div className={props.open ? 'sidebar-container sidebar-open' : 'sidebar-container sidebar-close'} >
        <div className="d-flex container-fluid align-items-center p-2">
            <div className="fs-3 text-info fw-bold" style={{ width: "200px" }}>Menassa</div>
            <div style={{ width: "50px", textAlign: "center" }}>
                <i style={{ cursor: "pointer" }} className={props.open ? "fa-solid fa-times" : "fa-solid fa-times d-none"} onClick={props.toggleSidebar}></i>
            </div>
        </div>

        <div className="d-flex flex-column mt-3 " >
            <SidebarItem class={location.pathname.endsWith("controlPanelN") && "sidenav-item-active"}
                color="#5e72e4" icon="fa-solid fa-house sidenav-icon" title="الرئيسية" link="/controlPanelN"></SidebarItem>

            <SidebarItem class={location.pathname.endsWith("users") || location.pathname.endsWith("addUser") ? "sidenav-item-active" : ""}
                color="rgb(173 228 94)" icon="fa-solid fa-users sidenav-icon" title="المستخدمين" link="/controlPanelN/users"></SidebarItem>

            <SidebarItem class={location.pathname.endsWith("companies") || location.pathname.endsWith("Company") ? "sidenav-item-active" : ""}
                color="rgb(228 189 94)" icon="fa-solid fa-building sidenav-icon" title="البيانات التنظيمية" link="/controlPanelN/companies"></SidebarItem>

            <SidebarItem class={location.pathname.endsWith("waterSupply") || location.pathname.endsWith("Supply") ? "sidenav-item-active" : ""}
                color="#5e72e4" icon="fa-solid fa-arrow-up-from-water-pump sidenav-icon" title="انظمة امداد المياه" link="/controlPanelN/waterSupply"></SidebarItem>

            <SidebarItem class={location.pathname.endsWith("treatmentData") ? "sidenav-item-active" : ""}
                color="rgb(86 92 128)" icon="fa-solid fa-house-flood-water-circle-arrow-right sidenav-icon" title="انظمة المعالجة " link="/controlPanelN/treatmentData"></SidebarItem>


            <SidebarItem class={location.pathname.endsWith("sludge") ? "sidenav-item-active" : ""}
                color="rgb(255 78 0)" icon="fa-solid fa-viruses sidenav-icon" title="انظمة الحمأة " link='/controlPanelN/sludge'></SidebarItem>

            <SidebarItem class={location.pathname.includes("waterPlans") ? "sidenav-item-active" : ""}
                color="#5e72e4" icon="fa-solid fa-layer-group sidenav-icon" title="خطط انظمة امداد المياه" link='/controlPanelN/waterPlans'></SidebarItem>

            <SidebarItem class={location.pathname.includes("sewagePlans") ? "sidenav-item-active" : ""}
                color="rgb(86 92 128)" icon="fa-solid fa-layer-group sidenav-icon" title="خطط انظمة المعالجة"  link='/controlPanelN/sewagePlans'></SidebarItem>


            <SidebarItem class={location.pathname.includes("sludgePlans") ? "sidenav-item-active" : ""}
                color="rgb(255 78 0)" icon="fa-solid fa-layer-group sidenav-icon" title="خطط انظمة الحمأة"  link='/controlPanelN/sludgePlans'></SidebarItem>

            <SidebarItem class={location.pathname.includes("library") ? "sidenav-item-active" : ""}
                color="rgb(150 0 0)" icon="fa-solid fa-book sidenav-icon" title="المكتبة الالكترونية" link='/controlPanelN/library' ></SidebarItem>
        </div>
    </div>
}