
import { Outlet } from "react-router-dom";
import Header from "./header/Header";

import Footer from "./sections/Footer";

export default function MainPage() {

    return (
        <div style={{ direction:"rtl"}}>
            <Header />
            <Outlet />
            <Footer />
        </div>
    )
}