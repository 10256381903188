import SideBar from "./SideBar";
import Navbr from "./Navbr";
import {Outlet} from "react-router-dom";
export default function Dashboard() {

    return (
        <>
            <div style={{ display:'flex',direction:'rtl' ,background: "#f7f7ff"}}>
                <SideBar />
                <div className='w-100'>
                    <Navbr />
                    <div>

                        <Outlet />
                    </div>
                </div>
            </div>

        </>
    )
}