import { Chart } from 'primereact/chart';
import axios from "axios";
import { useEffect, useState } from "react";
export default function ChartsBlocksN() {
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});
    const documentStyle = getComputedStyle(document.documentElement);
    useEffect(() => {

        const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
        const data = {
            labels: ['ممتاز', 'جيد جدا', 'جيد', 'متوسط', 'ضعيف'],
            datasets: [
                {
                    label: 'التقييمات',
                    data: [1500, 2500, 1700, 1300, 200],
                    backgroundColor: [
                        'green',
                        'yellowGreen',
                        'yellow',
                        'darkOrange',
                        'red'
                    ],
                    // borderColor: [
                    //     'rgb(255, 159, 64)',
                    //     'rgb(75, 192, 192)',
                    //     'rgb(54, 162, 235)',
                    //     'rgb(153, 102, 255)'
                    // ],
                    // borderWidth: 1
                }
            ]
        };
        const options = {
            responsive: true,
            // indexAxis: 'y',
            maintainAspectRatio: false,
            aspectRatio: 0.8,
            scales: {
                y: {
                    beginAtZero: true
                }
            }

        };

        setChartData(data);
        setChartOptions(options);
    }, [])
    return <>
        <div className=' mx-auto' style={{ marginTop: '-4.5rem' }}>
            <div className="row mx-0" style={{ marginTop: "50px" }}>
                <div className="col-sm-7 ">
                    <div className="bg-light m-4 shadow-sm">
                        <Chart type="bar" data={chartData} options={chartOptions} />
                    </div>
                </div>
                <div className="col-sm-5 ">
                    <div className="bg-light m-4 shadow-sm">
                        <Chart type="pie" data={chartData} options={chartOptions} />
                    </div>
                </div>
            </div>
        </div>
    </>
}