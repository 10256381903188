import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import { useState} from "react";
import {Link} from "react-router-dom";
import { useLocation } from 'react-router-dom'
export default  function SideBar() {
    const location = useLocation();

    const [collapse,setCollapse] = useState(false)
    const collapseHandler = () => {
        setCollapse(!collapse);
    }

   
    return (
        <div className="shadow-sm" style={{ display: 'flex', height: '100vh', minHeight: '400px',direction:'rtl' }}>
            <Sidebar
                rtl collapsed={collapse}>
                <Menu>
                    <MenuItem >
                        <div className='row border-bottom m-0 pb-3 pt-3' style={{color:'#008cff'}}>
                            <div className='col-9' >{!collapse && 'HCWW' }</div>
                            <div className='col-3' onClick={collapseHandler}>
                                <i className="fa-solid fa-arrows-left-right-to-line"></i>
                            </div>
                        </div>
                    </MenuItem>
                    <MenuItem active={location.pathname.endsWith("controlPanel")}  component={<Link to="/controlPanel" ></Link>} icon={<i className="fa-solid fa-house"></i>}> لوحة التحكم </MenuItem>
                    <MenuItem active={location.pathname.includes("users") || location.pathname.includes("addUser")} component={<Link to="/controlPanel/users" ></Link>} icon={<i className="fa-solid fa-users"></i>}> المستخدمين </MenuItem>
                    <MenuItem active={location.pathname.includes("companies") || location.pathname.includes("AddCompany")} component={<Link to="/controlPanel/companies" ></Link>} icon={<i className="fa-solid fa-building"></i>}> البيانات التنظيمية </MenuItem>
                    <MenuItem active={location.pathname.includes("waterSupply") || location.pathname.includes("addWaterSupply")  || location.pathname.includes("editWaterSupply")} component={<Link to="/controlPanel/waterSupply" ></Link>} icon={<i className="fa-solid fa-faucet-drip"></i>}> انظمة امداد المياه </MenuItem>
                    <MenuItem active={location.pathname.includes("treatmentData") || location.pathname.includes("addTreatment")  || location.pathname.includes("editTreatment")} component={<Link to="/controlPanel/treatmentData" ></Link>} icon={<i className="fa-solid fa-recycle"></i>}> انظمة المعالجة </MenuItem>
                    <MenuItem active={location.pathname.includes("sludge") || location.pathname.includes("addSludge")  || location.pathname.includes("editSludge")} component={<Link to="/controlPanel/sludge" ></Link>} icon={<i className="fa-solid fa-recycle"></i>}> انظمة الحمأة </MenuItem>
                    <MenuItem active={location.pathname.includes("waterPlans") || location.pathname.includes("addWaterPlans")  || location.pathname.includes("editWaterPlans")} component={<Link to="/controlPanel/waterPlans" ></Link>} icon={<i className="fa-solid fa-cog"></i>}> خطط المياه </MenuItem>
                    <MenuItem active={location.pathname.includes("sewagePlans") || location.pathname.includes("addSewagePlans")  || location.pathname.includes("editSewagePlans")} component={<Link to="/controlPanel/sewagePlans" ></Link>} icon={<i className="fa-solid fa-cog"></i>}> خطط الصرف الصحى </MenuItem>
                    <MenuItem active={location.pathname.includes("sludgePlans") || location.pathname.includes("addSludgePlans")  || location.pathname.includes("editSludgePlans")} component={<Link to="/controlPanel/sludgePlans" ></Link>} icon={<i className="fa-solid fa-cog"></i>}> خطط الحمأة </MenuItem>
                    <MenuItem active={location.pathname.includes("library") || location.pathname.includes("addLibrary")  || location.pathname.includes("editLibrary")} component={<Link to="/controlPanel/library" ></Link>} icon={<i className="fa-solid fa-book"></i>}>المكتبة الالكترونية </MenuItem>
                    <MenuItem active={location.pathname.includes("notifications")} component={<Link to="/controlPanel/notifications" ></Link>} icon={<i className="fa-solid fa-circle-exclamation"></i>}> اشعارات تنفيذ الخطط </MenuItem>
                </Menu>
            </Sidebar>
        </div>
    )
}