import {useContext, useState} from "react";
import {User} from "../../Context";
import { useNavigate} from "react-router-dom";
import {useFormik} from "formik";
import axios from "axios";
import {Oval} from "react-loader-spinner";
import GetOptions from "../../components/GetOptions";
import {Button} from "react-bootstrap";
import {Calendar} from "primereact/calendar";
import { useEffect } from "react";
import CompaniesOptions from "../companies/CompaniesOptionsN";
import {Card} from "primereact/card";
export default function AddSludgePlanN() {
    const [company,setCompany] = useState(null)
    const [loading,setLoading] = useState(false)
    const [folderValus,setFolderValues] = useState({});
    const user = useContext(User)
    const token = user.auth.token
    const nav= useNavigate()
    const validate = values => {
        const errors = {};
        if (!values.plan_name) {
            errors.plan_name = 'حقل مطلوب';
        }
        if (!values.sludge_id) {
            errors.sludge_id = 'حقل مطلوب';
        }
        if (!values.user_id) {
            errors.user_id = 'حقل مطلوب';
        }
        if (!values.version) {
            errors.version = 'حقل مطلوب';
        }if (!values.plan_start) {
            errors.plan_start = 'حقل مطلوب';
        }
        if(!values.reviewer) {
            errors.reviewer = 'حقل مطلوب';
        }
        return errors;
    };
    useEffect(() => {
        let values = folderValus;
        values[`folder_1`]  = "خطابات الإعتماد"
        values[`evaluate_1`]  = 1
        values[`canAdd_1`]  = ['can_add_2','can_add_3','can_add_4']
        values[`about_1`]  = "وصف الفولدر"


        values[`folder_2`]  = "خطابات وملاحظات القابضة"
        values[`evaluate_2`]  = 1
        values[`canAdd_2`]  = ['can_add_2']
        values[`about_2`]  = "وصف الفولدر"

        values[`folder_3`]  = "خطابات وملاحظات الجهاز"
        values[`evaluate_3`]  = 1
        values[`canAdd_3`]  = ['can_add_3']
        values[`about_3`]  = "وصف الفولدر"

        values[`folder_4`]  = "خطابات وملاحظات الصحة/اللجنة العليا"
        values[`evaluate_4`]  = 1
        values[`canAdd_4`]  = ['can_add_4']
        values[`about_4`]  = "وصف الفولدر"

        values[`folder_5`]  = "المخاطبات"
        values[`evaluate_5`]  = 0
        values[`canAdd_5`]  = []
        values[`about_5`]  = "وصف الفولدر"

        values[`folder_6`]  = "المتطلبات الإدارية"
        values[`evaluate_6`]  = 0
        values[`canAdd_6`]  = []
        values[`about_6`]  = "وصف الفولدر"

        values[`folder_7`]  = "المقدمة"
        values[`evaluate_7`]  = 0
        values[`canAdd_7`]  = []
        values[`about_7`]  = "وصف الفولدر"

        values[`folder_8`]  = "الملخص التنفيذي"
        values[`evaluate_8`]  = 0
        values[`canAdd_8`]  = []
        values[`about_8`]  = "وصف الفولدر"

        values[`folder_9`]  = "الوضع الحالي لتداول الحمأة "
        values[`evaluate_9`]  = 0
        values[`canAdd_9`]  = []
        values[`about_9`]  = "وصف الفولدر"

        values[`folder_10`]  = "الوضع المستقبلي لتداول الحمأة"
        values[`evaluate_10`]  = 0
        values[`canAdd_10`]  = []
        values[`about_10`]  = "وصف الفولدر"

        values[`folder_11`]  = "الخطة المستقبلية لتحقيق مأمونية الحمأة (البدائل والمقترحات)"
        values[`evaluate_11`]  = 0
        values[`canAdd_11`]  = []
        values[`about_11`]  = "وصف الفولدر"

        values[`folder_12`]  = "الدراسة الاقتصادية للبدائل والمقترحات"
        values[`evaluate_12`]  = 0
        values[`canAdd_12`]  = []
        values[`about_12`]  = "وصف الفولدر"

        values[`folder_13`]  = "المرفقات"
        values[`evaluate_13`]  = 0
        values[`canAdd_13`]  = []
        values[`about_13`]  = "وصف الفولدر"

        values[`folder_14`]  = "اللجنة العليا"
        values[`evaluate_14`]  = 0
        values[`canAdd_14`]  = []
        values[`about_14`]  = "وصف الفولدر"
        setFolderValues(values)
    },[])
    const formik = useFormik({
        initialValues: {
            plan_name: '',
            sludge_id: '',
            user_id: '',
            version:'1',
            plan_start : '',
            reviewer : '',
        },
        validate,
        onSubmit: async values => {
            try {
                setLoading(true)
                let res = await axios.post('/sludgePlan/create',{
                        plan_name : values.plan_name,
                    sludge_id : values.sludge_id,
                        user_id : values.user_id,
                    version : values.version,
                    values:folderValus,
                    plan_start : values.plan_start ? new Date(values.plan_start).getFullYear() + "-" +
                    ( parseInt(new Date(values.plan_start).getMonth())+1) + "-" + new Date(values.plan_start).getDate() : "",
                    reviewer : values.reviewer
                    },{
                        headers : {
                            Accept : "application/json",
                            Authorization : "Bearer " + token,
                        }}
                )
                if ( res.status === 200 ) {
                    nav('/controlPanelN/sludgePlans')
                }
            }catch(err) {
                console.error(err)
            }
            finally {
                setLoading(false)
            }
        },
    });
    return (
        <>
           
            <div className="mainN" style={{ backgroundColor:"transparent"}}>
            {loading && <Oval
                height={80}
                width={80}
                color="blue"
                wrapperStyle={{}}
                wrapperClass="spinner_container"
                visible={true}
                ariaLabel='oval-loading'
                secondaryColor="gray"
                strokeWidth={2}
                strokeWidthSecondary={2}

            />}
            <Card title={"اضافة خطة نظام حمأة"}  style={{ margin: "0 auto", maxWidth:"500px" }}>
            <div  style={{overflow:"scroll",maxHeight:"500px" }}>
                <div className="row bg-white border border-light-2 rounded shadow-sm mx-auto" style={{maxWidth:"500px"}}>
                    <h3 className="fs-6 m-3 text-center"> بيانات الخطة</h3>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="row p-3">
                            <label htmlFor="plan_name" className="mb-2">كود الخطة</label>
                            <input
                                id="plan_name"
                                name="plan_name"
                                type="text"
                                className="form-control"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.plan_name}
                            />
                            {formik.touched.plan_name && formik.errors.plan_name ? <div className="text-danger">{formik.errors.plan_name}</div> : null}
                            <label htmlFor="version" className="mb-2">اصدار الخطة</label>
                            <input
                                id="version"
                                name="version"
                                type="text"
                                className="form-control"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.version}
                            />
                            {formik.touched.version && formik.errors.version ? <div className="text-danger">{formik.errors.version}</div> : null}

                            <label htmlFor="company" className="mb-2 mt-2">الشركة</label>
                                <select
                                    id="company"
                                    name="company"
                                    onChange={(e) =>setCompany(e.target.value)}
                                    className="form-select" aria-label="Default select example"
                                    value={company}>
                                    <CompaniesOptions token={token}></CompaniesOptions>
                                </select>

                            <label htmlFor="sludge_id" className="mb-2 mt-2">نظام الحمأة</label>
                            <select
                                id="sludge_id"
                                name="sludge_id"
                                onChange={formik.handleChange}
                                className="form-select" aria-label="Default select example"
                            >
                                <GetOptions token={token} table={"sludge"} field={"system_name"} company={company}></GetOptions>

                            </select>
                            {formik.touched.sludge_id && formik.errors.sludge_id ? <div className="text-danger">{formik.errors.sludge_id}</div> : null}
                            <label htmlFor="user_id" className="mb-2 mt-2">مستخدم بداية الخطة</label>
                            <select
                                id="user_id"
                                name="user_id"
                                onChange={formik.handleChange}
                                className="form-select" aria-label="Default select example"
                            >
                                <GetOptions token={token} table={"user"} field={"name"} company={company}></GetOptions>

                            </select>
                            {formik.touched.user_id && formik.errors.user_id ? <div className="text-danger">{formik.errors.user_id}</div> : null}
                            <label htmlFor="reviewer" className="mb-2 mt-2">مراجع الخطة</label>
                            <select
                                id="reviewer"
                                name="reviewer"
                                onChange={formik.handleChange}
                                className="form-select" aria-label="Default select example"
                            >
                                <GetOptions token={token} table={"user"} field={"name"} kind={5}></GetOptions>

                            </select>
                            {formik.touched.reviewer && formik.errors.reviewer ? <div className="text-danger">{formik.errors.reviewer}</div> : null}

                            <label htmlFor="date_created" className="mx-2 fs-6"> تاريخ بدء الخطة</label>
                            <Calendar className="" dateFormat={"yy-mm-dd"} inputId={"plan_start"}  name={"plan_start"}
                                      value={formik.values.plan_start && new Date(formik.values.plan_start)} onChange={(e) => {
                                formik.setFieldValue('plan_start', e.target.value);
                            }} />


                            <Button variant="success" type="submit" className="mt-4">
                                اضافة
                            </Button>
                        </div>
                    </form>

                </div>
                </div>
                </Card>
            </div>
        </>
    )
}