import {useLocation} from "react-router-dom";
import {Oval} from "react-loader-spinner";
import React, {useState} from "react";
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Button } from 'primereact/button';
import FoldersDialog from "../../../components/FoldersDialog";
import { useEffect } from "react";
import axios from 'axios';
import ModalShow from "../../../components/ModalShow";
import ApprovePlan from "../ApprovePlan";
import ReplaceFileDialog from "../ReplaceFileDialog";
import { Chip } from 'primereact/chip';
import { Badge } from 'primereact/badge';
import {
    handelDownloadFile,
    handelDownloadFile2,
    handelDownloadPlanFolder,
    handelDownloadFolder
} from "../../../helpers/Helpers";
export default function WaterPlan() {
    const [showDel,setShowDel] = useState(false)
    const [showSend,setShowSend] = useState(false)
    const [showReplace,setShowReplace] = useState(false)
    const [idSend,setIdSend] = useState(0)
    const [idDel,setIdDel] = useState(0)
    const [show,setShow] = useState(false)
    const location = useLocation()
    const [idd,setIdd] = useState(0)
    const [data,setData] = useState([])
    const {id,name,token,approved,version} = location.state
    const [loading,setLoading] = useState(false)
    const [folderData , setFolderData] = useState("")
    const [files,setFiles] = useState([])
    const [run,setRun]  =useState(0)
    const [canApprove , setCanApprove] = useState(false)
    const [showApprove,setShowApprove] = useState(false)

    const setFData = (fname,idd) => {
        setIdd(idd)
        setFolderData(id + "/" + fname)
        setShow(true)
    }

    const setFileData = (fid) => {
        setIdDel(fid)
        setShowReplace(true)
    }


    const openModal = (id) => {
        setIdDel(id)
        setShowDel(true)
        
    }
    const openModalSend = (id) => {
        setIdSend(id)
        setShowSend(true)
        
    }
    useEffect(() => {
        const showFiles = async () => {
            try {
                setLoading(true)
               
                let res = await axios.get(`/waterFolderFiles/show/${id}`, {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + token,
                    }
                })
                if ( res.status === 200) {
                   setFiles(res.data)
                }
                
            } catch (err) {
                console.error(err)
            }
            finally {
                setLoading(false)
            }
        }

        const showData = async () => {
            let res = await axios.get(`/waterFolders/showbyid/${id}`, {
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + token,
                }
            })

            let approveOk = true;
            if ( res.status === 200) {
                    res.data.map(item => {
                        if ( item.approve_0 === 1 ) {
                            approveOk = false;
                        }else {
                            if ( item.send === 0 ){
                                approveOk = false;
                            }
                        }

                    })
                    setCanApprove(approveOk);
                if ( res.data.length > 0 ) {
                   setData(res.data)
                }else {
  
                }
            }

        }
        showData()
        showFiles()

        
    },[run])
    async function handelDeleteFile(id) {
        try {
            
            let res =await axios.delete(`/waterFolderFiles/delete/${id}`,{
                headers : {
                    Accept : "application/json",
                    Authorization : "Bearer " + token,
                }})
            if ( res.status === 200 ) {
                setShowDel(false)
                handleRun()
            }

        }catch(err) {
            console.error(err)
        }
    }
    function handleRun ()
    {
        setRun((prev) => prev + 1)
    }


    const handleSendFolder = async (id) => {
        try {
            setLoading(true)
            let res =await axios.post(`/waterFolders/send/${id}`,null,{
                headers : {
                    Accept : "application/json",
                    Authorization : "Bearer " + token,
                }})
            // if ( res.status === 200 ) {
            //     setShowSend(false)
            //     handleRun()
            // }
        }catch(err) {
             //console.error(err)
             console.log("error")
        }finally{
            setLoading(false)
            setShowSend(false)
                handleRun()
        }
    }



    const approvePlan = () => {
        try {
            let result;
            // eslint-disable-next-line no-restricted-globals
            result = confirm("هل انت متأكد من انك تريد اعتماد الخطة")
            // if ( result ) {
            //     setShowApprove(true)
            // }
        }catch(err) 
        {
            //console.error(err)
            console.log("error")
        }finally{
            setShowApprove(true)
        }
    }
    const content = (
        <div className="d-flex justify-content-between py-1">
            <span className="mx-2 font-medium">اصدار</span>
            <Badge value={version} className="align-self-start"></Badge>
        </div>
    );
    return (
        <>

            <div style={{ marginTop:"40px",width:"100%",padding:"10px"}}>
                {loading && <Oval
                    height={80}
                    width={80}
                    color="blue"
                    wrapperStyle={{}}
                    wrapperClass="spinner_container"
                    visible={true}
                    ariaLabel='oval-loading'
                    secondaryColor="gray"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                />}
                <div style={{minHeight:"500px",maxWidth:"1000px" , margin:"75px auto 0 auto",padding:"10px"}}>
                    <div className="text-center mt-4" data-aos="fade-up">
                        <h2 className="fs-sm-6">خطة سلامة ومأمونية المياه لنظام امداد 
                          {name} 
                          <Chip template={content}  /></h2>

                    </div>

                <div className="mt-5">

                    <Accordion activeIndex={0}>
                        {data.map((item,index) => (
                                <AccordionTab
                                    key={index} header={
                                        <div className="flex align-items-center">

                                               <i className="fa-solid fa-folder-open mx-2 text-warning"></i>
                                               <span className="vertical-align-middle">
                                                   {item.name}
                                                    <i style={{fontSize:"11px",display:"block",marginRight:"35px",color:"darkgray",paddingTop:"1px"}}>{item.about}</i>
                                               </span>
                                               {item.send === 1 && <i className="fa-solid fa-circle-check text-success fs-4 mx-2 "></i>}
                                        </div>
                                    }
                                >
                                    <div>
                                        {
                                            files.filter((f) => f.water_folders_id === item.id).map((fil,i) => (
                                                <div className="row p-2 mb-2 border-bottom rounded"  key={i}>
                                                    <div className="col-sm-4 d-flex">

                                                        <div className=" mb-2 mb-sm-0">
                                                            {
                                                                (fil.file_name.toLowerCase()).includes("jpg") || (fil.file_name.toLowerCase()).includes("jpeg")  || (fil.file_name.toLowerCase()).includes("png") || (fil.file_name.toLowerCase()).includes("jif") ?
                                                                    <i className="fa-solid fa-image text-info mx-2"></i>
                                                                    :
                                                                    (fil.file_name.toLowerCase()).includes("pdf") ? <i className="fa-solid fa-file-pdf text-danger mx-2"></i>
                                                                        :
                                                                        (fil.file_name.toLowerCase()).includes("doc") ? <i className="fa-solid fa-file-word text-primary mx-2"></i> :
                                                                            (fil.file_name.toLowerCase()).includes("xls") ? <i className="fa-solid fa-table text-success mx-2"></i> :
                                                                                <i className="fa-solid fa-file text-default mx-2"></i>
                                                            }
                                                        </div>
                                                        {fil.file_name}

                                                    </div>
                                                    <div className="col-sm-5 d-flex mb-3 mb-sm-0">
                                                        {fil.fname &&
                                                            <div className="bg-warning rounded-1 text-white py-1" style={{maxHeight:"35px",fontSize:"12px"}}>
                                                                 ملاحظات القابضة
                                                                <i
                                                                    onClick={() => handelDownloadFile2(fil.fname,fil.path,token,setLoading,`/waterFolderFilesNotes/download01`)}
                                                                    className="fa-solid fa-download text-white my-2 mx-2"
                                                                    style={{cursor: "pointer",fontSize:"10px"}}></i>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="col-sm-3 d-flex justify-content-between">
                                                        {fil.notes ?
                                                        <Button
                                                            style={{paddingBottom:"36px"}}
                                                            tooltip={fil.notes} tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }}
                                                            icon="fa-solid fa-envelope-open-text text-warning"
                                                            rounded text></Button>
                                                            :
                                                            <Button
                                                                style={{paddingBottom:"36px"}}
                                                                tooltip={fil.notes} tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }}
                                                                icon="fa-solid fa-envelope-open-text text-white"
                                                                disabled
                                                                rounded text></Button>
                                                        }
                                                        <i onClick={() => handelDownloadFile(fil.id,fil.file_name,token,'water')} className="fa-solid fa-download text-default" style={{cursor:"pointer" }}></i>

                                                    {item.send === 0 &&
                                                       <>
                                                           <i onClick={() => setFileData(fil.id)} className="fa-solid fa-copy text-primary" style={{cursor:"pointer" }}></i>
                                                           <i  onClick={() => openModal(fil.id)} className="fa-solid fa-times text-danger" style={{cursor:"pointer" }}></i>
                                                       </>
                                                    }
                                                    </div>
                                                </div>
                                                
                                            ))
                                        }
                                    </div>
                                     
                                       <div className="d-flex">
                                        {
                                            item.can_add_2 === 1 || item.can_add_3 === 1 || item.can_add_4 === 1 
                                            ? "" 
                                            : 
                                            <Button
                                            style={{ fontSize:"10px",padding:"8px"}}
                                            disabled = {item.send === 1}
                                            label="رفع ملف " icon="fa-solid fa-upload" onClick={() => setFData(item.name,item.id)}  size="small"/>

                                        }
                                           
                                            <Button
                                                style={{ fontSize:"10px",padding:"8px"}}
                                            disabled = {item.send === 1 }
                                            label={item.send === 0 ? "ارسال" :"تم الارسال" } icon="fa-solid fa-paper-plane" severity="success" size="small" className="mx-2" onClick={() => openModalSend(item.id)}/>
                                           <Button label={" تحميل الفولدر "}
                                                   onClick={() => handelDownloadPlanFolder(item.name,id,token,setLoading)}
                                                   severity="secondary" size="small" icon="fa-solid fa-download" style={{ fontSize:"10px",padding:"8px"}}/>
                                       </div>
                                </AccordionTab>
                                ))}
                    </Accordion>
                    <Button label={"تحميل الخطة"}
                            onClick={() => handelDownloadFolder(name,id,token,setLoading)}
                            severity="success" size="small" icon="fa-solid fa-download" style={{ fontSize:"12px",padding:"10px"}}/>
                    <Button label={"اعتماد الخطة"}
                            disabled = {!canApprove}
                            onClick={() => approvePlan(id)}
                            severity="secondary" size="small" icon="fa-solid fa-thumbs-up" style={{ fontSize:"12px",padding:"10px",marginRight:"5px"}}/>
                </div>
                </div>
            </div>
            <FoldersDialog level={0} run={handleRun} show={show} close={() => setShow(false)} id={id} idd={idd} folderData={folderData} token={token}></FoldersDialog>
            <ModalShow show={showDel} operationName={"حذف ملف"}
                       operationText={"حذف"} close={() => setShowDel(false)}
                       action={handelDeleteFile}
                       id={idDel}
            />
             <ModalShow show={showSend} operationName={"ارسال فولدر"}
                       operationText={"ارسال فولدر"} close={() => setShowSend(false)}
                       action={handleSendFolder}
                       id={idSend}
            />
            <ApprovePlan run={() => setRun(run + 1 )} token={token} show={showApprove} close={() => setShowApprove(false)}  id={id} code={0} kind={"approve"}/>
            <ReplaceFileDialog run={() => setRun(run + 1)} token={token} show={showReplace} close={() => setShowReplace(false)} fid={idDel} />
        </>
    )
}