import axios from 'axios';
import { Dialog } from 'primereact/dialog';
import { useState } from 'react';
import {Oval} from "react-loader-spinner";
export default function ApprovePlan(props) {
    const [loading,setLoading] = useState(false)
    const submitHandler = async (e) => {
        e.preventDefault();
        setLoading(true)
        try {
            let kind0 = 'water';
            if ( props.kind0 ) {
                kind0 = props.kind0
            }
            if ( props.kind === 'approve') {
                let res =await axios.post(`/${kind0}Plan/approve/${props.id}/${props.code}`,null,{
                    headers : {
                        Accept : "application/json",
                        Authorization : "Bearer " + props.token,
                    }})
                props.run()
                props.close()
            }else {
                let res =await axios.post(`/${kind0}Plan/cancelApprove/${props.id}/${props.code}`,null,{
                    headers : {
                        Accept : "application/json",
                        Authorization : "Bearer " + props.token,
                    }})
                props.run()
                props.close()
            }
        }catch (err) {
            console.error(err)
        }finally {
            setLoading(false)
        }
    }
    return (
        <>

            <Dialog header= {props.kind === 'approve' ? 'اعتماد' : 'الغاء اعتماد'} visible={props.show} style={{ maxWidth: '80vw',direction:"rtl" }} onHide={props.close}>

                <div className="card border-0">
                    {loading && <Oval
                        height={80}
                        width={80}
                        color="blue"
                        wrapperStyle={{}}
                        wrapperClass="spinner_container"
                        visible={true}
                        ariaLabel='oval-loading'
                        secondaryColor="gray"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                    />}
                    <form onSubmit={submitHandler} encType="multipart/form-data" id="imageForm">
                        {props.kind === 'approve' ?
                            <p className="p-2"> لن تستطيع التراجع او التعديل على الخطة بعد اعتمادها</p>
                        :
                            <p className="p-2"> لن تستطيع التراجع او اعتماد الخطة </p>
                        }
                        <div className="card-footer">
                            <button type="submit" className="btn btn-warning">
                                {props.kind === 'approve' ? 'اعتماد' : 'الغاء اعتماد'}
                            </button>
                        </div>
                    </form>
                </div>
            </Dialog>
        </>
    )
}