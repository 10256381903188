import {Card} from "primereact/card";
import {Oval} from "react-loader-spinner";
import {useContext, useState} from "react";
import WaterSupplyForm from "./WaterSupplyFormN";
import {useFormik} from "formik";
import axios from "axios";
import {User} from "../../Context";
import {useNavigate} from "react-router-dom";
import Msg from "../../components/Msg";

export default function AddWaterSupplyN() {
    const [loading,setLoading] = useState(false)
    const user = useContext(User)
    const token = user.auth.token
    const nav= useNavigate()
    const [message,setMessage] = useState("")
    const validate = values => {
        const errors = {};
        if (!values.system_name) {
            errors.system_name = 'حقل مطلوب';
        }

        return errors;
    }
    const formik = useFormik({
        initialValues: {
            system_name: '',
            system_kind : 'جوفى',
            system_dependency : 'الشركة',
            company : '',
            water_source : '-',
            near_stations : '-',
            water_alternative_source : '-',
            receiving_position : '-',
            date_created : '',
            construction_contractor : '-',
            design_power : '0',
            real_power : '0',
            emp_count : '0',
            station_size : '0',
            station_components : '-',
            power_alternative_source : '-',
            serviced_population : '-',
            net_tall : '0',
        },
        validate,
        onSubmit: async values => {

            try {

                setLoading(true)
                let res = await axios.post('/drink/create',{
                    system_name : values.system_name,
                    system_kind : values.system_kind,
                    system_dependency : values.system_dependency,
                    company : values.company,
                    water_source : values.water_source,
                    near_stations : values.near_stations,
                    water_alternative_source :values.water_alternative_source,
                    receiving_position : values.receiving_position,
                    date_created : values.date_created ? new Date(values.date_created).getFullYear() + "-" + ( parseInt(new Date(values.date_created).getMonth())+1) + "-" + new Date(values.date_created).getDate() : "" ,
                    construction_contractor : values.construction_contractor,
                    design_power : values.design_power,
                    real_power : values.real_power,
                    emp_count : values.emp_count,
                    station_size : values.station_size,
                    station_components : values.station_components,
                    power_alternative_source : values.power_alternative_source,
                    serviced_population : values.serviced_population,
                    net_tall : values.net_tall,
                    },{
                        headers : {
                            Accept : "application/json",
                            Authorization : "Bearer " + token,
                        }}
                ).catch((err) => {
                    setMessage(err.response.data.message.split(":")[1])
                })
                if ( res.status === 200 ) {
                    nav('/controlPanelN/waterSupply',{state : {message : "تم الاضافة بنجاح",kind:"success"}})
                }
            }catch(err) {

               // console.error(err)
            }finally {
                setLoading(false)
            }
        },
    });
    return (
        <div className={"mainN"} style={{ backgroundColor:"transparent"}}>
            {message !== "" && <Msg kind={"danger"} message={"يوجد خطا " + message} show={true}></Msg>}
            {loading && <Oval
            height={80}
            width={80}
            color="blue"
            wrapperStyle={{}}
            wrapperClass="spinner_container"
            visible={true}
            ariaLabel='oval-loading'
            secondaryColor="gray"
            strokeWidth={2}
            strokeWidthSecondary={2}

        />}
            <Card title={"اضافة نظام امداد مياه"}  style={{ margin: "0 auto", width: "95%" }}>
                <div  style={{overflow:"scroll",maxHeight:"500px" }}>
                <WaterSupplyForm formik={formik} actionName={"اضافة"} token={token}> </WaterSupplyForm>
                </div>
            </Card>

        </div>
    )
}