import {Card} from "primereact/card";
import {Panel} from "primereact/panel";
import {Dropdown} from "primereact/dropdown";
import React, {useContext, useEffect, useRef, useState} from "react";
import axios from "axios";
import {User} from "../../Context";
import { Button } from 'primereact/button';
import {Toast} from "primereact/toast";
export default function LibraryCategory() {
    const [categories,setCategories] = useState([]);
    const [category , setCategory] = useState("");
    const [library,setLibrary] = useState([]);
    const [names,setNames] = useState([]);
    const [name , setName] = useState("");
    const [id,setId] = useState(0)
    const user = useContext(User)
    const token = user.auth.token
    const toast = useRef(null);
    const [run,setRun]  =useState(0)
    const showSuccess = () => {
        toast.current.show({severity:'success', summary: 'Success', detail:'تم الاجراء بنجاح', life: 3000});
    }
    function handleRun ()
    {
        setRun((prev) => prev + 1)
    }
    useEffect(() => {
        async function getCategories () {
            try {
                await axios.get("/libraryCategory/show",{
                    headers : {
                        Accept : "application/json",
                        Authorization : "Bearer " + token,
                    }
                }).then ((data) => {

                    setCategories(data.data)
                    setCategory("")
                    setName("")
                })
            }catch(err){console.error(err)}
        }
        async function fetchData () {

            try {

                await axios.get("/library/show",{
                    headers : {
                        Accept : "application/json",
                        Authorization : "Bearer " + token,
                    }
                }).then((data) => {
                    setLibrary(data.data)
                    setId(0)

                })

            }catch(err) {
                console.error(err)
            }
        }

        getCategories()
        fetchData()
    },[run])


    const handleCategoryChange = (e) => {
        setCategory(e.value)
        setId(0)
        setNames(library.filter((item) => item.category_id === e.value.id))
    }


    const handleCreateCategory = async () => {
        const name = prompt("اكتب اسم التصنيف","");
        try {
            if ( name !== "" ) {
                let res = await axios.post("/libraryCategory/create", {name: name}, {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + token,
                    }
                })
                if ( res.status === 200 ) {
                    showSuccess()
                    handleRun()
                }else {
                    alert("خطا او موجود من قبل")
                }
            }
        }catch (err) {alert(err.response.data.message)}

    }
    const handleUpdateCategory = async () => {
        if ( category === "" ) {
            alert("يجب اختيار قسم عام")
            return;
        }
        const name = prompt("اكتب اسم التصنيف","");
        try {
            if ( name !== "" ) {
                let res = await axios.post(`/libraryCategory/update/${category.id}`, {name: name}, {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + token,
                    }
                })
                if ( res.status === 200 ) {
                    showSuccess()
                    handleRun()
                }else {
                    alert("خطا او موجود من قبل")
                }
            }
        }catch (err) {alert(err.response.data.message)}
    }


    const handleNameChange = (e) => {
        setName(e.value)
    }

    const handleAddSubCategory = async () => {
        if ( category === "" ) {
            alert("يجب اختيار قسم عام")
            return;
        }

        const name = prompt("اكتب اسم التصنيف الفرعى","");
        try {
            if ( name !== "" ) {
                let res = await axios.post(`/library/create/${category.id}`, {name: name}, {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + token,
                    }
                })
                if ( res.status === 200 ) {
                    showSuccess()
                    handleRun()
                }else {
                    alert("خطا او موجود من قبل")
                }
            }
        }catch (err) {alert(err.response.data.message)}
    }
    const handleEditSubCategory = async () => {
        if ( category === "" ) {
            alert("يجب اختيار قسم عام")
            return;
        }
        if ( name === "" ) {
            alert("يجب اختيار قسم فرعى")
            return;
        }
        const name0 = prompt("اكتب اسم التصنيف الفرعى","");
        try {
            if ( name0 !== "" ) {
                let res = await axios.post(`/library/update/${name.id}`, {name: name0}, {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + token,
                    }
                })
                if ( res.status === 200 ) {
                    showSuccess()
                    handleRun()
                }else {
                    alert("خطا او موجود من قبل")
                }
            }
        }catch (err) {alert(err.response.data.message)}
    }
    return (
        <>
            <Toast ref={toast} position={"top-center"}/>
        <Card title={"التحكم فى التصنيفات"} className="m-3">
            <Panel header="التصنيفات العامة">
                <div className="d-flex">
                    <div className="col-sm-3 my-sm-0 my-2">
                        <Dropdown value={category} onChange={(e) => handleCategoryChange(e)} options={categories} optionLabel="name"
                                  placeholder="اختر تصنيف عام" className="w-100 md:w-14rem" />
                    </div>
                    <Button onClick={handleCreateCategory} icon="fas fa-plus-circle" className="mx-2" severity="success" rounded outlined aria-label="Add" />
                    <Button onClick={handleUpdateCategory} icon="fas fa-pen" className="mx-2" severity="secondary" rounded outlined aria-label="Edit" />
                </div>
            </Panel>
            <Panel header="التصنيفات الفرعية" className="my-3">
                <div className="d-flex">
                    <div className="col-sm-3 mb-sm-0 mb-2">
                        <Dropdown value={name} onChange={(e) => handleNameChange(e)} options={names} optionLabel="name"
                                  placeholder="اختر تصنيف فرعى" className="w-100 md:w-14rem" />
                    </div>
                    <Button onClick={handleAddSubCategory} icon="fas fa-plus-circle" className="mx-2" severity="success" rounded outlined aria-label="Add" />
                    <Button onClick={handleEditSubCategory} icon="fas fa-pen" className="mx-2" severity="secondary" rounded outlined aria-label="Edit" />
                </div>
            </Panel>
        </Card>
        </>
    )
}