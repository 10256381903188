import { Link, useNavigate } from "react-router-dom";
import React, { useContext, useEffect, useRef, useState } from "react";
import { User } from "../../Context";
import { Oval } from "react-loader-spinner";
import { Card } from "primereact/card";
import { Dropdown } from 'primereact/dropdown';
import { Button } from "primereact/button";
import axios from "axios";
import UploadFile from "./UploadFile";
import ModalShow from "../../components/ModalShow";
import { saveAs } from 'file-saver';
import AddLibraryFolder from "./AddLibraryFolder";
import { Toast } from "primereact/toast";
import { Accordion, AccordionTab } from 'primereact/accordion';
export default function LibraryN() {
    const [show, setShow] = useState(false)
    const [showDel, setShowDel] = useState(false)
    const [idDel, setIdDel] = useState(0)
    const [id, setId] = useState(0)
    const [categories, setCategories] = useState([]);
    const [category, setCategory] = useState("");
    const [library, setLibrary] = useState([]);
    const [names, setNames] = useState([]);
    const [name, setName] = useState("");
    const [docs, setDocs] = useState([]);
    const [run, setRun] = useState(0)
    const [loading, setLoading] = useState(false)
    const user = useContext(User)
    const token = user.auth.token
    const nav = useNavigate()
    const [addFolder, setAddFolder] = useState(false);
    const toast = useRef(null);
    const [folders, setFolders] = useState([]);
    const [foldersFiles, setFoldersFiles] = useState([]);
    const showSuccess = () => {
        toast.current.show({ severity: 'success', summary: 'Success', detail: 'تم الاجراء بنجاح', life: 3000 });
    }
    function handleRun() {
        setRun((prev) => prev + 1)
    }

    useEffect(() => {


        async function getCategories() {
            try {
                await axios.get("/libraryCategory/show", {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + token,
                    }
                }).then((data) => {

                    setCategories(data.data)
                })
            } catch (err) { console.error(err) }
        }

        async function fetchData() {

            try {
                setLoading(true)
                await axios.get("/library/show", {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + token,
                    }
                }).then((data) => {
                    setLibrary(data.data)
                    setCategory("")
                    setName("")
                    setId(0)
                    setDocs([])
                    setFoldersFiles([])
                    setFolders([])
                })

            } catch (err) {
                console.error(err)
            } finally {
                setLoading(false)
            }
        }
        getCategories()
        fetchData()
    }, [run])

    const handleCategoryChange = (e) => {
        setCategory(e.value)
        setId(0)
        setNames(library.filter((item) => item.category_id === e.value.id))
    }
    const handleNameChange = (e) => {
        setName(e.value)
        setId(e.value.id)
    }

    const handleUploadFile = () => {
        if (id !== 0) {
            setShow(true)
        } else {
            alert("لم تختر بيانات")
        }

    }

    const handleGetDocs = async () => {
        if (id === 0) {
            alert("لم تختر بيانات")
        }
        try {
            setLoading(true)
            await axios.get(`/librarydoc/show/${id}`, {
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + token,
                }
            }).then((data) => {

                setDocs(data.data.docs)
                setFolders(data.data.folders)
                setFoldersFiles(data.data.foldersFiles)
            })
        } catch ($err) {
            console.error($err)
        } finally {
            setLoading(false)
        }
    }

    const handelDeleteFile = async () => {
        try {
            setLoading(true)
            let res = await axios.delete(`/librarydoc/delete/${idDel}`, {
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + token,
                }
            })
            if (res.status === 200) {
                await handleGetDocs()
                setShowDel(false)
            }
        } catch ($err) {
            console.error($err)
        } finally {
            setLoading(false)
        }
    }
    const handleDownloadFile = async (id, fileName) => {
        try {
            const axiosConfigration = {
                responseType: "arraybuffer", headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + token,
                }
            }
            await axios.get(`/librarydoc/download/${id}`, axiosConfigration
            ).then(response => {

                saveAs(new Blob([response.data]), fileName);
            })
        } catch (err) {
            console.error(err)
        }
    }

    const showAddFolder = () => {
        if (id === 0) {
            alert("لم تختر بيانات")
        } else {
            setAddFolder(true)
        }
    }

    const handleAddFolder = async (folderName) => {
        try {

            let res = await axios.post(`/libraryFolder/create/${id}`, { folderName: folderName }, {
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + token,
                }
            })
            if (res.status === 200) {
                setAddFolder(false)
                showSuccess()
                await handleGetDocs()
            }

        } catch (err) { console.log(err) }
    }

    const handelDeleteFolder = async (folderId) => {
        try {
            let result;
            // eslint-disable-next-line no-restricted-globals
            result = confirm("تحذير سيتم حذف الفولدر وكافة الملفات بداخله")
            if (result) {
                let res = await axios.delete(`/libraryFolder/delete/${folderId}`, {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + token,
                    }
                })
            }
        } catch (err) { console.log(err) }
    }
    return (
        <>

            <div className="mainN" style={{ backgroundColor: "transparent" }}>
                <Toast ref={toast} position={"top-center"} />
                {loading && <Oval
                    height={80}
                    width={80}
                    color="blue"
                    wrapperStyle={{}}
                    wrapperClass="spinner_container"
                    visible={true}
                    ariaLabel='oval-loading'
                    secondaryColor="gray"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                />}

                <Card style={{ direction: "rtl", margin: "0 auto", width: "90%" }}>
                <div style={{ overflow: "scroll", minHeight:"500px" }}>
                    <div className="d-flex flex-row">
                        <h3 className="fs-5 m-3"> المكتبة الالكترونية</h3>
                        <span className="m-3 border-end border-light-2"></span>
                        <Link to="/controlPanelN/libraryCategory" className="btn btn-sm btn-info text-white m-3 shadow-sm">التصنيفات</Link>
                    </div>
                    <div className="row mb-5" >
                        <div className="col-sm-3 my-sm-0 my-2">
                            <Dropdown value={category} onChange={(e) => handleCategoryChange(e)} options={categories} optionLabel="name"
                                placeholder="اختر تصنيف عام" className="w-100 md:w-14rem" />
                        </div>
                        <div className="col-sm-3 mb-sm-0 mb-2">
                            <Dropdown value={name} onChange={(e) => handleNameChange(e)} options={names} optionLabel="name"
                                placeholder="اختر تصنيف فرعى" className="w-100 md:w-14rem" />
                        </div>

                        <div className="col-sm-2 d-flex">
                            <Button icon="fas fa-search" rounded severity="success" aria-label="Search" className="mx-1" onClick={handleGetDocs} />
                            <Button icon="fas fa-folder-open" rounded outlined severity="default" className="mx-1" aria-label="Search" onClick={showAddFolder} />
                            <Button icon="fas fa-plus" rounded severity="secondary" aria-label="Add" className="mx-1" onClick={handleUploadFile} />
                        </div>
                    </div>
                    {docs.map((item, index) => (
                        <div key={index} className="row border-bottom p-2">
                            <div className="col-sm-1 mb-sm-0 mb-2">{index + 1}</div>
                            <div className="col-sm-10 mb-sm-0 mb-2">
                                {
                                    (item.fname.toLowerCase()).includes("jpg") || (item.fname.toLowerCase()).includes("jpeg") || (item.fname.toLowerCase()).includes("png") || (item.fname.toLowerCase()).includes("jif") ?
                                        <i className="fa-solid fa-image text-info mx-2"></i>
                                        :
                                        (item.fname.toLowerCase()).includes("pdf") ? <i className="fa-solid fa-file-pdf text-danger mx-2"></i>
                                            :
                                            (item.fname.toLowerCase()).includes("doc") ? <i className="fa-solid fa-file-word text-primary mx-2"></i> :
                                                (item.fname.toLowerCase()).includes("xls") ? <i className="fa-solid fa-table text-success mx-2"></i> :
                                                    <i className="fa-solid fa-file text-default mx-2"></i>
                                }
                                {item.fname}</div>
                            <div className="col-sm-1 d-flex">
                                <i className="fas fa-download mx-4 cursor-pointer" onClick={() => handleDownloadFile(item.id, item.fname)}></i>
                                <i className="fas fa-times cursor-pointer" onClick={() => { setIdDel(item.id); setShowDel(true) }}></i>

                            </div>
                        </div>
                    ))}
                    {/*folders*/}
                    <Accordion style={{ maxWidth: "800px", marginTop: "15px" }}>
                        {
                            folders.map((fold, i) => (
                                <AccordionTab
                                    header={
                                        <div className="flex align-items-between flex-row">
                                            <i className="fas fa-folder-open mr-2"></i>
                                            <span className="vertical-align-middle">{fold.folder_name}</span>
                                            <Button icon="fa-solid fa-times" rounded text severity="danger" aria-label="Cancel"
                                                style={{ fontSize: "12px", margin: "-19px 0px" }} onClick={() => handelDeleteFolder(fold.id)} />
                                        </div>
                                    }
                                    key={i}>
                                    {foldersFiles.filter((f) => f.library_folder_id === fold.id).map((fil, ii) => (
                                        <div key={ii} className="row border-bottom p-2" style={{ maxWidth: "800px" }}>
                                            <div className="col-sm-1 mb-sm-0 mb-2">{ii + 1}</div>
                                            <div className="col-sm-8 mb-sm-0 mb-2">
                                                {
                                                    (fil.fname.toLowerCase()).includes("jpg") || (fil.fname.toLowerCase()).includes("jpeg") || (fil.fname.toLowerCase()).includes("png") || (fil.fname.toLowerCase()).includes("jif") ?
                                                        <i className="fa-solid fa-image text-info mx-2"></i>
                                                        :
                                                        (fil.fname.toLowerCase()).includes("pdf") ? <i className="fa-solid fa-file-pdf text-danger mx-2"></i>
                                                            :
                                                            (fil.fname.toLowerCase()).includes("doc") ? <i className="fa-solid fa-file-word text-primary mx-2"></i> :
                                                                (fil.fname.toLowerCase()).includes("xls") ? <i className="fa-solid fa-table text-success mx-2"></i> :
                                                                    <i className="fa-solid fa-file text-default mx-2"></i>
                                                }
                                                {fil.fname}</div>
                                            <div className="col-sm-3 d-flex">
                                                <i className="fas fa-download mx-4 cursor-pointer" onClick={() => handleDownloadFile(fil.id, fil.fname)}></i>
                                                <i className="fas fa-times cursor-pointer" onClick={() => { setIdDel(fil.id); setShowDel(true) }}></i>

                                            </div>
                                        </div>))
                                    }
                                </AccordionTab>
                            ))

                        }
                    </Accordion>
                    </div>
                </Card>

            </div>
            <UploadFile show={show} id={id} close={() => setShow(false)} run={handleGetDocs} token={token} />
            <ModalShow show={showDel} operationName={"حذف ملف"}
                operationText={"حذف"} close={() => setShowDel(false)}
                action={handelDeleteFile}
                id={idDel}
            />
            <AddLibraryFolder show={addFolder} close={() => setAddFolder(false)} token={token} id={id} action={handleAddFolder} />
        </>
    )
}