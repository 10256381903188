
import { useNavigate} from "react-router-dom";
import { useFormik } from 'formik';
import {Button} from "react-bootstrap";
import {useContext,  useState} from "react";
import {User} from '../../Context'
import axios from "axios";
import Cookies from "universal-cookie";
import {Oval} from "react-loader-spinner";
import '../../backend.css'
export default function Login () {
    const [loading,setLoading] = useState(false)
    const userNow = useContext(User);
    const nav = useNavigate();
    const cookie = new Cookies();
    const [showMsg,setShowMsg] = useState(false);
    axios.defaults.baseURL = "https://wssp.hcww.com.eg/api";
    const validate = values => {
        const errors = {};
        if (!values.password) {
            errors.password = 'حقل مطلوب';
        }
        if (!values.email) {
            errors.email = 'حقل مطلوب';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
            errors.email = 'حقل الايميل غير صالح';
        }
        return errors;
    };
    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validate,
        onSubmit: async values => {
            try {
                setLoading(true)
                setShowMsg(false)
                let res = await axios.post(axios.defaults.baseURL + '/login',{
                    email : values.email,
                    password:values.password
                });
                if ( res.status === 200 ) {
                    let token = res.data.data.token
                    let user = res.data.data.user
                    document.cookie = "Bearer=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                    cookie.set('Bearer',token,{path:"/",maxAge:3600});
                    userNow.setAuth({token,user});
                    if ( user.auth === 0 ) {
                        nav('/controlPanelN');
                    }else {
                        nav('/home');
                    }
                }
            }catch(err) {
                setShowMsg(true)
                console.log(err.response.status)
            }finally {
                setLoading(false)
            }
        },
    });
    return (
        <>

            {loading && <Oval
                height={80}
                width={80}
                color="blue"
                wrapperStyle={{}}
                wrapperClass="spinner_container"
                visible={true}
                ariaLabel='oval-loading'
                secondaryColor="gray"
                strokeWidth={2}
                strokeWidthSecondary={2}

            />}

            <div className="main-login" style={{direction:'rtl',height:"100vh"}}>

               <div className="row bg-white border border-light-2 rounded shadow-sm mx-auto" style={{maxWidth:"500px",marginTop:"100px"}}>
                    <h3 className="fs-5 mb-3 px-3 mt-1 text-center mt-4"> تسجيل الدخول</h3>
                   <form onSubmit={formik.handleSubmit}>
                       <div className="row p-3">
                       <label htmlFor="email" className="mb-2">الايميل</label>
                           <input
                               id="email"
                               name="email"
                               type="email"
                               className="form-control"
                               onChange={formik.handleChange}
                               onBlur={formik.handleBlur}
                               value={formik.values.email}
                           />
                           {formik.touched.email && formik.errors.email ? <div className="text-danger">{formik.errors.email}</div> : null}
                               <label htmlFor="password" style={{ marginTop:"20px"}}>الباسورد</label>
                               <input
                                   id="password"
                                   name="password"
                                   type="password"
                                   className="form-control"
                                   onChange={formik.handleChange}
                                   onBlur={formik.handleBlur}
                                   value={formik.values.name}
                               />
                               {formik.touched.password && formik.errors.password ? <div className="text-danger">{formik.errors.password}</div> : null}
                           {showMsg ? <div className="text-danger text-center py-2">خطا فى بيانات الدخول</div> : ""}
                           
                           <Button variant="success" type="submit" className="mt-4">
                               تسجيل الدخول
                           </Button>
                       </div>
                   </form>

               </div>
            </div>
        </>
    )
}