import heroImg from "../../assets/frontend/hero_image_last.png";

export default function Hero() {
    return (<>
        <section id="hero" className="d-flex align-items-center">

            <div className="container">
                <div className="row">
                    <div style={{marginBottom:"30px"}} className="col-lg-6 pt-5 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center">
                        <h1 data-aos="fade-up" className="lh-sm text-center fw-bolder" style={{textAlign:"justify",textJustify:"inter-word",color:"#074255"}}>
                            المنصة الرقمية <br/>
                            <span className="fs-3 f2-bolder">لخطط مأمونية المياه والصرف الصحي والحمأة</span>
                        </h1>
                        <h5 data-aos="fade-up" data-aos-delay="400"
                            className="lh-base"
                            style={{textAlign:"justify",textJustify:"inter-word"}}>طبقا لمنظمة الصحة العالمية تعتبر خطط المأمونية هي الضمانة الرئيسية لحماية الصحة
                            والبيئة وتم تصميم المنصة من قبل مختصين علي أعلي مستوي لتسهيل عملية
                            المراجعة للخطط والتواصل بين الجهات المختلفة المشاركه في إعداد وتنفيذ ومراجعة الخطط</h5>
                        {/*<div data-aos="fade-up" data-aos-delay="800">*/}
                        {/*    <a href="#about" className="btn-get-started scrollto">Get Started</a>*/}
                        {/*</div>*/}
                    </div>
                    <div  className="col-lg-6 order-1 order-lg-2 hero-img d-flex align-items-center justify-content-center mt-sm-0 mt-5" data-aos="fade-left" data-aos-delay="200">
                        <img src={heroImg} className="img-fluid animated" alt=""/>
                    </div>
                </div>
            </div>

        </section>
    </>)
}