import Header from "./HeaderN";
import ChartsBlocks from "./ChartsBlocksN";
import { useOutletContext } from "react-router-dom";

import CardsN from "./CardsN";
export default function DashboardMain() {
    const [toggleSidebar,openSidebar] = useOutletContext();
    return <div>
            <CardsN></CardsN>
            <ChartsBlocks></ChartsBlocks>
    </div>
}