import axios from 'axios';
import { Dialog } from 'primereact/dialog';
import {useEffect, useState} from 'react';
import {Oval} from "react-loader-spinner";
export default function FilesNotesDialog(props) {
    const [notes , setNotes] = useState("")
    const [loading,setLoading] = useState(false)

    useEffect( () => {
        async  function  getNotes() {
            setNotes("")
               if ( props.show ===  true ) {
                   let kind = 'water';
                   if ( props.kind ) {
                       kind = props.kind
                   }
                   let url = `/${kind}FolderFilesNotes/show/${props.id}`;
                   if ( props.level ) {
                       url = `/${kind}FolderFilesNotes_${props.level}/show/${props.id}`;
                   }
                   try {
                       let res = await axios.get(axios.defaults.baseURL + url,
                           {headers : {
                                   Accept : "application/json",
                                   Authorization : "Bearer " + props.token,
                               }})
                       if ( res.status === 200 ) {
                           if (props.level ) {
                               setNotes(res.data[`notes${props.level}`])
                           }else {
                               setNotes(res.data.notes)
                           }
                       }else {
                           setNotes("")
                       }
                   }catch (err) {
                       console.error(err)
                       setNotes("")
                   }
               }
            }
        getNotes()
    },[props.show])

   const submitHandler = async (e) => {

        e.preventDefault();
        setLoading(true)

        try {
            let kind = 'water';
            if ( props.kind ) {
                kind = props.kind
            }
            let url = `/${kind}FolderFilesNotes/saveNotes/${props.id}`;
            if ( props.level ) {
                url = `/${kind}FolderFilesNotes_${props.level}/saveNotes/${props.id}`;
            }
            let res = await axios.post(axios.defaults.baseURL + url,
                {notes : notes},{headers : {
                Accept : "application/json",
                Authorization : "Bearer " + props.token,
            }})
            if ( res.status === 200 ) {
                props.run();
                props.close();
            }
        }catch (err) {
            console.error(err)
        }finally {
            setLoading(false)
        }
   }

    const handleChange = (e) => {
        setNotes(e.target.value)
    }
    return (
        <>
        
        <Dialog header="ملاحظات الملف" visible={props.show} style={{minWidth:"30vw", maxWidth: '80vw',direction:"rtl" }} onHide={props.close}>
       
            <div className="card">
            {loading && <Oval
            height={80}
            width={80}
            color="blue"
            wrapperStyle={{}}
            wrapperClass="spinner_container"
            visible={true}
            ariaLabel='oval-loading'
            secondaryColor="gray"
            strokeWidth={2}
            strokeWidthSecondary={2}
        />}
            <form onSubmit={submitHandler}>
                <div className="form-group p-4">
                    <label htmlFor="notes" className='mb-2'> الملاحظات</label>
                    <textarea
                        name="notes"
                        onChange={handleChange}
                        className="form-control"
                        rows={5}
                        value={notes}
                    />

                </div>
                  <div className="card-footer">
                    <button type="submit" className="btn btn-success">
                        Save
                    </button>
                </div>
            </form>
            </div>
        </Dialog>
        </>
    )
}