import {Oval} from "react-loader-spinner";
import React, {useContext, useEffect, useState} from "react";
import {Card} from "primereact/card";
import {Dropdown} from "primereact/dropdown";
import { saveAs } from 'file-saver';
import axios from "axios";
import {User} from "../../Context";
import {Accordion, AccordionTab} from "primereact/accordion";

export default function LibraryF() {
    const [loading,setLoading] = useState(false)
    const [libraryData,setLibraryData] = useState([])
    const [library,setLibrary] = useState({})
    const [docs,setDocs] = useState([]);
    const [run,setRun]  =useState(0)
    const [categories,setCategories] = useState([]);
    const [category , setCategory] = useState("");
    const [names,setNames] = useState([]);
    const [name , setName] = useState("");
    const user = useContext(User)
    const token = user.auth.token
    const [folders,setFolders] = useState([]);
    const [foldersFiles,setFoldersFiles]=useState([]);
    useEffect(  () => {
        async function getCategories () {
            try {
                await axios.get("/libraryCategory/show",{
                    headers : {
                        Accept : "application/json",
                        Authorization : "Bearer " + token,
                    }
                }).then ((data) => {

                    setCategories(data.data)
                })
            }catch(err){console.error(err)}
        }
        async function fetchData () {

            try {
                setLoading(true)
                await axios.get("/library/show",{
                    headers : {
                        Accept : "application/json",
                        Authorization : "Bearer " + token,
                    }
                }).then((data) => {
                    setLibrary(data.data)
                    setCategory("")
                    setName("")
                    setDocs([])

                })

            }catch(err) {
                console.error(err)
            }finally {
                setLoading(false)
            }
        }
        getCategories()
        fetchData()
    },[run])
    const handleCategoryChange = (e) => {
        setCategory(e.value)
        setNames(library.filter((item) => item.category_id === e.value.id))
        //
    }
    const handleNameChange = (e) => {
        setName(e.value)
        handleGetDocs(e.value.id)
    }
    const handleDownloadFile = async (id,fileName) => {
        try {
            const axiosConfigration = {
                responseType : "arraybuffer",  headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + token,
                }
            }
            await axios.get(`/librarydoc/download/${id}`,axiosConfigration
            ).then(response => {

                saveAs(new Blob([response.data]), fileName);
            })
        }catch(err) {
            console.error(err)
        }
    }
    const  handleGetDocs = async (id) => {
        try {
            setLoading(true)
            await axios.get(`/librarydoc/show/${id}`,{
                headers : {
                    Accept : "application/json",
                    Authorization : "Bearer " + token,
                }
            }).then((data) => {
                setDocs(data.data.docs)
                setFolders(data.data.folders)
                setFoldersFiles(data.data.foldersFiles)

            })
        }catch($err) {
            console.error($err)
        }finally {
            setLoading(false)
        }
    }
    return (
        <div  style={{ marginTop:"40px",width:"100%",padding:"10px"}}>
            {loading && <Oval
                height={80}
                width={80}
                color="blue"
                wrapperStyle={{}}
                wrapperClass="spinner_container"
                visible={true}
                ariaLabel='oval-loading'
                secondaryColor="gray"
                strokeWidth={2}
                strokeWidthSecondary={2}
            />}
            <div style={{minHeight:"500px",maxWidth:"800px" , margin:"75px auto 0 auto",padding:"10px"}}>
                <div className="text-center mt-4" data-aos="fade-up">
                    <h2>المكتبة الالكترونية</h2>
                </div>
                <Card>
                    <div className="row mb-5">
                        <div className="col-sm-5 my-sm-0 my-2">
                            <Dropdown value={category} onChange={(e) => handleCategoryChange(e)} options={categories} optionLabel="name"
                                      placeholder="اختر تصنيف عام" className="w-100 md:w-14rem" />
                        </div>
                        <div className="col-sm-5 mb-sm-0 mb-2">
                            <Dropdown value={name} onChange={(e) => handleNameChange(e)} options={names} optionLabel="name"
                                      placeholder="اختر تصنيف فرعى" className="w-100 md:w-14rem" />
                        </div>
                        {/*<div className="col-sm-8 my-sm-0 my-2">*/}
                        {/*    <Dropdown value={library} onChange={(e) => handleCategoryChange(e)} options={libraryData} optionLabel="label"*/}
                        {/*              optionGroupLabel="label" optionGroupChildren="items" placeholder="اقسام المكتبة" className="w-100 md:w-14rem" />*/}
                        {/*</div>*/}
                    </div>
                    {docs.map((item,index) => (
                        <div key={index} className="row border-bottom p-2" style={{maxWidth:"800px"}}>
                            <div className="col-sm-1 mb-sm-0 mb-2">{index+1}</div>
                            <div className="col-sm-8 mb-sm-0 mb-2">
                                {
                                    (item.fname.toLowerCase()).includes("jpg") || (item.fname.toLowerCase()).includes("jpeg")  || (item.fname.toLowerCase()).includes("png") || (item.fname.toLowerCase()).includes("jif") ?
                                        <i className="fa-solid fa-image text-info mx-2"></i>
                                        :
                                        (item.fname.toLowerCase()).includes("pdf") ? <i className="fa-solid fa-file-pdf text-danger mx-2"></i>
                                            :
                                            (item.fname.toLowerCase()).includes("doc") ? <i className="fa-solid fa-file-word text-primary mx-2"></i> :
                                                (item.fname.toLowerCase()).includes("xls") ? <i className="fa-solid fa-table text-success mx-2"></i> :
                                                    <i className="fa-solid fa-file text-default mx-2"></i>
                                }
                                {item.fname}</div>
                            <div className="col-sm-3 d-flex">
                                <i className="fas fa-download mx-4 cursor-pointer" onClick={() => handleDownloadFile(item.id,item.fname)}></i>

                            </div>
                        </div>
                    ))}
                    {/*folders*/}
                    <Accordion style={{maxWidth:"800px",marginTop:"15px"}}>
                        {
                            folders.map((fold,i) =>(
                                <AccordionTab
                                    header={
                                        <div className="flex align-items-center">
                                            <i className="fas fa-folder-open mr-2"></i>
                                            <span className="vertical-align-middle">{fold.folder_name}</span>
                                        </div>
                                    }
                                    key={i}>
                                    {foldersFiles.filter((f)=> f.library_folder_id === fold.id).map((fil,ii) => (
                                        <div key={ii} className="row border-bottom p-2" style={{maxWidth:"800px"}}>
                                            <div className="col-sm-1 mb-sm-0 mb-2">{ii+1}</div>
                                            <div className="col-sm-8 mb-sm-0 mb-2">
                                                {
                                                    (fil.fname.toLowerCase()).includes("jpg") || (fil.fname.toLowerCase()).includes("jpeg")  || (fil.fname.toLowerCase()).includes("png") || (fil.fname.toLowerCase()).includes("jif") ?
                                                        <i className="fa-solid fa-image text-info mx-2"></i>
                                                        :
                                                        (fil.fname.toLowerCase()).includes("pdf") ? <i className="fa-solid fa-file-pdf text-danger mx-2"></i>
                                                            :
                                                            (fil.fname.toLowerCase()).includes("doc") ? <i className="fa-solid fa-file-word text-primary mx-2"></i> :
                                                                (fil.fname.toLowerCase()).includes("xls") ? <i className="fa-solid fa-table text-success mx-2"></i> :
                                                                    <i className="fa-solid fa-file text-default mx-2"></i>
                                                }
                                                {fil.fname}</div>
                                            <div className="col-sm-3 d-flex">
                                                <i className="fas fa-download mx-4 cursor-pointer" onClick={() => handleDownloadFile(fil.id,fil.fname)}></i>
                                            </div>
                                        </div>))
                                    }
                                </AccordionTab>
                            ))

                        }
                    </Accordion>

                </Card>
            </div>
        </div>
    )
}