import axios from "axios";
import { saveAs } from 'file-saver';
export async function handelDownloadFile(id,fileName,token,kind) {
    try {
        const axiosConfigration = {
            responseType : "arraybuffer",  headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            }
        }
        await axios.get(`/${kind}FolderFiles/download/${id}`,axiosConfigration
        ).then(response => {
                
               saveAs(new Blob([response.data]), fileName);
            })
    }catch(err) {
        console.error(err)
    }
}

export async function handelDownloadFile2(fileName,path,token,setLoading,url) {
    try {
        setLoading(true)
        const axiosConfigration = {
            responseType : "arraybuffer",  headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            }
        }
        await axios.post(url,{path : path},axiosConfigration
        )
            .then(response => {

                saveAs(new Blob([response.data]), fileName);
            })
    }catch(err) {
        console.error(err)
    }finally {
        setLoading(false)
    }
}

export async function handelDownloadPlanFolder(folderName,id,token,setLoading) {
    try {
        setLoading(true)
        //responseType : "arraybuffer",
        const axiosConfigration = {
            responseType : "arraybuffer",headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            }
        }
        await axios.post(`/waterFolderFiles/downloadPlanFolder/${id}/${folderName}`,null,axiosConfigration
        )
            .then(response => {

                saveAs(new Blob([response.data]), folderName + ".rar");
            })
    }catch(err) {
        console.error(err)
    }finally {
        setLoading(false)
    }
}
export async function handelDownloadSewagePlanFolder(folderName,id,token,setLoading) {
    try {
        setLoading(true)
        //responseType : "arraybuffer",
        const axiosConfigration = {
            responseType : "arraybuffer",headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            }
        }
        await axios.post(`/sewageFolderFiles/downloadPlanFolder/${id}/${folderName}`,null,axiosConfigration
        )
            .then(response => {

                saveAs(new Blob([response.data]), folderName + ".rar");
            })
    }catch(err) {
        console.error(err)
    }finally {
        setLoading(false)
    }
}
export async function handelDownloadFolder(folderName,id,token,setLoading) {
    try {
        setLoading(true)
        //responseType : "arraybuffer",
        const axiosConfigration = {
            responseType : "arraybuffer",headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            }
        }
       await axios.post(`/waterFolderFiles/downloadFolder/${id}`,null,axiosConfigration
        )
            .then(response => {

                saveAs(new Blob([response.data]), folderName + ".rar");
            })
    }catch(err) {
        console.error(err)
    }finally {
        setLoading(false)
    }
}
export async function handelDownloadSewageFolder(folderName,id,token,setLoading) {
    try {
        setLoading(true)
        //responseType : "arraybuffer",
        const axiosConfigration = {
            responseType : "arraybuffer",headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            }
        }
       await axios.post(`/sewageFolderFiles/downloadFolder/${id}`,null,axiosConfigration
        )
            .then(response => {

                saveAs(new Blob([response.data]), folderName + ".rar");
            })
    }catch(err) {
        console.error(err)
    }finally {
        setLoading(false)
    }
}
export async function getPlanData(id,token,kind) {
   try {
       let data = {};

       await axios.post(`${kind}Plan/plan/${id}`,null,{
           headers : {
               Accept: "application/json",
               Authorization: "Bearer " + token,
           }
       }).then(res => {
           data = res.data;
       })
return data;
   }catch(err){
       console.log(err)
   }
}
