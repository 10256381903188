import CardItem from "./CardItemN";
import wsp from "../assets/frontend/navbarIcons/wsp-small.png";
import ssp from "../assets/frontend/navbarIcons/ssp-small.png";
import ssh from "../assets/frontend/navbarIcons/ssh-small.png";
import library from "../assets/frontend/navbarIcons/library.png"
import {useContext, useEffect, useState} from "react";
import axios from "axios";
import {User} from "../Context";
export default function CardsN() {
    const user = useContext(User)
    const token = user.auth.token
    const [data,setData] = useState(
        {
            wsp : 0,
            ssp : 0,
            ssh : 0,
            library : 0
        }
    )
    useEffect(() => {
        async function getData() {
            try {

                let res = await axios("/dashboard/show",{
                    headers : {
                        Authorization : "Bearer " + token
                    }
                }).then((data) => {
                    setData(data.data.data)
                })
            }catch (err) {
                console.error(err)
            }
        }
        getData()
    },[])
    return <div className="row mx-4">
        <CardItem title="خطط امداد المياه" icon="bg-gradient-red" image={wsp}  value={data.wsp}></CardItem>
        <CardItem title="خطط انظمة المعالجة" icon="bg-gradient-orange" image={ssp}  value={data.ssp}></CardItem>
        <CardItem title="خطط انظمة الحمأة" value={data.ssh} icon="bg-gradient-primary" image={ssh}></CardItem>
        <CardItem title="المكتبة الالكترونية" value={data.library} icon="bg-gradient-green" image={library}></CardItem>
    </div>
}