import React from 'react'
import { User } from "../../../Context";
import { Button } from "primereact/button";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { InputText } from 'primereact/inputtext';
import ApproveSludgePlanShow from "../../../dashboard/sludgePlans/ApproveSludgePlanShow";
import { Timeline } from 'primereact/timeline';
import ModalTrack from '../../../components/ModalTrack';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Card } from 'primereact/card';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { RadioButton } from "primereact/radiobutton";
function SludgePlansFN() {
    const [ingredient, setIngredient] = useState('0');
    const [plans, setPlan] = useState([]);
    const [fPlans, setFPlans] = useState([]);
    const user = useContext(User)
    const token = user.auth.token
    const role = user.auth.user.role;
    const [loading, setLoading] = useState(false)
    const nav = useNavigate()
    const [showApproveData, setShowApproveData] = useState(false)
    const [id, setId] = useState(0)
    const [showTrack, setShowTrack] = useState(false);
    const [track, setTrack] = useState(0);
    const [run, setRun] = useState(0);
    const [expandedRows, setExpandedRows] = useState(null);
    const customizedMarker = (item) => {
        return (

            <i className={item.icon} style={{ color: item.color, fontSize: "20px" }}></i>

        );
    };
    const customizedContent = (item) => {
        return (
            <div style={{ width: "100px", textAlign: "right" }}>
                <p style={{ fontSize: '12px' }}>{item.name}</p>
            </div>

        );
    };
    const rowExpansionTemplate = (data) => {
        return (
            <div className="p-3">
                <Timeline
                    value={[{ name: data.company, icon: "fa-solid fa-circle-check", color: data.approve_0 === 1 ? 'green' : 'gray' },
                    { name: 'القابضة', icon: "fa-solid fa-circle-check", color: data.approve_1 === 1 ? 'green' : 'gray' },
                    { name: 'الجهاز', icon: "fa-solid fa-circle-check", color: data.approve_2 === 1 ? 'green' : 'gray' },
                    { name: 'الصحة-لجنة عليا', icon: "fa-solid fa-circle-check", color: data.approve_3 === 1 ? 'green' : 'gray' }]}
                    layout="horizontal" content={customizedContent} marker={customizedMarker} />
            </div>
        );
    };
    const allowExpansion = (rowData) => {
        return rowData.plan_name.length > 0;
    };
    useEffect(() => {
        if (role === 2 || role === 3) {
            setIngredient("2")
        }
        async function getData() {
            try {
                setLoading(true)

                let url = "";
                if (role === 0 || role === 4) {
                    url = `/sludgePlan/showFront/${user.auth.user.id}`
                } else if (role === 1 || role === 5) {
                    url = `/sludgePlan/showFront_2/${user.auth.user.id}`
                } else {
                    url = `/sludgePlan/showFront_1`
                }

                let res = await axios(url, {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + token,
                    }
                }).then((data) => {
                    setPlan(data.data)
                    if (role === 4 || role === 0 || role === 1 || role === 5) {
                        setFPlans(data.data)
                    } else {
                        if (role === 2) {
                            setPlan(data.data.filter(item => item.see_1 === 1))
                            setFPlans(data.data.filter(item => item.approve_0 === 1 && item.approve_3 !== 1 && item.see_1 === 1))
                        } else {
                            setPlan(data.data.filter(item => item.see_2 === 1))
                            setFPlans(data.data.filter(item => item.approve_0 === 1 && item.approve_3 !== 1 && item.see_2 === 1))
                        }

                    }
                }
                )

            } catch (err) {
                console.error(err)
            } finally {
                setLoading(false)
            }
        }
        getData()

    }, [])

    const openPlan = async (id, plan_name, approved, see_1, see_2, version) => {
        try {

            if (role === 0 || role === 4) {
                nav("/home/sludgePlanFolders", {
                    state: { id: id, name: plan_name, token: token, approved: approved, version: version }
                })
            } else if (role === 1 || role === 5) {
                nav("/home/sludgePlanFolders01", {
                    state: { id: id, name: plan_name, token: token, see: see_1, version: version }
                })
            }
            else if (role === 2) {
                nav("/home/sludgePlanFolders02", {
                    state: { id: id, name: plan_name, token: token, see: see_2, version: version }
                })

            } else if (role === 3) {
                nav("/home/sludgePlanFolders03", {
                    state: { id: id, name: plan_name, token: token, version: version }
                })

            }

        } catch (err) {
            console.error(err)
        }

    }
    const approvePlanShow = (id) => {
        setId(id)
        setShowApproveData(true)
    }



    // table 
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };
    const renderHeader = () => {
        return (
            <div className="flex justify-content-end">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
                </span>
            </div>
        );
    };
    const representativeBodyTemplate = (item) => {


        return (
            <div className="flex align-items-center gap-2">
                {item.approve_0 === 0 ? <span>قيد الاعداد</span>
                    :
                    item.approve_1 === 0 ? <span>القابضة</span>
                        :
                        item.approve_1 === 1 && item.approve_2 === 0 ? <span>جهاز تنظيمي</span>
                            : item.approve_2 === 1 && item.approve_3 === 0 ? <span>وزارة الصحة-اللجنة العليا</span>
                                : item.approve_3 === 1 && item.plan_cond === 1 ?
                                    <Button icon="fa-solid fa-stamp" rounded outlined severity="primary" onClick={() => approvePlanShow(item.id)} />
                                    : ""
                }
            </div>
        );
    };


    const actionBodyTemplate = (item) => {


        return (
            <div className="flex align-items-center gap-2">
                <Button severity='secondary' size='small' className='p-1' raised
                    onClick={() => openPlan(item.id, item.system_name, item.approve_0, item.see_1, item.see_2, item.version)} icon="fa-solid fa-folder-open">
                </Button>
                <Button severity='info' size='small' className='p-1 mx-2' raised
                    onClick={() => { setShowTrack(true); setTrack(item); setRun(run + 1) }} icon="fa-solid fa-list-ol">
                </Button>

            </div>
        );
    };

    const header = renderHeader();
    const filterPlans = (cond) => {
        setIngredient(cond)
        switch (cond) {
            case '0':
                setFPlans(plans)
                break;
            case '1':
                setFPlans(plans.filter(item => item.approve_0 === 0))
                break;
            case '2':
                setFPlans(plans.filter(item => item.approve_0 === 1 && item.approve_3 === 0))
                break;
            case '3':
                setFPlans(plans.filter(item => item.approve_3 === 1))
                break;
            default:
                break;
        }
    }
   
    return (
        <div style={{ marginTop: "20px", width: "100%", padding: "10px" }}>
            <div style={{ minHeight: "500px", maxWidth: "1200px", margin: "75px auto 0 auto", padding: "10px" }}>
                <Card title="خطط مأمونية الحمأة">

                    <div className="d-flex flex-wrap gap-3 my-2">
                        <div className={role === 2 || role === 3 ? "d-none" : "d-flex align-items-center"}>
                            <RadioButton inputId="ingredient5" name="all" value="0" onChange={(e) => filterPlans(e.value)} checked={ingredient === '0'} />
                            <label htmlFor="ingredient5" className="mx-2">الكل</label>
                        </div>
                        <div className={role === 2 || role === 3 ? "d-none" : "d-flex align-items-center"}>
                            <RadioButton inputId="ingredient1" name="operating" value="1" onChange={(e) => filterPlans(e.value)} checked={ingredient === '1'} />
                            <label htmlFor="ingredient1" className="mx-2">قيد الاعداد</label>
                        </div>
                        <div className="d-flex align-items-center">
                            <RadioButton inputId="ingredient2" name="reviewing" value="2" onChange={(e) => filterPlans(e.value)} checked={ingredient === '2'} />
                            <label htmlFor="ingredient2" className="mx-2">قيد المراجعة</label>
                        </div>
                        <div className="d-flex align-items-center">
                            <RadioButton inputId="ingredient3" name="health" value="3" onChange={(e) => filterPlans(e.value)} checked={ingredient === '3'} />
                            <label htmlFor="ingredient3" className="mx-2">خطط معتمدة</label>
                        </div>
                    </div>


                    <DataTable stripedRows size='small' value={fPlans} paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]} dataKey="id" loading={loading} filters={filters}
                        globalFilterFields={['plan_name', 'system_name', 'name', 'representative']} header={header}
                        emptyMessage="لا يوجد خطط" scrollable scrollHeight="flex" tableStyle={{ fontSize: "14px" }}
                        expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)} rowExpansionTemplate={rowExpansionTemplate}
                    >
                        <Column expander={allowExpansion} style={{ width: '5rem' }} />
                        <Column field="plan_name" sortable header="كود الخطة" style={{ minWidth: '12rem', textAlign: "right" }} />
                        <Column field="system_name" sortable header="نظام الحمأة " style={{ minWidth: '12rem', textAlign: "right" }} />
                        <Column field="name" sortable header="المستخدم" style={{ minWidth: '12rem', textAlign: "right" }} />

                        <Column field='representative' body={representativeBodyTemplate}
                            header="حالة الخطة"
                            style={{ minWidth: '12rem', textAlign: "right" }} />

                        <Column header="عمليات" style={{ minWidth: '12rem', textAlign: "right" }} body={actionBodyTemplate} />
                    </DataTable>
                </Card>

            </div>
            <ApproveSludgePlanShow show={showApproveData} close={() => setShowApproveData(false)} id={id} token={token} />
            <ModalTrack show={showTrack} kind={'getSludgeById'} close={() => setShowTrack(false)} track={track} run={run} />
        </div>
    )
}
export default SludgePlansFN