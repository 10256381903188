import {useLocation} from "react-router-dom";
import {Oval} from "react-loader-spinner";
import React, {useState} from "react";
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Button } from 'primereact/button';
import { useEffect } from "react";
import axios from 'axios';
import ModalShow from "../../../components/ModalShow";
import { saveAs } from 'file-saver';
import FilesNotesDialog from "../../../components/FilesNotesDialog";
import FoldersDialog from "../../../components/FoldersDialog";
import ApprovePlan from "../ApprovePlan";
import {
    handelDownloadFile,
    handelDownloadFile2,
    handelDownloadFolder,
    handelDownloadPlanFolder
} from "../../../helpers/Helpers";
export default function SludgePlanReview03() {
    const [showDel,setShowDel] = useState(false)
    const [showSend,setShowSend] = useState(false)
    const [showSendBack,setShowSendBack] = useState(false)
    const [idSend,setIdSend] = useState(0)
    const [idDel,setIdDel] = useState(0)
    const [show,setShow] = useState(false)
    const [show2,setShow2] = useState(false)
    const location = useLocation()
    const [idd,setIdd] = useState(0)
    const [data,setData] = useState([])
    const {id,name,token} = location.state
    const [loading,setLoading] = useState(false)
    const [files,setFiles] = useState([])
    const [run,setRun]  =useState(0)
    const [canApprove , setCanApprove] = useState(false)
    const [approvedPlan ,setApprovedPlan] = useState(false)
    const [showApprove,setShowApprove] = useState(false)
    const [showCancelApprove,setShowCancelApprove] = useState(false)
    const [canCancel,setCanCancel] = useState(true)
    const [uploadKind,setUploadKind] = useState(3)
    const [folderData , setFolderData] = useState("")

    const setFData = (idd) => {
        setIdd(idd)
        setUploadKind(3)
        setShow2(true)
    }
    const setFData1 = (fname,idd) => {
        setIdd(idd)
        setUploadKind(0)
        setFolderData(id + "/" + fname)
        setShow2(true)
    }
    const openNotesDialog = (id) => {
        setIdd(id)
        setShow(true)
    }

    const openModal = (id) => {
        setIdDel(id)
        setShowDel(true)

    }
    const openModalSend = (id) => {
        setIdSend(id)
        setShowSend(true)

    }
    const openModalSendBack = (id) => {
        setIdSend(id)
        setShowSendBack(true)

    }
    useEffect(() => {
        const showFiles = async () => {
            try {
                setLoading(true)

                let res = await axios.get(`/sludgeFolderFiles/show01/${id}`, {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + token,
                    }
                })
                if ( res.status === 200) {
                    setFiles(res.data)
                }

            } catch (err) {
                console.error(err)
            }
            finally {
                setLoading(false)
            }
        }

        const showData = async () => {
            let res = await axios.get(`/sludgeFolders/showbyid/${id}`, {
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + token,
                }
            })
            let approveOk = true;
            if ( res.status === 200) {
                res.data.map(item => {
                    if ( item.approve_2 === 1 ) {
                        setApprovedPlan(true)
                        if ( item.approve_3 === 1 ) {
                            setCanCancel(false)
                            approveOk = false;
                        }else {
                            setCanCancel(true)
                        }
                    }else {
                        setCanCancel(false)
                        approveOk = false;
                    }
                })
                setCanApprove(approveOk);
                if ( res.data.length > 0 ) {
                    setData(res.data.filter((item) => item.send_lv3 === 1))
                }else {

                }
            }
        }
        showData()
        showFiles()

    },[run])

    function handleRun ()
    {
        setRun((prev) => prev + 1)
    }




    function handleRun ()
    {
        setRun((prev) => prev + 1)
    }

    const handleBackSendFolder = async (id) => {
        try {

            let res =await axios.post(`/sludgeFolders/backSend03/${id}`,null,{
                headers : {
                    Accept : "application/json",
                    Authorization : "Bearer " + token,
                }})
            if ( res.status === 200 ) {
                setShowSendBack(false)
                handleRun()
            }
        }catch(err) {
            console.error(err)
        }
    }


    const approvePlan = () => {
        try {
            let result;
            // eslint-disable-next-line no-restricted-globals
            result = confirm("هل انت متأكد من انك تريد اعتماد الخطة")
            if ( result ) {
                setShowApprove(true)
            }
        }catch(err) {console.error(err)}
    }
    const cancelApprovePlan = async () => {
        try {
            let result;
            // eslint-disable-next-line no-restricted-globals
            result = confirm("هل انت متأكد من انك تريد ارجاع الخطة")
            if ( result ) {
                setShowCancelApprove(true)
            }
        }catch(err) {console.error(err)}
    }
    return (
        <>

            <div style={{ marginTop:"40px",width:"100%",padding:"10px"}}>
                {loading && <Oval
                    height={80}
                    width={80}
                    color="blue"
                    wrapperStyle={{}}
                    wrapperClass="spinner_container"
                    visible={true}
                    ariaLabel='oval-loading'
                    secondaryColor="gray"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                />}
                <div style={{minHeight:"500px",maxWidth:"1000px" , margin:"75px auto 0 auto",padding:"10px"}}>
                    <div className="text-center mt-4" data-aos="fade-up">
                        <h2 className="fs-sm-6">خطة سلامة ومأمونية المياه لنظام امداد   {name}</h2>
                    </div>
                    <div className="mt-5">
                        <Accordion activeIndex={0}>
                            {data.map((item,index) => (
                                <AccordionTab
                                    key={index} header={
                                    <div className="flex align-items-center">

                                        <i className="fa-solid fa-folder-open mx-2 text-warning"></i>
                                        <span className="vertical-align-middle">{item.name}</span>
                                    </div>
                                }
                                >
                                    <div>
                                        {
                                            files.filter((f) => f.sludge_folders_id === item.id).map((fil,i) => (
                                                <div className="row p-2 mb-2 border-bottom rounded"  key={i}>
                                                    <div className="col-sm-5 d-flex">

                                                        <div className="mx-2 mb-2 mb-sm-0">
                                                            {
                                                                (fil.file_name.toLowerCase()).includes("jpg") || (fil.file_name.toLowerCase()).includes("jpeg")  || (fil.file_name.toLowerCase()).includes("png") || (fil.file_name.toLowerCase()).includes("jif") ?
                                                                    <i className="fa-solid fa-image text-info"></i>
                                                                    :
                                                                    (fil.file_name.toLowerCase()).includes("pdf") ? <i className="fa-solid fa-file-pdf text-danger"></i>
                                                                        :
                                                                        (fil.file_name.toLowerCase()).includes("doc") ? <i className="fa-solid fa-file-word text-primary"></i> :
                                                                            (fil.file_name.toLowerCase()).includes("xls") ? <i className="fa-solid fa-table text-success"></i> :
                                                                                <i className="fa-solid fa-file text-default"></i>
                                                            }
                                                        </div>
                                                        {fil.file_name}

                                                    </div>
                                                    <div className="col-sm-4 d-flex mb-3 mb-sm-0 justify-content-center">
                                                        {fil.fname02 &&
                                                            <div className="bg-info rounded-1 text-white p-1" style={{maxHeight:"40px"}}>
                                                                مرفق :
                                                                <i
                                                                    onClick={() => handelDownloadFile2(fil.fname,fil.path,token,setLoading,`/sludgeFolderFilesNotes_02/download01`)}
                                                                    className="fa-solid fa-download text-default my-2 mx-2"
                                                                    style={{cursor: "pointer",fontSize:"10px"}}></i>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="col-sm-3 d-flex justify-content-between align-items-center">
                                                        {canApprove &&
                                                            <>
                                                                <i
                                                                    onClick={() => openNotesDialog(fil.id)}
                                                                    className="fa-solid fa-comment-dots text-warning disabled"
                                                                    style={{cursor: "pointer"}}></i>
                                                                <i onClick={() => setFData(fil.id)}
                                                                   className="fa-solid fa-upload text-primary"
                                                                   style={{cursor: "pointer"}}></i>

                                                            </>
                                                        }
                                                        <i onClick={() => handelDownloadFile(fil.id,fil.file_name,token,'sludge')}
                                                           className="fa-solid fa-download text-default" style={{cursor:"pointer" }}></i>
                                                    </div>
                                                </div>

                                            ))
                                        }
                                    </div>

                                    <div className="d-flex">
                                    {item.can_add_4 === 1 ?
                                            <Button
                                            style={{ fontSize:"10px",padding:"8px"}}
                                            disabled = {item.send_lv4 === 0 ?false :true }
                                            label="رفع ملف " icon="fa-solid fa-upload" onClick={() => setFData1(item.name,item.id)}  size="small"/>
                                            :
                                            item.can_add_2 === 0 && item.can_add_3 === 0 && item.can_add_4 === 0 &&
                                            <Button
                                            style={{ fontSize:"10px",padding:"8px"}}
                                            disabled = {item.send_lv4 === 0 ?false :true }
                                            label="رفع ملف " icon="fa-solid fa-upload" onClick={() => setFData1(item.name,item.id)}  size="small"/>
                                        }
                                        <Button
                                            disabled={!canApprove}
                                            style={{ fontSize:"10px",padding:"8px"}}
                                            label={"اعادة"} icon="fa-solid fa-envelope" severity="danger" size="small" className="mx-2" onClick={() => openModalSendBack(item.id)}/>
                                        <Button label={" تحميل الفولدر "}
                                                onClick={() => handelDownloadPlanFolder(item.name,id,token,setLoading)}
                                                severity="secondary" size="small" icon="fa-solid fa-download" style={{ fontSize:"10px",padding:"8px"}}/>
                                    </div>
                                </AccordionTab>
                            ))}
                        </Accordion>
                        <Button label={"تحميل الكل"}
                                onClick={() => handelDownloadFolder(name,id,token,setLoading)}
                                severity="success" size="small" icon="fa-solid fa-download" style={{ fontSize:"12px",padding:"10px",marginRight:"5px"}}/>
                        <Button label={"اعادة الخطة"}
                                disabled = {!canCancel }
                                onClick={() => cancelApprovePlan(id)}
                                severity="danger" size="small" icon="fa-solid fa-thumbs-down"  style={{ fontSize:"12px",padding:"10px",marginRight:"5px"}}/>
                        <Button label={"اعتماد الخطة"}
                                disabled = {!canApprove}
                                onClick={() => approvePlan(id)}
                                severity="secondary" size="small" icon="fa-solid fa-thumbs-up"  style={{ fontSize:"12px",padding:"10px",marginRight:"5px"}}/>
                    </div>
                </div>
            </div>
            {/*<FoldersDialog run={handleRun} show={show} close={() => setShow(false)} id={id} idd={idd} folderData={folderData} token={token}></FoldersDialog>*/}
            <FoldersDialog kind={"sludge"} level={uploadKind} id={id} run={handleRun} show={show2} close={() => setShow2(false)} idd={idd} token={token}></FoldersDialog>
            <FilesNotesDialog kind={"sludge"} level={"02"} run={handleRun} show={show} close={() => setShow(false)} id={idd} token={token}/>
            <ModalShow show={showSendBack} operationName={"اعادة فولدر"}
                       operationText={"اعادة الفولدر"} close={() => setShowSendBack(false)}
                       action={handleBackSendFolder}
                       id={idSend}
            />
            <ApprovePlan kind0={"sludge"} run={() => setRun(true)} token={token} show={showApprove} close={() => setShowApprove(false)}  id={id} code={3} kind={"approve"}/>
            <ApprovePlan kind0={"sludge"} run={() => setRun(true)} token={token} show={showCancelApprove} close={() => setShowCancelApprove(false)}  id={id} code={3} kind={"cancelApprove"}/>
        </>
    )
}