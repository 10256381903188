import { Card } from 'primereact/card';
import {useContext, useEffect, useState} from "react";
import axios from "axios";
import {Field, Formik, Form} from "formik";
import { Button } from 'primereact/button';
import {useLocation, useNavigate} from "react-router-dom";
import {User} from "../../Context";

export default function AddSewageFolders() {
    const [folderArray,setFolderArray] = useState([]);
    const [folderValus,setFolderValues] = useState({});
    const nav = useNavigate()
    const location = useLocation()
    const {data} = location.state
    const user = useContext(User)
    const token = user.auth.token
    useEffect(() => {
        resetFoldersArray()
    },[])
    const addFolder = () => {
        setFolderArray(prev => [...prev,"folder_" + (folderArray.length +1)]);
        let values = folderValus;
        values[`folder_${(folderArray.length +1)}`]  = "فولدر"
        values[`evaluate_${(folderArray.length +1)}`]  = 0
        values[`canAdd_${(folderArray.length +1)}`]  = []
        values[`about_${(folderArray.length +1)}`]  = 'وصف الفولدر'
        setFolderValues(values)
    }
    const deleteFolder = (theFolder) => {
        const newArray = folderArray.filter((item) => item !== theFolder);
        setFolderArray(newArray);

        if ( folderArray.length > 0 ) {
            const number = theFolder.split("_")[1];
            let newValues = {...folderValus}
            delete newValues[theFolder]
            delete newValues[`evaluate_${number}`]
            delete newValues[`canAdd_${number}`]
            delete newValues[`about_${number}`]
            setFolderValues(newValues)
        }

    }
    const resetFoldersArray = () => {
        setFolderArray( ["folder_1","folder_2","folder_3","folder_4"
            ,"folder_5","folder_6","folder_7","folder_8","folder_9","folder_10","folder_11","folder_12","folder_13"])
        let values = folderValus;
        values[`folder_1`]  = "خطابات الإعتماد"
        values[`evaluate_1`]  = 0
        values[`canAdd_1`]  = []
        values[`about_1`]  = "وصف الفولدر"


        values[`folder_2`]  = "خطابات وملاحظات القابضة"
        values[`evaluate_2`]  = 0
        values[`canAdd_2`]  = []
        values[`about_2`]  = "وصف الفولدر"

        values[`folder_3`]  = "خطابات وملاحظات الجهاز"
        values[`evaluate_3`]  = 0
        values[`canAdd_3`]  = []
        values[`about_3`]  = "وصف الفولدر"

        values[`folder_4`]  = "خطابات وملاحظات الصحة/اللجنة العليا"
        values[`evaluate_4`]  = 0
        values[`canAdd_4`]  = []
        values[`about_4`]  = "وصف الفولدر"

        values[`folder_5`]  = "المخاطبات"
        values[`evaluate_5`]  = 0
        values[`canAdd_5`]  = []
        values[`about_5`]  = "وصف الفولدر"

        values[`folder_6`]  = "المتطلبات الإدارية"
        values[`evaluate_6`]  = 0
        values[`canAdd_6`]  = []
        values[`about_6`]  = "وصف الفولدر"

        values[`folder_7`]  = "المقدمة"
        values[`evaluate_7`]  = 0
        values[`canAdd_7`]  = []
        values[`about_7`]  = "وصف الفولدر"

        values[`folder_8`]  = "شبكة التجميع والروافع"
        values[`evaluate_8`]  = 0
        values[`canAdd_8`]  = []
        values[`about_8`]  = "وصف الفولدر"

        values[`folder_9`]  = "محطة المعالجة "
        values[`evaluate_9`]  = 0
        values[`canAdd_9`]  = []
        values[`about_9`]  = "وصف الفولدر"

        values[`folder_10`]  = "صرف السيب النهائي وإعادة الاستخدام "
        values[`evaluate_10`]  = 0
        values[`canAdd_10`]  = []
        values[`about_10`]  = "وصف الفولدر"

        values[`folder_11`]  = "الحمأة"
        values[`evaluate_11`]  = 0
        values[`canAdd_11`]  = []
        values[`about_11`]  = "وصف الفولدر"

        values[`folder_12`]  = "المرفقات"
        values[`evaluate_12`]  = 0
        values[`canAdd_12`]  = []
        values[`about_12`]  = "وصف الفولدر"

        values[`folder_13`]  = "اللجنة العليا"
        values[`evaluate_13`]  = 0
        values[`canAdd_13`]  = []
        values[`about_13`]  = "وصف الفولدر"
        setFolderValues(values)
    }

    return (
        <Card title="اضافة ملفات خطة نظام امداد مياه" style={{ maxWidth: '700px',direction:"rtl",margin:"30px auto" }}>
            <Button type={"button"} icon="fa-solid fa-arrows-rotate" rounded text severity="secondary" aria-label="Bookmark" onClick={resetFoldersArray}/>
            <Button type={"button"} icon="fa-solid fa-plus" rounded text severity="secondary" aria-label="Add" onClick={addFolder}/>
            <Formik enableReinitialize={true} initialValues={folderValus
            } onSubmit={async values => {
                try {
                    let res = await axios.post(`/sewageFolders/create/${data}`,
                        {values},
                        {
                            headers : {
                                Accept : "application/json",
                                Authorization : "Bearer " + token,
                            }})
                    if ( res.status === 200 ) {
                        nav('/controlPanel/sewagePlans')
                    }
                }catch(err) {
                    console.log(err)
                }
            }}>
                <Form>
                    {folderArray.map((item,index) => (
                            <div key={index} className="p-2 border-bottom" style={{marginBottom:"10px"}}>
                                <div className="d-flex flex-column">
                                    <div className={"row"}>
                                        <div className="col-md-4 mt-3 mt-sm-0">
                                            <Field as="input" id={item} name={item} placeholder="ادخل الاسم" className="form-control"/>
                                        </div>
                                        <div className="col-md-3 my-2 my-md-0">
                                            <Field name={"evaluate_" + (index+1)} as="select" className="form-control">
                                                <option value="0" selected>تقييم</option>
                                                <option value="1">عدم تقييم</option>
                                            </Field>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className='d-flex mt-3 mt-sm-0'>

                                                <label>
                                                    <Field type="checkbox" value={"can_add_2"} name={"canAdd_" + (index+1)} className='mx-1'/>
                                                    القابضة
                                                </label>
                                                <label>
                                                    <Field type="checkbox" value={"can_add_3"} name={"canAdd_" + (index+1)}  className='mx-1'/>
                                                    الجهاز
                                                </label>
                                                <label>
                                                    <Field type="checkbox" value={"can_add_4"} name={"canAdd_" + (index+1)} className='mx-1'/>
                                                    الصحة
                                                </label>
                                            </div>
                                        </div>

                                        <div className="col-md-1">

                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-9">
                                            <Field as="input" name={"about_" + (index+1)} placeholder="وصف الفولدر" className="form-control my-2"/>
                                        </div>
                                        <div className="col-md-1">
                                            {index > 0 &&

                                                <Button type={"button"} icon="fa-solid fa-times" rounded text severity="danger" aria-label="Cancel" onClick={() =>deleteFolder(item)}/>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    )}
                    <Button type={"submit"}>حفظ الفولدرات</Button>
                </Form>
            </Formik>
        </Card>
    )
}