import { useContext,useState } from "react"
import {Card} from "primereact/card";
import {Oval} from "react-loader-spinner";
import { Timeline } from 'primereact/timeline';
import {User} from "../Context";
import { useLocation, useNavigate} from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
export default function FollowPlan() {
    const [logs,setLogs] = useState([]);
    const user = useContext(User)
    const token = user.auth.token
    const nav= useNavigate()
    const location = useLocation()
    const {data} = location.state
    const [loading,setLoading] = useState(false)
    const events = [data.plan_start, "الشركة القابضة", 'الجهاز التنظيمي', 'وزارة الصحة',"اللجنة العليا","اعتماد"];
    const customizedMarker = (item) => {
        return (
            <>
            <span className="flex w-2rem h-2rem align-items-right justify-content-center 
            text-white border-circle z-1 shadow-1">
                <i className={item.icon} style={{ color: item.color }}></i>
            </span>
            
            </>
        );
    };
    const customizedContent = (item) => {
        return (
            <Card title={<><span> {item.status}</span><br/><span style={{fontSize:"14px"}}> {item.desc} </span></>} subTitle={item.date} className="border">
            
                <span>المستخدم : </span> {item.user}
                
            </Card>
        );
    };
    useEffect(() => {
        async function getData() {
            try {
            
                // setDateTimeFrom(new Date())
                //setDateTimeTo(new Date())
                setLoading(true)
                let res = await axios.get(`/planLog/getWaterById/${data.id}`, {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + token,
                    }
                })
               
                if ( res.status === 200 ) {
                    
                  //  setLogs(res.data)
                //   console.log(res.data)
                  const events = [];
                    res.data.map((item) => {
                        events.push({status: item.action,date:item.created_at, icon: 'fa-solid fa-circle-dot',color: '#9C27B0',user : item.name,desc:item.action_desc})
                    })
                    setLogs(events)
                }
            } catch (err) {
                console.error(err)
            }finally{
                setLoading(false)
            }
        }
        getData()
    },[])
    return (
        <div className={"main"}>
            {loading && <Oval
                height={80}
                width={80}
                color="blue"
                wrapperStyle={{}}
                wrapperClass="spinner_container"
                visible={loading}
                ariaLabel='oval-loading'
                secondaryColor="gray"
                strokeWidth={2}
                strokeWidthSecondary={2}
            />}
            <Card title={"متابعة خطة"} subTitle={data.plan_name}>
            <div className="my-5">
                <Timeline value={events} layout="horizontal" align="top" content={(item) => item} style={{fontSize:"14px !important"}}/>
            </div>
            <Timeline style={{maxWidth:"700px"}} align="alternate" className="customized-timeline" content={customizedContent} marker={customizedMarker} value={logs} 
             />
            </Card>
        </div>
    )
}