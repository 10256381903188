import { Outlet} from "react-router-dom";
import {useContext} from "react";
import {User} from "./Context";
import Cookies from "universal-cookie";
export default function RequireBack() {
    const cookie = new Cookies();
    const token = cookie.get('Bearer')
    const user = useContext(User)
    const tokenUser = user.auth.token;
    return (
        tokenUser && token ? window.history.back() : <Outlet />
    )
}