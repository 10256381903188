
export default function AboutUs() {
    return (<>
        {/*======= About Us Section ======= */}
        <section id="about" className="about">
            <div className="container">

                <div className="section-title pb-4" data-aos="fade-up">
                    <h2>من نحن</h2>
                </div>

                <div className="row content">
                    <div className="col-lg-12" data-aos="fade-up" data-aos-delay="150">
                        <p className="lh-lg" style={{textAlign:"justify",textJustify:"inter-word"}}>
                            نحن مجموعة متفردة من المختصين القادرين على تطبيق أحدث المبادئ التوجيهية وأفضل الممارسات من منظمة الصحة العالمية لتطوير خطط كاملة لتقييم المخاطر والتخفيف منها، باستخدام أدوات مختلفة لأنظمة الإمداد والمعالجة جنبًا إلى جنب مع الفاعلين المشاركين في مراجعة الخطط من المختصين بوزارة الصحة ووزارة الري والجهاز التنظيمي لمياه الشرب وحماية المستهلك، يعملون معًا كما أنهم مزودين بالأدوات اللازمة لتطوير خططهم الخاصة واستراتيجيات القياس المختلفة لفاعلية الإجراءات التصحيحية. وتجدر الإشارة الى أن كثيرًا منهم حاصلين على درجة الماجستير والدكتوراة في مجالات مأمونية المياه والصرف، وتم تدربيهم داخل وخارج مصر لثَقْلِ قدراتهم التي يمكن أن نستشف نتائجها من مقدار التطور الكبير الذي ظهر على الساحة في مأمونية المياه والصرف.
                        </p>

                    </div>

                </div>

            </div>
        </section>{/*<!-- End About Us Section -->*/}
    </>)
}