import { useRef } from "react";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import en_raslan from '../../assets/frontend/testimonials/en_raslan.jpg'
import dr_salah from '../../assets/frontend/testimonials/dr_salah.jpeg'
export default function HomePageFeatures() {
    gsap.registerPlugin(ScrollTrigger)
    let mm = gsap.matchMedia();
    useGSAP(() => {
        gsap.fromTo('.library', { opacity: 0,scale:.5 }, {
            opacity: 100,
            scale:1,
            duration: 1,
            scrollTrigger: {
                trigger: ".library",
                start: "top 60%",
                end: "bottom 15%",
                scrub: false,
                toggleActions: "play reverse restart reverse",
                //markers: true,
            }
        })
        
        gsap.fromTo('.testimonials_card', { opacity: 0,scale:.5 }, {
            opacity: 100,
            scale:1,
            duration: 1,
            scrollTrigger: {
                trigger: ".testimonials_card",
                start: "top 60%",
                end: "bottom 15%",
                scrub: false,
                toggleActions: "play reverse restart reverse",
              //  markers: true,
            }
        })
        // gsap.fromTo('.box2', { opacity: 0, y: -100 }, {
        //     opacity: 100,
        //     y: 0,
        //     duration: 1,
        //     scrollTrigger: {
        //         trigger: ".box1",
        //         start: "top 80%",
        //         end: "bottom 70%",
        //         // scrub: true,
        //         //markers: true,
        //         toggleActions: "play reverse restart reverse",
        //     }
        // })
        // gsap.fromTo('.box3', { opacity: 0, y: 100 }, {
        //     opacity: 100,
        //     y: 0,
        //     duration: 1,
        //     scrollTrigger: {
        //         trigger: ".box1",
        //         start: "top 80%",
        //         end: "bottom 70%",
        //         //  scrub: true,
        //         //  markers: true,
        //         toggleActions: "play reverse restart reverse",
        //     }
        // })
        // mm.add("(max-width:500px)", () => {
        //     gsap.fromTo('.box1',{opacity:0,y:-100},{
        //         opacity : 100,   
        //         y : 0,
        //         duration: 1,


        //         scrollTrigger: {
        //             trigger: ".box1",
        //             start: "top 60%",
        //             end :"bottom 30%",
        //          //   scrub: true,
        //          //  markers: true,
        //            toggleActions:"play reverse restart reverse",
        //         }
        //     })
        //     gsap.fromTo('.box2',{opacity:0,y:100},{
        //         opacity : 100,   
        //         y : 0,
        //         duration: 1,

        //         scrollTrigger: {
        //             trigger: ".box1",
        //             start: "top 60%",
        //             end :"bottom 30%",
        //            // scrub: true,
        //          //  markers: true,
        //            toggleActions:"play reverse restart reverse",
        //         }
        //     })
        //     gsap.fromTo('.box3',{opacity:0,y:100},{
        //         opacity : 100,   
        //         y : 0,
        //         duration: 1,

        //         scrollTrigger: {
        //             trigger: ".box1",
        //             start: "top 60%",
        //             end :"bottom 30%",
        //           //  scrub: true,
        //          //  markers: true,
        //            toggleActions:"play reverse restart reverse",
        //         }
        //     })
        // })
    })
    return (
        <div >

            <div className="d-flex flex-column justify-content-center ">
                <h3 className="text-center fw-bold fs-1 library" style={{ color: "#3468C0" }}>الداعمين للمنصة</h3>
                <div className="row mx-auto" style={{ marginTop: "30px" }}>

                    <div className="col-sm-6 col-12">
                        <div className="testimonials_card my-2">
                            <div className="card shadow p-4"  >
                                <img className="card-img-top border rounded border-default" src={en_raslan} style={{ maxHeight: "250px" }} />
                                <div className="card-body">
                                    <div className="member-info text-center">
                                        <h6>السيد المهندس</h6>
                                        <h2 >ممدوح رسلان</h2>
                                        <p>
                                            <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                                            رئيس مجلس ادارة الشركة القابضة لمياه الشرب والصرف الصحى
                                            <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-12">
                    <div className="testimonials_card my-2">
                            <div className="card shadow p-4"  >
                                <img className="card-img-top border rounded border-default" src={dr_salah} style={{ maxHeight: "250px" }} />
                                <div className="card-body">
                                    <div className="member-info text-center">
                                    <h6 >الاستاذ الدكتور المهندس</h6>
                                        <h2>صلاح بيومي</h2>
                                        <p>
                                            <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                                            نائب رئيس مجلس ادارة الشركة القابضة لمياه الشرب والصرف الصحى
                                            <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    )
}