import React, { useState} from 'react';
import Alert from 'react-bootstrap/Alert';
export default function Msg(props) {
    const [show,setShow] = useState(props.show)
    // 'primary',
    //     'secondary',
    //     'success',
    //     'danger',
    //     'warning',
    //     'info',
    //     'light',
    //     'dark',
if (show) {
return (
    <div className={"m-3"}>
        <Alert variant={props.kind} onClose={() => setShow(false)} dismissible>
        <p>
            {props.message}
        </p>
        </Alert>
    </div>
    );
}


}