import {useEffect, useState} from "react";
import axios from "axios";

export default function CompaniesOptionsN(props) {
    const [companies,setCompanies] = useState([]);
    useEffect(  () => {
        async function fetchData () {
            try {

                await axios.get("/companies/show",{
                    headers : {
                        Accept : "application/json",
                        Authorization : "Bearer " + props.token,
                    }
                }).then((data) => setCompanies(data.data))

            }catch(err) {
                console.error(err)
            }
        }
        fetchData()
    },[])
    return (
        <>
        {props.id ? 
             companies.map((item,index) =>
                         <option key={index} value={item.id} selected={props.id === item.id ? true : false}>{item.company}</option>
                 )
    
    :

        companies.map((item,index) => (
            index === 0  ?
                <>
                    <option key={1000} selected disabled>اختر شركة</option>
                    <option key={index} value={item.id}>{item.company}</option>
                </>
                :
                <option key={index} value={item.id}>{item.company}</option>

        ))
    }
           
        </>
    )
}