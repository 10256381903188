import { useContext, useEffect, useState } from "react"
import { User } from "./Context"
import {Outlet} from "react-router-dom";
import axios from "axios";
import {Oval} from "react-loader-spinner";
import Cookies from "universal-cookie";

export default function PersistLogin () {
    const user = useContext(User)
    const [loading,setLoading] = useState(true)
    const token = user.auth.token;
    const cookie = new Cookies();

    const getToken = cookie.get('Bearer')
    axios.defaults.baseURL = "https://wssp.hcww.com.eg/api";
    
    useEffect(() => {
        
    async function refresh () {
      
           try {
               await axios.post('/refresh',null,{
                   headers : {
                       Authorization : "Bearer " + getToken
                   },
               })
                   .then((data) =>
                   {
                    
                    document.cookie = "Bearer=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                    // document.cookie = "Bearer=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/controlPanel;";
                       cookie.set('Bearer',data.data.token,{path:"/",maxAge:3600});
                       user.setAuth((prev) => {return {user : data.data.user,token:data.data.token}})
                   })
           }catch(err){
               console.log(err)
           }finally {
               setLoading(false)
           }
       }
       token === undefined ? refresh() : setLoading(false)
},[])
    return (!loading ? <Outlet /> :  <Oval
        height={80}
        width={80}
        color="blue"
        wrapperStyle={{}}
        wrapperClass="spinner_container"
        visible={true}
        ariaLabel='oval-loading'
        secondaryColor="gray"
        strokeWidth={2}
        strokeWidthSecondary={2}

    />)
}