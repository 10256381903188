import {Table} from "react-bootstrap";

export default function SystemsTableExpand(props) {
    return (
        <div className="p-3 d-flex align-items-start">
            <Table  bordered hover style={{width:"100%",margin:"0 auto",textAlign:"right"}}>

                <tbody>
                <tr>
                    {props.kind ==='ًصرف' ?
                   <>
                       <td className={"bg-light"}>نقطة الصرف</td>
                       <td>{props.data.water_source}</td>
                       <td className={"bg-light"}> المحطات القريبة التي تقع علي نفس نقطة المصرف</td>
                       <td>{props.data.near_stations}</td>
                   </>
                        :
                        <>
                            <td className={"bg-light"}>مصدر المياه</td>
                            <td>{props.data.water_source}</td>
                            <td className={"bg-light"}>  المحطات القريبة التي تقع علي نفس المصدر</td>
                            <td>{props.data.near_stations}</td>
                        </>
                }

                    <td className={"bg-light"}> موقف الاستلام </td>
                    <td>{props.data.receiving_position}</td>
                </tr>
                <tr>
                    <td className={"bg-light"}> تاريخ الانشاء</td>
                    <td>{props.data.date_created}</td>
                    <td className={"bg-light"}>  مقاول الانشاء</td>
                    <td>{props.data.construction_contractor}</td>
                    <td className={"bg-light"}> طاقة المحطة التصميمية م3/يوم </td>
                    <td>{props.data.design_power}</td>
                </tr>
                <tr>
                    <td className={"bg-light"}>  عدد العمالة بالمحطة</td>
                    <td>{props.data.emp_count}</td>
                    <td className={"bg-light"}>   مساحة المحطة</td>
                    <td>{props.data.station_size}</td>
                    <td className={"bg-light"}>  مكونات المحطة الرئيسية </td>
                    <td>{props.data.station_components}</td>
                </tr>
                <tr>
                    <td className={"bg-light"}>   وجود مصدر بديل للطاقة</td>
                    <td>{props.data.power_alternative_source}</td>
                    <td className={"bg-light"}>  عدد السكان المخدومين</td>
                    <td>{props.data.serviced_population}</td>
                    <td className={"bg-light"}> اطوال الشبكات </td>
                    <td>{props.data.net_tall}</td>
                </tr>
                </tbody>
            </Table>

        </div>
    )
}