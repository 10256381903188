import { Dialog } from 'primereact/dialog';
import {Field, Formik, Form} from "formik";
import { Button } from 'primereact/button';
export default function FoldersDialog(props) {
    return (
        <>
        <Dialog header="اضافة فولدر" visible={props.show} style={{ maxWidth: '700px',direction:"rtl" }} onHide={props.close}>
            <Formik  initialValues={{folder_1 : "",evaluate_1 : 0,canAdd_1 : [],about_1 :'وصف الفولدر'} }
            onSubmit={ values => {
                props.addNewFolder(values)
                props.close();
            }}>
                <Form>
                    <div className="row p-2 border-bottom" style={{marginBottom:"10px"}}>
                       <div className="d-flex flex-column">
                           <div className="row">
                               <div className="col-md-4 mt-3 mt-sm-0">
                                   <Field as="input" name="folder_1" placeholder="ادخل الاسم" className="form-control"/>
                               </div>
                               <div className="col-md-3 mt-3 mt-sm-0">
                                   <Field name="evaluate_1" as="select" className="form-control" >
                                       <option value="0">تقييم</option>
                                       <option value="1">عدم تقييم</option>
                                   </Field>
                               </div>
                               <div className='col-md-5 mt-3 mt-sm-0'>
                                   <div className='d-flex'>

                                       <label>
                                           <Field type="checkbox" value={"can_add_2"} name={"canAdd_1"} className='mx-1'/>
                                           القابضة
                                       </label>
                                       <label>
                                           <Field type="checkbox" value={"can_add_3"} name={"canAdd_1"}  className='mx-1'/>
                                           الجهاز
                                       </label>
                                       <label>
                                           <Field type="checkbox" value={"can_add_4"} name={"canAdd_1"} className='mx-1'/>
                                           الصحة
                                       </label>
                                   </div>
                               </div>
                           </div>
                           <div>
                               <Field as="input" name={"about_1"} placeholder="وصف الفولدر" className="form-control my-2"/>
                           </div>
                       </div>
                    </div>
                    <Button type={"submit"}>حفظ الفولدر</Button>
                </Form>
            </Formik>
        </Dialog>
        </>
    )
}