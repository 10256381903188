import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import {useState} from "react";
import {InputText} from "primereact/inputtext";
export default function AddLibraryFolder(props) {
    const [folderName,setFolderName] = useState("")
    const handleAction = (e) => {
        e.preventDefault();
        props.action(folderName)
    }
    return (
        <>
        <Dialog header="اضافة فولدر" visible={props.show} style={{ maxWidth: '700px',direction:"rtl" }} onHide={props.close}>
            <form onSubmit={handleAction}>
                <div className="d-flex flex-column gap-2">
                    <label htmlFor="folerName">اسم الفولدر</label>
                    <InputText id="folerName" value={folderName} onChange={(e) => setFolderName(e.target.value)} required/>
                    <Button label="اضافة الفولدر" size={"small"} type={"submit"}/>
                </div>

            </form>
        </Dialog>
        </>
    )
}