import Dashboard from "./dashboard/Dashboard";
import { Route, Routes } from "react-router-dom";
import Users from "./dashboard/auth/Users";
import Main from "./dashboard/Main";
import AddUser from "./dashboard/auth/AddUser";
import EditUser from "./dashboard/auth/EditUser";
import Login from "./dashboard/auth/Login";
import PersistLogin from "./PersistLogin";
import Companies from "./dashboard/companies/Companies";
import AddCompany from "./dashboard/companies/AddCompany";
import UpdateCompany from "./dashboard/companies/UpdateCompany";
import WaterSupply from "./dashboard/waterSupply/WaterSupply";
import AddWaterSupply from "./dashboard/waterSupply/AddWaterSupply";
import EditWaterSupply from "./dashboard/waterSupply/EditWaterSupply";
import TreatmentData from "./dashboard/treatmentData/TreatmentData";
import AddTreatment from "./dashboard/treatmentData/AddTreatment";
import EditTreatment from "./dashboard/treatmentData/EditTreatment";
import RequireAuth from "./RequireAuth";
import MainPage from "./frontend/MainPage";
import ERR403 from "./dashboard/auth/403";
import Error404 from "./dashboard/auth/404";
import RequireBack from "./RequireBack";
import Plans from "./dashboard/waterPlans/Plans";
import AddPlan from "./dashboard/waterPlans/AddPlan";
import EditPlan from "./dashboard/waterPlans/EditPlan";
import SewagePlans from "./dashboard/sewagePlans/SewagePlans";
import AddSewagePlan from "./dashboard/sewagePlans/AddSewagePlan";
import EditSewagePlan from "./dashboard/sewagePlans/EditSewagePlan";
import EditSewageFolders from "./dashboard/sewagePlans/EditSewageFolders";
import AddSewageFolders from "./dashboard/sewagePlans/AddSewageFolders";
import EditWaterFolders from "./dashboard/waterPlans/EditWaterFolders";
import AddWaterFolders from "./dashboard/waterPlans/AddWaterFolders";
import MainContent from './frontend/MainContent';
import WaterPlans from './frontend/plans/water/WaterPlans';
import WaterPlan from "./frontend/plans/water/WaterPlan";
import WaterPlanReview01 from "./frontend/plans/water/WaterPlanReview01";
import WaterPlanReview02 from "./frontend/plans/water/WaterPlanReview02";
import WaterPlanReview03 from "./frontend/plans/water/WaterPlanReview03";
import Notification from "./dashboard/notifications/Notification";
import SewagePlansF from "./frontend/plans/sewage/SewagePlansF";
import SewagePlan from "./frontend/plans/sewage/SewagePlan";
import SewagePlanReview01 from "./frontend/plans/sewage/SewagePlanReview01";
import SewagePlanReview02 from "./frontend/plans/sewage/SewagePlanReview02";
import SewagePlanReview03 from "./frontend/plans/sewage/SewagePlanReview03";
import Sludge from "./dashboard/sludge/Sludge";
import AddSludge from "./dashboard/sludge/AddSludge";
import EditSludge from "./dashboard/sludge/EditSludge";
import SludgePlans from "./dashboard/sludgePlans/SludgePlans";
import AddSludgePlan from "./dashboard/sludgePlans/AddSludgePlan";
import EditSludgePlan from "./dashboard/sludgePlans/EditSludgePlan";
import AddSludgeFolders from "./dashboard/sludgePlans/AddSludgeFolders";
import EditSludgeFolders from "./dashboard/sludgePlans/EditSludgeFolders";
import SludgePlansF from "./frontend/plans/sludge/SludgePlansF";
import SludgePlan from "./frontend/plans/sludge/SludgePlan";
import SludgePlanReview01 from "./frontend/plans/sludge/SludgePlanReview01";
import SludgePlanReview02 from "./frontend/plans/sludge/SludgePlanReview02";
import SludgePlanReview03 from "./frontend/plans/sludge/SludgePlanReview03";
import LibraryF from "./frontend/library/LibraryF";
import MainPageGuest from "./frontend/MainPageGuest";
import MainContentGuest from "./frontend/MainContentGuest";
import Library from "./dashboard/library/Library";
import LibraryCategory from "./dashboard/library/LibraryCategory";
import FollowPlan from "./components/FollowPlan";
import ChangePassword from "./dashboard/auth/ChangePassword";
import PublicDownloads from "./frontend/publicDownloads/PublicDownloads";
import DashboardN from "./dashboardN/DashboardN";
import DashboardMain from "./dashboardN/DashboardMain";
import UsersN from "./dashboardN/users/UsersN";
import AddUserN from "./dashboardN/users/AddUserN";
import EditUserN from "./dashboardN/users/EditUserN";
import CompaniesN from "./dashboardN/companies/CompaniesN";
import AddCompanyN from "./dashboardN/companies/AddCompanyN";
import UpdateCompanyN from "./dashboardN/companies/UpdateCompanyN";
import WaterSupplyN from "./dashboardN/waterSupply/WaterSupplyN";
import AddWaterSupplyN from "./dashboardN/waterSupply/AddWaterSupplyN";
import EditWaterSupplyN from "./dashboardN/waterSupply/EditWaterSupplyN";
import TreatmentDataN from "./dashboardN/treatmentData/TreatmentDataN";
import AddTreatmentN from "./dashboardN/treatmentData/AddTreatmentN";
import EditTreatmentN from "./dashboardN/treatmentData/EditTreatmentN";
import SludgeN from "./dashboardN/sludge/SludgeN";
import AddSludgeN from "./dashboardN/sludge/AddSludgeN";
import EditSludgeN from "./dashboardN/sludge/EditSludgeN";
import PlansN from "./dashboardN/waterPlans/PlansN";
import AddPlanN from "./dashboardN/waterPlans/AddPlanN";
import EditWaterFoldersN from "./dashboardN/waterPlans/EditWaterFoldersN";
import EditPlanN from "./dashboardN/waterPlans/EditPlanN";
import SewagePlansN from "./dashboardN/sewagePlans/SewagePlansN";
import AddSewagePlanN from "./dashboardN/sewagePlans/AddSewagePlanN";
import EditSewagePlanN from "./dashboardN/sewagePlans/EditSewagePlanN";
import EditSewageFoldersN from "./dashboardN/sewagePlans/EditSewageFoldersN";
import SludgePlansN from "./dashboardN/sludgePlans/SludgePlansN";
import AddSludgePlanN from "./dashboardN/sludgePlans/AddSludgePlanN";
import EditSludgePlanN from "./dashboardN/sludgePlans/EditSludgePlanN";
import EditSludgeFoldersN from "./dashboardN/sludgePlans/EditSludgeFoldersN";
import LibraryN from "./dashboardN/library/LibraryN";
import LibraryCategoryN from "./dashboardN/library/LibraryCategoryN";
import WaterPlansN from "./frontend/plans/water/WaterPlansN";
import SewagePlansFN from "./frontend/plans/sewage/SewagePlansN";
import SludgePlansFN from "./frontend/plans/sludge/SludgePlanFN";
import HomePage from "./frontend/Home/HomePage";
function App() {

  return (
    <>
      <Routes>
        {/* protected routes */}
        <Route element={<PersistLogin />}>

          <Route element={<RequireAuth auth={[0]} />}>
            {/*<Route path="/" element={<Dashboard />}></Route>*/}
            <Route path="/controlPanelN" element={<DashboardN />}>
              <Route path="/controlPanelN" element={<DashboardMain />}></Route>
              <Route path="users" element={<UsersN />}></Route>
              <Route path="addUser" element={<AddUserN />}></Route>
              <Route path="editUser" element={<EditUserN />}></Route>

              <Route path="companies" element={<CompaniesN />}></Route>
              <Route path="addCompany" element={<AddCompanyN />}></Route>
              <Route path="updateCompany" element={<UpdateCompanyN />}></Route>

              <Route path="waterSupply" element={<WaterSupplyN />}></Route>
              <Route path="addWaterSupply" element={<AddWaterSupplyN />}></Route>
              <Route path="editWaterSupply" element={<EditWaterSupplyN />}></Route>

              <Route path="treatmentData" element={<TreatmentDataN />}></Route>
              <Route path="addTreatment" element={<AddTreatmentN />}></Route>
              <Route path="editTreatment" element={<EditTreatmentN />}></Route>

              <Route path="sludge" element={<SludgeN />}></Route>
              <Route path="addSludge" element={<AddSludgeN />}></Route>
              <Route path="editSludge" element={<EditSludgeN />}></Route>

              <Route path="waterPlans" element={<PlansN />}></Route>
             <Route path="addWaterPlans" element={<AddPlanN />}></Route>
             <Route path="editWaterFolders" element={<EditWaterFoldersN />}></Route>
             <Route path="editWaterPlans" element={<EditPlanN />}></Route>
               {/* 
              <Route path="addWaterFolders" element={<AddWaterFolders />}></Route>
               */}


              <Route path="sewagePlans" element={<SewagePlansN />}></Route>
              <Route path="addSewagePlans" element={<AddSewagePlanN />}></Route>
              <Route path="editSewagePlans" element={<EditSewagePlanN />}></Route>
              <Route path="editSewageFolders" element={<EditSewageFoldersN />}></Route>
              {/* 
             
              <Route path="addSewageFolders" element={<AddSewageFolders />}></Route>
               */}

              <Route path="sludgePlans" element={<SludgePlansN />}></Route>
              <Route path="addSludgePlans" element={<AddSludgePlanN />}></Route>
              <Route path="editSludgePlans" element={<EditSludgePlanN />}></Route>
              <Route path="editSludgeFolders" element={<EditSludgeFoldersN />}></Route>
              {/*   */}
              {/* <Route path="addSludgeFolders" element={<AddSludgeFoldersN />}></Route> */}
              {/*
              
              */}

              <Route path="library" element={<LibraryN />}></Route>
              <Route path="libraryCategory" element={<LibraryCategoryN />}></Route>
            </Route>
            <Route path="/controlPanel" element={<Dashboard />}>
              <Route path="/controlPanel" element={<Main />}></Route>
              <Route path="users" element={<Users />}></Route>
              <Route path="addUser" element={<AddUser />}></Route>
              <Route path="editUser" element={<EditUser />}></Route>

              <Route path="companies" element={<Companies />}></Route>
              <Route path="addCompany" element={<AddCompany />}></Route>
              <Route path="updateCompany" element={<UpdateCompany />}></Route>

              <Route path="waterSupply" element={<WaterSupply />}></Route>
              <Route path="addWaterSupply" element={<AddWaterSupply />}></Route>
              <Route path="editWaterSupply" element={<EditWaterSupply />}></Route>

              <Route path="treatmentData" element={<TreatmentData />}></Route>
              <Route path="addTreatment" element={<AddTreatment />}></Route>
              <Route path="editTreatment" element={<EditTreatment />}></Route>

              <Route path="sludge" element={<Sludge />}></Route>
              <Route path="addSludge" element={<AddSludge />}></Route>
              <Route path="editSludge" element={<EditSludge />}></Route>


              <Route path="waterPlans" element={<Plans />}></Route>
              <Route path="addWaterPlans" element={<AddPlan />}></Route>
              <Route path="editWaterPlans" element={<EditPlan />}></Route>
              <Route path="addWaterFolders" element={<AddWaterFolders />}></Route>
              <Route path="editWaterFolders" element={<EditWaterFolders />}></Route>

              <Route path="sewagePlans" element={<SewagePlans />}></Route>
              <Route path="addSewagePlans" element={<AddSewagePlan />}></Route>
              <Route path="editSewagePlans" element={<EditSewagePlan />}></Route>
              <Route path="addSewageFolders" element={<AddSewageFolders />}></Route>
              <Route path="editSewageFolders" element={<EditSewageFolders />}></Route>

              <Route path="sludgePlans" element={<SludgePlans />}></Route>
              <Route path="addSludgePlans" element={<AddSludgePlan />}></Route>
              <Route path="editSludgePlans" element={<EditSludgePlan />}></Route>
              <Route path="addSludgeFolders" element={<AddSludgeFolders />}></Route>
              <Route path="editSludgeFolders" element={<EditSludgeFolders />}></Route>


              <Route path="notifications" element={<Notification />}></Route>

              <Route path="library" element={<Library />}></Route>
              <Route path="libraryCategory" element={<LibraryCategory />}></Route>

              <Route path="followPlan" element={<FollowPlan />}></Route>
            </Route>
          </Route>
          <Route element={<RequireAuth auth={[1, 0]} />}>
            <Route path="/changePassword" element={<ChangePassword />}></Route>
            <Route path="/home" element={<MainPage />}>
              <Route path="/home" element={<MainContent />}></Route>
              {/* <Route path="waterPlansN" element={<WaterPlansN />}></Route> */}

              <Route path="waterPlans" element={<WaterPlansN />}></Route>
              <Route path="waterPlanFolders" element={<WaterPlan />}></Route>
              <Route path="waterPlanFolders01" element={<WaterPlanReview01 />}></Route>
              <Route path="waterPlanFolders02" element={<WaterPlanReview02 />}></Route>
              <Route path="waterPlanFolders03" element={<WaterPlanReview03 />}></Route>

              {/* <Route path="sewagePlans" element={<SewagePlansF />}></Route> */}
              <Route path="sewagePlans" element={<SewagePlansFN />}></Route>
              <Route path="sewagePlanFolders" element={<SewagePlan />}></Route>
              <Route path="sewagePlanFolders01" element={<SewagePlanReview01 />}></Route>
              <Route path="sewagePlanFolders02" element={<SewagePlanReview02 />}></Route>
              <Route path="sewagePlanFolders03" element={<SewagePlanReview03 />}></Route>

              {/* <Route path="sludgePlans" element={<SludgePlansF />}></Route> */}
              <Route path="sludgePlans" element={<SludgePlansFN />}></Route>
              <Route path="sludgePlanFolders" element={<SludgePlan />}></Route>
              <Route path="sludgePlanFolders01" element={<SludgePlanReview01 />}></Route>
              <Route path="sludgePlanFolders02" element={<SludgePlanReview02 />}></Route>
              <Route path="sludgePlanFolders03" element={<SludgePlanReview03 />}></Route>

              <Route path="libraryF" element={<LibraryF />}></Route>
            </Route>

          </Route>
        </Route>
        {/* <Route path="/" element={<MainPageGuest />}>
          <Route path="/" element={<MainContentGuest />}></Route>
          <Route path="publicDownloads" element={<PublicDownloads />}></Route>
        </Route> */}
        <Route path="/" element={<HomePage />}></Route>
        <Route path="/403" element={<ERR403 />}></Route>

        <Route path="/*" element={<Error404 />}></Route>
        <Route element={<RequireBack />}>
          <Route path="/login" element={<Login />}></Route>

        </Route>
      </Routes>
    </>
  );
}

export default App;
