import { Dialog } from 'primereact/dialog';
import {Field, Formik, Form, useFormik} from "formik";
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import axios from 'axios';
import {Oval} from "react-loader-spinner";
import { useState } from 'react';

export default function ApproveSludgePlan(props) {
    const [file,setFile] = useState(null)
    const [loading,setLoading] = useState(false)
    const formik = useFormik({
        initialValues: {
            doc :null,
            commit_start : new Date(),
            commit_end :new Date(),
            meet_num:0,
            meet_date:new Date()
        },
        onSubmit: async values => {
            console.log(file)
            setLoading(true)
           try {
                const data = new FormData()
               if ( file !== null ) {data.append('file', file)}
                data.append('commit_start', values.commit_start)
                data.append('commit_end', values.commit_end)
                data.append('meet_num', values.meet_num)
                data.append('meet_date', values.meet_date)
                let res = await axios.post(axios.defaults.baseURL + `/sludgeApprove/addApprove/${props.id}`, data, {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + props.token,
                    }
                })

                if ( res.status === 200 ) {
                    props.run();
                    props.close();
                }
           }catch(Err) {
            console.log(Err)
           }finally {
            setLoading(false)
           }
        },
      });
    return (
        <>
        <Dialog header="اعتماد خطة" visible={props.show} style={{minWidth:'500px',direction:"rtl" }} onHide={props.close}>
                {loading && <Oval
                    height={80}
                    width={80}
                    color="blue"
                    wrapperStyle={{}}
                    wrapperClass="spinner_container"
                    visible={true}
                    ariaLabel='oval-loading'
                    secondaryColor="gray"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                />}
                <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
                    <div className="row p-2 border-bottom" style={{marginBottom:"10px"}}>
                        <div className="col-md-8">
                        <div className="d-flex flex-column my-2">
                            <label htmlFor="commit_start" className="mb-2 fs-6"> تاريخ بداية الاعتماد</label>
                            <input type='date' id='commit_start' name='commit_start' className='form-control' value={formik.values.commit_start} onChange={formik.handleChange}/>
                        </div>
                        <div className="d-flex flex-column my-2">
                            <label htmlFor="commit_end" className="mb-2 fs-6"> تاريخ نهاية الاعتماد</label>
                            <input type='date' id='commit_end' name='commit_end' className='form-control' value={formik.values.commit_end} onChange={formik.handleChange}/>
                        </div>
                        <div className="d-flex flex-column my-2">
                        <label htmlFor="firstName">رقم الجلسة</label>
                            <input
                                id="meet_num"
                                name="meet_num"
                                type="text"
                                onChange={formik.handleChange}
                                value={formik.values.meet_num}
                                className='form-control'
                            />
                        </div>
                        <div className="d-flex flex-column my-2">
                            <label htmlFor="meet_date" className="mb-2 fs-6"> تاريخ الجلسة</label>
                            <input type='date' id='meet_date' name='meet_date' className='form-control' value={formik.values.meet_date} onChange={formik.handleChange}/>
                        </div>
                        <div className="d-flex flex-column my-2">
                            <label htmlFor="doc" className='mb-2'>شهادة الاعتماد</label>
                            <input
                            type="file"
                            name="doc"     
                            id="doc"                  
                            onChange={(event) => {
                                setFile(event.currentTarget.files[0]);
                              }}
                            className="form-control"
                            />
                  </div>
                        </div>
                    </div>
                    <Button type={"submit"}>حفظ </Button>
                </form>
        </Dialog>
        </>
    )
}