
import { Card } from 'primereact/card';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import {useContext, useEffect,  useState} from "react";
import axios from "axios";
import {User} from "../../Context";
import {Oval} from "react-loader-spinner";
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import {useNavigate} from "react-router-dom";
import ModalShow from "../../components/ModalShow";
import SystemsTableExpand from "../../components/SystemsTableExpand";
import { FilterMatchMode } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
export default function SludgeN() {
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [showDel,setShowDel] = useState(false)
    const [id,setId] = useState(0)
    const [sewage,setSewage] = useState([]);
    const user = useContext(User)
    const token = user.auth.token
    const [run,setRun]  =useState(0)
    const [loading,setLoading] = useState(false)
    const nav = useNavigate()
    const [expandedRows, setExpandedRows] = useState(null);
    const openModal = (id) => {
        setShowDel(true)
        setId(id)
    }
    useEffect(() => {
        async function getData() {
            try {
                setLoading(true)
                let res = await axios("/sludge/show",{
                    headers : {
                        Accept : "application/json",
                        Authorization : "Bearer " + token,
                    }
                }).then((data) => setSewage(data.data))
            }catch (err) {
                console.error(err)
            }finally {
                setLoading(false)
            }
        }
        getData()
    },[run])
    async function handelDeleteSewage(id) {
        try {
            let res =await axios.delete(`/sludge/delete/${id}`,{
                headers : {
                    Accept : "application/json",
                    Authorization : "Bearer " + token,
                }})
            if ( res.status === 200 ) {
                setShowDel(false)
                handleRun()
            }

        }catch(err) {
            console.error(err)
        }
    }
    function handleRun ()
    {
        setRun((prev) => prev + 1)
    }


    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <Button icon="fa-solid fa-pencil" style={{marginLeft:"5px"}} rounded outlined className="mr-2" onClick={() => nav("/controlPanelN/editSludge",{
                    state : {data : rowData}
                })} />
                <Button icon="fa-solid fa-trash" rounded outlined severity="danger" onClick={() =>
                    openModal(rowData.id)
                } />
            </>
        );
    };
    const leftToolbarTemplate = () => {
        return (
            <div className="flex flex-wrap gap-2">
                <Button rounded  aria-label="اضافة" icon="fa-solid fa-plus-circle" style={{marginLeft:"10px"}} onClick={() => nav("/controlPanelN/addSludge")} />
                <Button rounded  aria-label="تصدير" icon="fa-solid fa-download" className="p-button-help"  data-pr-tooltip="XLS"/></div>

        )
    };
    const allowExpansion = (rowData) => {
        return rowData.system_name.length > 0;
    };
    const rowExpansionTemplate = (data) => {
        return (
            <SystemsTableExpand data={data} kind={"ًصرف"}></SystemsTableExpand>
        );
    };
    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };
    const renderHeader = () => {
        return (
            <div className="flex justify-content-end">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
                </span>
            </div>
        );
    };
    const header = renderHeader();
    return (
        <div className={"mainN"} style={{backgroundColor:"transparent"}}>
            {loading && <Oval
                height={80}
                width={80}
                color="blue"
                wrapperStyle={{}}
                wrapperClass="spinner_container"
                visible={true}
                ariaLabel='oval-loading'
                secondaryColor="gray"
                strokeWidth={2}
                strokeWidthSecondary={2}

            />}

            <Card title={"انظمة الحمأة"} style={{ margin: "0 auto", width: "95%" }}>
                <Toolbar className="mb-4 " end={leftToolbarTemplate}></Toolbar>
                <DataTable 
                header={header}
                filters={filters} filterDisplay="row"
                globalFilterFields={['system_name','system_kind','company_name','system_dependency']}
                scrollable scrollHeight="400px"
                 expandedRows={expandedRows}
                            onRowToggle={(e) => setExpandedRows(e.data)}
                            value={sewage} paginator={true}
                            rowExpansionTemplate={rowExpansionTemplate}
                            rows={10} rowsPerPageOptions={[10, 15, 20, 50]}
                            tableStyle={{width:"95%",margin:"0 auto"}} size='small'>
                    <Column expander={allowExpansion} style={{ width: '5rem' }} />
                    <Column field="system_name" header="اسم النظام" style={{textAlign:"right"}} sortable></Column>
                    <Column field="system_kind" header="نوع المعالجة" style={{textAlign:"right"}} sortable></Column>
                    <Column field="system_dependency" header="التبعية" style={{textAlign:"right"}} sortable></Column>
                    <Column field="company_name" header="الشركة التابعة" style={{textAlign:"right"}} sortable></Column>
                    <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
                </DataTable>
            </Card>
            <ModalShow show={showDel} operationName={"حذف نظام حمأة"}
                       operationText={"حذف"} close={() => setShowDel(false)}
                       action={handelDeleteSewage}
                       id={id}
            />
        </div>
    )
}