import { useRef } from "react";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import wsp from '../../assets/frontend/homepage/wsp-compressed - white.png'
import ssp from '../../assets/frontend/homepage/ssp_compressed - white.png'
import san from '../../assets/frontend/homepage/English Logo-c (2).png'
import library_icon from '../../assets/frontend/homepage/library_icon.png'
export default function HomePageHero() {
    gsap.registerPlugin(ScrollTrigger)
    useGSAP(() => {
        gsap.to('.h1', {
            //    x : -500,
            opacity: 0,
            // display: "none",
            scale: .3,
            duration: 3,
            delay: 2,
            scrollTrigger: {
                trigger: ".h1",
                start: "center 20%",
                scrub: true,
                //  markers: true,
            }
        })

        gsap.to('.h2', {
            x: "100px",
            opacity: 0,
            // display: "none",
            scale: .3,
            duration: 3,
            delay: 2,
            scrollTrigger: {
                trigger: ".h1",
                start: "center 20%",
                scrub: true,
                //     markers: true,
            }
        })
       
        gsap.to('.card_wssp', {
            y: "-100px",
            opacity: 0,
            duration: 4,
            delay: 2,
            scrollTrigger: {
                trigger: ".h1",
                start: "center 10%",
                end  :"bottom 10%",
                scrub: true,
              // markers: false,
            }
        })
    })
    return (<div className="home_hero d-flex flex-column ">

        <h1 className="h1">
            الشركة القابضة لمياه الشرب والصرف الصحى
        </h1>
        <h1 className="h1">
            المنصة الرقمية للمأمونية
        </h1>

        {/* <span className="span"> المياه والصرف الصحى والحمأة</span> */}
        <div className="row mx-auto my-5">
            <div className="col-lg-3 col-6 d-flex flex-column align-items-center">
                <div onClick={() => alert("hello water")}
              
                style={{
                        background: "#75CBFF",
                    background: "linear-gradient(0deg, #36B3FF, #017CFF)",
                  }}
                    className=" d-flex flex-column align-items-center  justify-content-center card_wssp shadow rounded">

                    <img src={wsp} />
                    <span style={{  color: "#fff" }}>
                        المياه
                    </span>
                    {/* <svg className="rounded" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                        <path fill="#0099ff" fill-opacity="1" d="M0,96L34.3,96C68.6,96,137,96,206,128C274.3,160,343,224,411,224C480,224,549,160,617,122.7C685.7,85,754,75,823,106.7C891.4,139,960,213,1029,213.3C1097.1,213,1166,139,1234,106.7C1302.9,75,1371,85,1406,90.7L1440,96L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z"></path></svg> */}
                </div>
            </div>
            <div
                onClick={() => alert("hello sewage")}
                className="col-lg-3 col-6 d-flex flex-column align-items-center">
                <div
                style={{background: "#0C2A3C",background: "linear-gradient(0deg, #0C2A3C, #01162C)" }}
                className="d-flex flex-column align-items-center justify-content-center card_wssp shadow rounded" 
               >
                    <img src={ssp} />
                    <span style={{ color: "#fff" }}>
                        الصرف الصحى
                    </span>
                    {/* <svg className="rounded" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#809e3d" fill-opacity="1" d="M0,224L34.3,224C68.6,224,137,224,206,234.7C274.3,245,343,267,411,272C480,277,549,267,617,229.3C685.7,192,754,128,823,112C891.4,96,960,128,1029,122.7C1097.1,117,1166,75,1234,101.3C1302.9,128,1371,224,1406,272L1440,320L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z"></path></svg> */}
                </div>
            </div>
            <div
                onClick={() => alert("hello sanitation")}
                className="col-lg-3 col-6 d-flex flex-column align-items-center">
                <div 
                style={{ background: "#583404",background: "linear-gradient(0deg, #583404, #472A04)"}}
                className="d-flex flex-column align-items-center  justify-content-center card_wssp shadow rounded" >
                    <img src={san} className="img01"/>
                    <span style={{  color: "#fff" }}>
                        الحمأة
                    </span>
                    {/* <svg className="rounded" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#3b4e01" fill-opacity="1" d="M0,224L34.3,224C68.6,224,137,224,206,213.3C274.3,203,343,181,411,181.3C480,181,549,203,617,208C685.7,213,754,203,823,202.7C891.4,203,960,213,1029,197.3C1097.1,181,1166,139,1234,128C1302.9,117,1371,139,1406,149.3L1440,160L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z"></path></svg> */}
                </div>
            </div>
            <div
                onClick={() => alert("hello sanitation")}
                className="col-lg-3 col-6 d-flex flex-column align-items-center">
                <div 
                style={{ background: "#3D5801",background: "linear-gradient(0deg, #3D5801, #293B01)"}}
                className="d-flex flex-column align-items-center  justify-content-center card_wssp shadow rounded" >
                    <img src={library_icon} className="img02"/>
                    <span className="span4">
                        المكتبة الالكترونية
                    </span>
                    {/* <svg className="rounded" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#3b4e01" fill-opacity="1" d="M0,224L34.3,224C68.6,224,137,224,206,213.3C274.3,203,343,181,411,181.3C480,181,549,203,617,208C685.7,213,754,203,823,202.7C891.4,203,960,213,1029,197.3C1097.1,181,1166,139,1234,128C1302.9,117,1371,139,1406,149.3L1440,160L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z"></path></svg> */}
                </div>
            </div>
        </div>

    </div>)
}

