
export default function CardItem({name,value,icon,color}) {
    return (
        <>
            <div className="col"  data-aos="zoom-in">
                <div className={"card radius-10 border-" + color + " border-start border-0 border-4 mt-2"}>
                    <div className="card-body">
                        <div className="d-flex align-items-center justify-content-between">
                            <div>
                                <p className="mb-0">{name}</p>
                                <h4 className={'my-1 text-' + color}>{value}</h4>
                            </div>
                            <div className={'font-35 text-' + color}>
                                {icon}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}