import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {useContext, useEffect, useState} from "react";
import {User} from "../Context";
import axios from 'axios';

export default function ModalTrack(props) {
    const [logs,setLogs] = useState([]);
    const user = useContext(User)
    const token = user.auth.token
    
    useEffect(() => {
        async function getData() {
            
            try {
                let res = await axios.get(`/planLog/${props.kind}/${props.track.id}`, {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + token,
                    }
                })
               
                if ( res.status === 200 ) {
                    setLogs(res.data)
                }
            } catch (err) {
                console.error(err)
            }
        }
        getData()
    },[props.run])
    return (
        <Modal show={props.show} onHide={props.close}>
        <Modal.Header closeButton>
            <Modal.Title>{props.track.system_name} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div style={{maxHeight:"500px",overflow:"scroll"}}>
            {
                logs.map((item) => (
                    <div className='row p-2 mb-2 border-bottom' style={{direction:"rtl"}}>
                        <div className='col-sm-1'>
                            {item.action.includes('ارسال') ?
                                <i class="fa-solid fa-envelope-circle-check " style={{color:"orange"}}></i>
                            :
                            item.action.includes('اعتماد') ?
                                    item.action.includes('الغاء') ?
                                    <i class="fa-solid  fa-thumbs-down " style={{color:"red"}}></i>
                                    :
                                <i class="fa-solid fa-thumbs-up " style={{color:"green"}}></i>
                            :
                            item.action.includes('عرض') ?
                                <i class="fa-solid fa-eye " style={{color:"gray"}}></i>
                            :
                           
                                <i class="fa-solid fa-reply " style={{color:"red"}}></i>
                            }
                        </div>
                        <div className='col-sm-3' style={{fontSize:"12px"}}>
                            {item.created_at}
                        </div>
                        <div className='col-sm-2' style={{fontSize:"12px"}}>
                            {item.action}
                        </div>
                        <div className='col-sm-3' style={{fontSize:"12px"}}>
                            {item.action_desc}
                        </div>
                        <div className='col-sm-2' style={{fontSize:"12px"}}>
                            {item.name}
                        </div>
                    </div>
                ))
            }
            </div>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={props.close}>
                Close
            </Button>
            
        </Modal.Footer>
    </Modal>)

}