import {Link, useNavigate} from "react-router-dom";
import {useContext, useEffect, useState,useRef} from "react";
import {User} from "../../Context";
import {Oval} from "react-loader-spinner";
import { Table} from "react-bootstrap";
import axios from "axios";
import ModalShow from "../../components/ModalShow";
import { Toolbar } from "primereact/toolbar";
import { DataTable } from "primereact/datatable";
import { Card } from "primereact/card";
import { Column } from "primereact/column";
import {Button} from "primereact/button";
import {Toast} from "primereact/toast";
export default function Companies() {

    const [showDel,setShowDel] = useState(false)
    const [id,setId] = useState(0)
    const [companies,setCompanies] = useState([]);
    const [run,setRun]  =useState(0)
    const [loading,setLoading] = useState(false)
    const user = useContext(User)
    const token = user.auth.token
    const nav = useNavigate()
    const [expandedRows, setExpandedRows] = useState(null);
    const toast = useRef(null);
    const showSuccess = () => {
        toast.current.show({severity:'success', summary: 'Success', detail:'تم الاجراء بنجاح', life: 3000});
    }
    const openModal = (id) => {
        setShowDel(true)
        setId(id)
    }

    function handleRun ()
    {
        setRun((prev) => prev + 1)
    }

    useEffect(  () => {
        async function fetchData () {
            try {
                setLoading(true)
                await axios.get("/companies/show",{
                    headers : {
                        Accept : "application/json",
                        Authorization : "Bearer " + token,
                    }
                }).then((data) => setCompanies(data.data))

            }catch(err) {
                console.error(err)
            }finally {
                setLoading(false)
            }
        }
        fetchData()
    },[run])

    async function handelDeleteCompany(id) {
        try {
            let res =await axios.delete(`/companies/delete/${id}`,{
                headers : {
                    Accept : "application/json",
                    Authorization : "Bearer " + token,
                }})
            if ( res.status === 200 ) {
                setShowDel(false)

                handleRun()
                showSuccess()
            }

        }catch(err) {
            console.error(err)
        }
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <Button icon="fa-solid fa-pencil" style={{marginLeft:"5px"}} rounded outlined className="mr-2" onClick={() => nav("/controlPanel/updateCompany",{
                    state : {data : rowData}
                })} />
                <Button icon="fa-solid fa-trash" rounded outlined severity="danger" onClick={() =>
                    openModal(rowData.id)
                } />
            </>
        );
    };
    const leftToolbarTemplate = () => {
        return (
            <div className="flex flex-wrap gap-2">
                <Button rounded  aria-label="اضافة" icon="fa-solid fa-plus-circle" style={{marginLeft:"10px"}} onClick={() => nav("/controlPanel/AddCompany")} />
                <Button rounded  aria-label="تصدير" icon="fa-solid fa-download" className="p-button-help"  data-pr-tooltip="XLS"/></div>

        )
    };
    const allowExpansion = (rowData) => {
        return rowData.company.length > 0;
    };
    const rowExpansionTemplate = (data) => {
        return (
            <div className="p-3 d-flex align-items-start">
                
                <Table  bordered hover style={{width:"100%",margin:"0 auto",textAlign:"right"}}>
                    <tbody>
                        <tr>
                            <td className={"bg-light"}>الطاقة التصميمية لمحطات المياه</td>
                            <td>{data.water_design_power}</td>
                            <td className={"bg-light"}> الطاقة الفعلية لمحطات المياه</td>
                            <td>{data.water_real_power}</td>
                        </tr>
                        <tr>
                            <td className={"bg-light"}>عدد محطات المياه</td>
                            <td>{data.water_station_count}</td>
                        </tr>
                        <tr>
                            <td className={"bg-light"}>عدد محطات جوفى</td>
                            <td>{data.ground_stations_count}</td>
                            <td className={"bg-light"}> طاقة محطات جوفى</td>
                            <td>{data.ground_stations_power}</td>
                        </tr>
                        <tr>
                            <td className={"bg-light"}>عدد محطات مرشح سطحى</td>
                            <td>{data.filter_stations_count}</td>
                            <td className={"bg-light"}> طاقة محطات مرشح سطحى</td>
                            <td>{data.filter_stations_power}</td>
                        </tr>
                        <tr>
                            <td className={"bg-light"}>عدد محطات نقالى</td>
                            <td>{data.mobile_stations_count}</td>
                            <td className={"bg-light"}>طاقة محطات نقالى</td>
                            <td>{data.mobile_stations_power}</td>
                        </tr>
                        <tr>
                            <td className={"bg-light"}>عدد محطات مدمج</td>
                            <td>{data.compact_stations_count}</td>
                            <td className={"bg-light"}>طاقة محطات مدمج</td>
                            <td>{data.compact_stations_power}</td>
                        </tr>
                        <tr>
                            <td className={"bg-light"}>عدد محطات التحلية</td>
                            <td>{data.desalination_stations_count}</td>
                            <td className={"bg-light"}>طاقة محطات التحلية</td>
                            <td>{data.desalination_stations_power}</td>
                        </tr>
                        <tr>
                            <td className={"bg-light"}>الطاقة التصميمية لمحطات الصرف</td>
                            <td>{data.sanitation_design_power}</td>
                            <td className={"bg-light"}> الطاقة الفعلية لمحطات الصرف</td>
                            <td>{data.sanitation_real_power}</td>
                        </tr>
                        <tr>
                            <td className={"bg-light"}>عدد محطات الصرف الصحى</td>
                            <td>{data.sanitation_stations_power}</td>
                        </tr>
                        <tr>
                            <td className={"bg-light"}>اطوال شبكات المياه</td>
                            <td>{data.water_network_tall}</td>
                            <td className={"bg-light"}> اطوال شبكات الصرف</td>
                            <td>{data.sanitation_network_tall}</td>
                            <td className={"bg-light"}>عدد محطات الرفع لكل نظام صرف</td>
                            <td>{data.sanitation_lift_count}</td>
                        </tr>
                        <tr>
                            <td className={"bg-light"}>يوجد ادارة عامة لمأمونية المياه</td>
                            <td>{data.water_safety === 0 ? "يوجد" : "لا يوجد"}</td>

                        </tr>
                        <tr>
                            <td className={"bg-light"}>نوع مواقع التعامل الامن مع الحمأة</td>
                            <td>{data.sludge_location_kind}</td>

                        </tr>
                        <tr>
                            <td className={"bg-light"}>عدد المستهلكين</td>
                            <td>{data.consumers_count}</td>
                            <td className={"bg-light"}>عدد مشتركين المياه</td>
                            <td>{data.water_subscribers}</td>
                            <td className={"bg-light"}>عدد المشتركين صرف </td>
                            <td>{data.sanitation_subscribers}</td>
                        </tr>
                    </tbody>
                </Table>
            </div>
        );
    };
    return (
      <>
          <Toast ref={toast} position={"top-center"}/>
          {loading && <Oval
              height={80}
              width={80}
              color="blue"
              wrapperStyle={{}}
              wrapperClass="spinner_container"
              visible={true}
              ariaLabel='oval-loading'
              secondaryColor="gray"
              strokeWidth={2}
              strokeWidthSecondary={2}
          />}
          <div className="main">
              <div className="d-flex flex-row">
                  <h3 className="fs-5 m-3"> البيانات التنظيمية</h3>
                  <span className="m-3 border-end border-light-2"></span>
                  <Link to="/controlPanel/AddCompany" className="btn btn-sm btn-info text-white m-3 shadow-sm">اضافة شركة</Link>
              </div>
              <Card title={"بيانات الشركات"}>
                           <Toolbar className="mb-4 " end={leftToolbarTemplate}></Toolbar>
                           <DataTable
                                   expandedRows={expandedRows}
                                   onRowToggle={(e) => setExpandedRows(e.data)}
                                   rowExpansionTemplate={rowExpansionTemplate}
                                       value={companies} paginator={true}
                                       rows={5} rowsPerPageOptions={[5, 10, 15, 50]}
                                       tableStyle={{width:"95%",margin:"0 auto"}}>
                               <Column expander={allowExpansion} style={{ width: '5rem' }} />
                               <Column field="company" header="اسم الشركة" style={{textAlign:"right"}} sortable></Column>
                               <Column field="water_station_count" header="عدد محطات المياه"  style={{textAlign:"right"}} sortable></Column>
                               <Column field="sanitation_stations_power" header="عدد محطات الصرف"  style={{textAlign:"right"}} sortable></Column>
                               <Column field="desalination_stations_count" header="عدد محطات التحلية"  style={{textAlign:"right"}} sortable></Column>
                               <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
                               
                           </DataTable>
                       </Card>
              <ModalShow show={showDel} operationName={"حذف شركة"}
                         operationText={"حذف"} close={() => setShowDel(false)}
                         action={handelDeleteCompany}
                         id={id}
              />
          </div>
      </>
    )
}