import HomePageFeatures from "./HomePageFeatures";
import HomePageHeader from "./HomePageHeader";
import HomePageHero from "./HomePageHero";
import './HomeStyles.css'
export default function HomePage () {
    return (
        <div className="d-flex flex-column">
            <HomePageHeader/>
            <HomePageHero/>
            <HomePageFeatures />
        </div>
    ) 
}