import avatar from '../assets/avatar.jpg'
import {User} from "../Context"
import {useContext} from 'react';
import axios from "axios";
import Cookies from "universal-cookie";
import {Link, Navigate, useNavigate} from "react-router-dom";
export default function NavbarN(props) {
    const user = useContext(User)
    const cookie = new Cookies();
    const token = cookie.get("Bearer");
    const nav = useNavigate()
    async function  handleLogOut () {
        try {
            let res = await axios.post("/logout", {
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + token,
                }
            })
            if ( res.status === 200 ) {
                user.setAuth({})
                cookie.remove('Bearer')
                nav("/login");
            }
           
        }catch(err){
            console.error(err)
        }

    }
    return <div className="container-fluid">
        <div className="row">
            {/* user data */}
            <div className="d-flex align-items-center p-4 justify-content-between">
                <div style={{ width: "50px", textAlign: "center", color: "white" }}>
                    <i style={{ cursor:"pointer"}} className={props.open ? "fa-solid fa-bars d-none" : "fa-solid fa-bars"} onClick={props.toggleSidebar}></i>
                    <i  style={{ cursor:"pointer"}}  className={props.open ? "fa-solid fa-bars-staggered " : "fa-solid fa-bars-staggered d-none"}  onClick={props.toggleSidebar}></i>
                </div>
                <div>
                <Link to="/home" ><i className='fa-solid fa-home text-secondary px-2'></i></Link>
                    <i className='fa-solid fa-bell text-light px-2'></i>
                    <i onClick={handleLogOut} className='fa-solid fa-sign-out text-danger px-2'  style={{cursor:"pointer"}}></i>
                    <span className="text-light px-2">{user.auth.user.name}</span>
                    <img src={avatar} alt="avatar" className="avatar shadow-sm" />
                    
                </div>
            </div>

        </div>
    </div>
}