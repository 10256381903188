import {useContext, useEffect, useState} from "react";
import {User} from "../../Context";
import {useLocation, useNavigate} from "react-router-dom";
import { Card } from 'primereact/card';
import axios from "axios";
import {Field, Formik, Form} from "formik";
import { Button } from 'primereact/button';
import {Oval} from "react-loader-spinner";
import AddNewFolderDialog from "../../components/AddNewFolderDialog";
import ModalShow from "../../components/ModalShow";
export default function EditWaterFoldersN() {
    const [showDel,setShowDel] = useState(false)
    const [idd,setId] = useState(0)
    const [folderArray,setFolderArray] = useState([]);
    const [folderValus,setFolderValues] = useState({});
    const user = useContext(User)
    const token = user.auth.token
    const location = useLocation()
    const {data,id} = location.state
    const [loading,setLoading] = useState(false)
    const [showFolderDialog,setShowFolderDialog] = useState(false)
    const openModal = (id) => {
        setShowDel(true)
        setId(id)
    }

    useEffect(() => {
        resetFoldersArray()
    },[])

    // ********************
    // add new folder
    // ********************
    const addNewFolder = async (values) => {
        try {

            let res = await axios.post(`/waterFolders/createFolder/${id}`,
                {values},
                {
                    headers : {
                        Accept : "application/json",
                        Authorization : "Bearer " + token,
                    }})

            if ( res.status === 200 ) {
                resetFoldersArrayDb(res.data.data.data)
            }
        }catch(err) {
            console.log(err)
        }
    }
    const resetFoldersArray = () => {
        let fol = []
        let values = {}
        data.map((item,index) => {
            fol.push(`folder_${index+1}`) 
            values[`folder_${index+1}`]  = item.name
            values[`evaluate_${index+1}`]  = item.evaluate
            values[`canAdd_${index+1}`]  = [item.can_add_2 === 1 && 'can_add_2',item.can_add_3 === 1 && 'can_add_3',item.can_add_4 === 1 && 'can_add_4']
            values[`about_${index+1}`]  = item.about
            values[`id_${index+1}`]  = item.id
        })
        setFolderArray(fol)
        setFolderValues(values)
    }
    const resetFoldersArrayDb = (data0) => {
        let fol = []
        let values = {}
        data0.map((item,index) => {
            fol.push(`folder_${index+1}`) 
            values[`folder_${index+1}`]  = item.name
            values[`evaluate_${index+1}`]  = item.evaluate
            values[`canAdd_${index+1}`]  = [item.can_add_2 === 1 && 'can_add_2',item.can_add_3 === 1 && 'can_add_3',item.can_add_4 === 1 && 'can_add_4']
            values[`id_${index+1}`]  = item.id
            values[`about_${index+1}`]  = item.about
        })
        setFolderArray(fol)
        setFolderValues(values)
        setShowFolderDialog(false)
        setShowDel(false)
    }
    const addFolder = () => {
        setShowFolderDialog(true)
    }
    const deleteFolder = async (theFolder) => {
        try {
            let folderId = folderValus[`id_${theFolder.split("_")[1]}`];

            let res = await axios.delete(`/waterFolders/delete/${folderId}/${id}`,
                {
                    headers : {
                        Accept : "application/json",
                        Authorization : "Bearer " + token,
                    }})

            if ( res.status === 200 ) {
                resetFoldersArrayDb(res.data.data.data)
            }
        }catch(err) {
            console.log(err)
        }
    }
    return (
        <div className="mainN" style={{ backgroundColor:"transparent"}}>
        {loading && <Oval
                height={80}
                width={80}
                color="blue"
                wrapperStyle={{}}
                wrapperClass="spinner_container"
                visible={true}
                ariaLabel='oval-loading'
                secondaryColor="gray"
                strokeWidth={2}
                strokeWidthSecondary={2}
            />}
        {folderArray.length > 0 ?
<Card title="تعديل ملفات خطة نظام امداد" style={{ direction:"rtl",margin: "0 auto", maxWidth:"500px" }}>
<div  style={{overflow:"scroll",maxHeight:"500px" }}>
           <Button type={"button"} icon="fa-solid fa-plus" rounded text severity="secondary" aria-label="Add" onClick={() => setShowFolderDialog(true)}/>
            <Formik enableReinitialize initialValues={folderValus
            } onSubmit={async values => {
                try {
                    setLoading(true);
                    let res = await axios.post(`/waterFolders/update`,
                        {values},
                        {
                            headers : {
                                Accept : "application/json",
                                Authorization : "Bearer " + token,
                            }})
                    if ( res.status === 200 ) {
                       // nav('/controlPanel/waterPlans')
                    }
                }catch(err) {
                    console.log(err)
                }finally{
                    setLoading(false)
                }
            }}>
                <Form>
                    {folderArray.map((item,index) => (
                            <div key={index} className=" p-2 border-bottom" style={{marginBottom:"10px"}}>
                                <div className="d-flex flex-column">
                                    <div className={"row"}>
                                <div className="col-md-4 mt-3 mt-md-0">
                                    <Field disabled={index === 0} as="input" id={item} name={item} placeholder="ادخل الاسم" className="form-control"/>
                                </div>
                                <div className="col-md-3 mt-3 mt-sm-0">
                                    <Field name={`evaluate_${(index+1)}`} id={`evaluate_${(index+1)}`} as="select" className="form-control" > 
                                        <option value="0" selected>تقييم</option>
                                        <option value="1">عدم تقييم</option>
                                    </Field>
                                </div>
                                <div className='col-md-4'>
                                    <div className='d-flex mt-3 mt-sm-0'>
                                        <label>
                                            <Field type="checkbox" value={"can_add_2"} name={"canAdd_" + (index+1)} className='mx-1'/>
                                            القابضة
                                        </label>
                                        <label>
                                            <Field type="checkbox" value={"can_add_3"} name={"canAdd_" + (index+1)}  className='mx-1'/>
                                            الجهاز
                                        </label>
                                        <label>
                                            <Field type="checkbox" value={"can_add_4"} name={"canAdd_" + (index+1)} className='mx-1'/>
                                            الصحة
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-1">

                                </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-9">
                                            <Field as="input" name={"about_" + (index+1)} placeholder="وصف الفولدر" className="form-control my-2"/>
                                        </div>
                                        <div className="col-md-1">
                                            {index > 0 &&

                                                <Button type={"button"} icon="fa-solid fa-times" rounded text severity="danger" aria-label="Cancel" onClick={() =>openModal(item)}/>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    )}
                    <Button type={"submit"}>حفظ الفولدرات</Button>
                </Form>
            </Formik>
            </div>
        </Card>
        :<p className="text-center m-5 p-3 fs-3">لا يوجد فولدرات قم بالعودة واضافة فولدرات</p> }
         <AddNewFolderDialog show={showFolderDialog} close={() => setShowFolderDialog(false)} addNewFolder={addNewFolder}/>
         <ModalShow show={showDel} operationName={"حذف فولدر"}
                       operationText={"حذف"} close={() => setShowDel(false)}
                       action={deleteFolder}
                       id={idd}
            />
           
        </div>
    )
}