import {useContext} from "react";
import { User } from "./Context";
import {Navigate, Outlet} from "react-router-dom";
import Cookies from "universal-cookie";

export default function RequireAuth (props) {
    const cookie = new Cookies();
    const token = cookie.get('Bearer')
    const user = useContext(User);
    if ( token ) {
        if ( props.auth.includes(user.auth.user.auth) ) {
            return <Outlet/>
        }else {
            return <Navigate to='403' />
        }
    }else {
        return <Navigate to='login' />
    }
}
