import {useContext, useEffect, useState} from "react";
import {User} from "../../Context";
import {useLocation, useNavigate} from "react-router-dom";
import axios from "axios";
import {Button} from "primereact/button";
import Msg from "../../components/Msg";
import {Oval} from "react-loader-spinner";
import {Card} from "primereact/card";
import {Toolbar} from "primereact/toolbar";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import ModalShow from "../../components/ModalShow";
import FoldersDialog from "../../components/FoldersDialog";
import {FilterMatchMode} from "primereact/api";
import {InputText} from "primereact/inputtext";
import ApproveSludgePlan from "./ApproveSludgePlan";
import ApproveSludgePlanShow from "./ApproveSludgePlanShow";

export default function SludgePlansN() {
    const [showDel,setShowDel] = useState(false)
    const [showFolderModal,setShowFolderModal] = useState(false);
    const [id,setId] = useState(0)
    const [plans,setPlan] = useState([]);
    const user = useContext(User)
    const token = user.auth.token
    const [run,setRun]  =useState(0)
    const [loading,setLoading] = useState(false)
    const nav = useNavigate()
    const [showApprove,setShowApprove] = useState(false)
    const [showApproveData,setShowApproveData] = useState(false)
    const [filters,setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const location = useLocation()
    const openModal = (id) => {
        setShowDel(true)
        setId(id)
    }
    const openFolderModal = async (id) => {
        try {
            let res = await axios.get(`/sludgeFolders/showbyid/${id}`, {
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + token,
                }
            })
            if ( res.status === 200) {
                if ( res.data.length > 0 ) {
                    nav("/controlPanel/editSludgeFolders",{
                        state : {data : res.data,id:id}
                    })
                }else {
                    nav('/controlPanelN/addSludgeFolders',{
                        state : {data : id}
                    })
                }
            }
        } catch (err) {
            console.error(err)
        }
    }
    useEffect(() => {

        async function getData() {
            try {
                setLoading(true)
                let res = await axios("/sludgePlan/show",{
                    headers : {
                        Accept : "application/json",
                        Authorization : "Bearer " + token,
                    }
                }).then((data) => setPlan(data.data))
            }catch (err) {
                console.error(err)
            }finally {
                setLoading(false)
            }
        }
        getData()
    },[run])
    async function handelDeletePlan(id) {
        try {
            let res =await axios.delete(`/sludgePlan/delete/${id}`,{
                headers : {
                    Accept : "application/json",
                    Authorization : "Bearer " + token,
                }})
            if ( res.status === 200 ) {
                setShowDel(false)
                handleRun()
            }

        }catch(err) {
            console.error(err)
        }
    }
    function handleRun ()
    {
        setRun((prev) => prev + 1)
    }
    const approvePlan = (id) => {
        setId(id)
        setShowApprove(true)
    }
    const approvePlanShow = (id) => {
        setId(id)
        setShowApproveData(true)
    }

    const actionBodyTemplate = (rowData) => {
        if (rowData.approve_0 === 0 ) {
            return (
                <>
                    <Button icon="fa-solid fa-folder" style={{marginLeft: "5px"}} rounded outlined severity="success"
                            onClick={() =>
                                openFolderModal(rowData.id)
                            }/>
                    <Button icon="fa-solid fa-pencil" style={{marginLeft: "5px"}} rounded outlined className="mr-2"
                            onClick={() => nav("/controlPanelN/editSludgePlans", {
                                state: {data: rowData}
                            })}/>
                    <Button icon="fa-solid fa-trash" rounded outlined severity="danger" onClick={() =>
                        openModal(rowData.id)
                    }/>

                </>
            )
        }else {
            if ( rowData.approve_3 === 1 ) {
                if ( rowData.plan_cond === 0 ) {
                    return <Button icon="fa-solid fa-stamp" rounded outlined severity="primary" onClick={() => approvePlan(rowData.id)}/>
                }else {
                    return <Button icon="fa-solid fa-stamp" rounded outlined severity="primary" onClick={() => approvePlanShow(rowData.id)}/>
                }

            }

        }
    };
    const leftToolbarTemplate = () => {
        return (
            <div className="flex flex-wrap gap-2">
                <Button rounded  aria-label="اضافة" icon="fa-solid fa-plus-circle" style={{marginLeft:"10px"}} onClick={() => nav("/controlPanelN/addSludgePlans")} />
                <Button rounded  aria-label="تصدير" icon="fa-solid fa-download" className="p-button-help"  data-pr-tooltip="XLS"/></div>

        )
    };
    // const allowExpansion = (rowData) => {
    //     return rowData.plan_name.length > 0;
    // };
    // const rowExpansionTemplate = (data) => {
    //     return (
    //         <SystemsTableExpand data={data} kind={"مياه"}></SystemsTableExpand>
    //     );
    // };
    const condTemplate = (rowData) => {
        return (
            <>
                {
                    rowData.plan_cond === 0 ?
                        <span className={"text-white bg-success px-1 rounded"}>جارى</span>
                        :
                        <span className={" bg-info px-1 rounded"}>منتهية</span>
                }
            </>
        )
    }
    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };
    const renderHeader = () => {
        return (
            <div className="flex justify-content-end">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
                </span>
            </div>
        );
    };
    const header = renderHeader();
    return (
        <div className={"mainN"} style={{ backgroundColor:"transparent"}}>
            {location.state && <Msg kind={location.state.kind} message={location.state.message} show={true}></Msg>}
            {loading && <Oval
                height={80}
                width={80}
                color="blue"
                wrapperStyle={{}}
                wrapperClass="spinner_container"
                visible={true}
                ariaLabel='oval-loading'
                secondaryColor="gray"
                strokeWidth={2}
                strokeWidthSecondary={2}
            />}

            <Card title={"خطط سلامة ومأمونية الحمأة"} style={{ margin: "0 auto", width: "95%" }}>
                <Toolbar className="mb-4 " end={leftToolbarTemplate}></Toolbar>
                {/*expandedRows={expandedRows}*/}
                {/*onRowToggle={(e) => setExpandedRows(e.data)}*/}
                {/*rowExpansionTemplate={rowExpansionTemplate}*/}
                {/*value={water}*/}
                <div>
                <DataTable
                scrollable scrollHeight="400px"
                    filters={filters} filterDisplay="row"
                    globalFilterFields={['plan_name','system_name','system_dependency','company','name','plan_start']}
                    header={header}
                    value={plans}
                    size="small"
                    paginator={true} rows={10} rowsPerPageOptions={[10, 15, 20, 50]}
                    tableStyle={{ fontSize:"14px"}}
                >
                    {/*<Column expander={allowExpansion} style={{ width: '5rem' }} />*/}
                    <Column field="plan_name" header="كود الخطة" style={{textAlign:"right"}} sortable></Column>
                    <Column field="version" header="اصدار الخطة" style={{textAlign:"right"}} sortable></Column>
                    <Column field="system_name" header="نظام الحمأة" style={{textAlign:"right"}} sortable></Column>
                    <Column field="system_dependency" header="التبعية" style={{textAlign:"right"}} sortable></Column>
                    <Column field="company" header="الشركة" style={{textAlign:"right"}} sortable></Column>
                    <Column field="name" header="القائم بالخطة" style={{textAlign:"right"}} sortable></Column>
                    <Column field="review" header="مراجع الخطة" style={{textAlign:"right"}} sortable></Column>
                    <Column field="plan_start" header="بداية الخطة" style={{textAlign:"right"}} sortable></Column>
                    <Column body={condTemplate} header="حالة الخطة" style={{textAlign:"right"}} sortable></Column>
                    <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
                </DataTable>
                </div>
            </Card>
            <ModalShow show={showDel} operationName={"حذف خطة"}
                       operationText={"حذف"} close={() => setShowDel(false)}
                       action={handelDeletePlan}
                       id={id}
            />
            <FoldersDialog show={showFolderModal} close={() => setShowFolderModal(false)} id={id} token={token}></FoldersDialog>
            <ApproveSludgePlan show={showApprove} close={() => setShowApprove(false)} id={id} token={token} run={handleRun}/>
            <ApproveSludgePlanShow  show={showApproveData} close={() => setShowApproveData(false)} id={id} token={token}/>
        </div>
    )
}