
import {Link, useNavigate} from "react-router-dom";
import { useFormik } from 'formik';
import {Button} from "react-bootstrap";
import {useLocation} from "react-router-dom";
import axios from "axios";
import {useContext, useState} from "react";
import {User} from "../../Context";
import CompaniesOptions from "../companies/CompaniesOptions";
import {Oval} from "react-loader-spinner";

export default function EditUser () {
    const [loading,setLoading] = useState(false)
    const user = useContext(User)
    const token = user.auth.token
    const location = useLocation()
    const {data} = location.state
    const nav = useNavigate()
    const validate = values => {
        const errors = {};
        if (!values.name) {
            errors.name = 'حقل مطلوب';
        }

        if (!values.email) {
            errors.email = 'حقل مطلوب';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
            errors.email = 'حقل الايميل غير صالح';
        }
        if (!values.company) {
            errors.company = 'حقل مطلوب';
        }
        if (!values.auth) {
            errors.auth = 'حقل مطلوب';
        }
        if (!values.msg) {
            errors.msg = 'حقل مطلوب';
        }
        return errors;
    };
    const formik = useFormik({
        initialValues: {
            name: data.name,
            email: data.email,
            company: data.company_id,
            auth: data.auth,
            role:data.role,
            msg:data.msg
        },
        validate,
        onSubmit: async values => {
            try {
                setLoading(true)
                let res = await axios.post(`/user/update/${data.id}`,{
                        name : values.name,
                        email : values.email,
                        company: values.company,
                        auth : values.auth,
                        password : "12345678",
                        password_confirmation : "12345678",
                        role : values.role,
                        msg : values.msg
                    },{
                        headers : {
                            Accept : "application/json",
                            Authorization : "Bearer " + token,
                        }}
                )
                if ( res.status === 200 ) {
                    nav('/controlPanel/users')
                }
            }catch(err) {
                console.error(err)
            }
            finally {
                setLoading(false)
            }
        },
    });
    return (
        <>
            {loading && <Oval
                height={80}
                width={80}
                color="blue"
                wrapperStyle={{}}
                wrapperClass="spinner_container"
                visible={true}
                ariaLabel='oval-loading'
                secondaryColor="gray"
                strokeWidth={2}
                strokeWidthSecondary={2}

            />}
            <div className="main">
                <div className="d-flex flex-row  text-center">
                    <Link to="/controlPanel/users" className="btn btn-sm btn-outline-dark mb-3">{"<<"}</Link>
                    <h3 className="fs-5 mb-3 px-3 mt-1"> تعديل بيانات مستخدم</h3>
                </div>
               <div className="row bg-white border border-light-2 rounded shadow-sm mx-auto" style={{maxWidth:"500px"}}>
                   <h3 className="fs-6 m-3 text-center"> بيانات المستخدم</h3>
                   <form onSubmit={formik.handleSubmit}>
                       <div className="row p-3">
                               <label htmlFor="name" className="mb-2">الاسم</label>
                               <input
                                   id="name"
                                   name="name"
                                   type="text"
                                   className="form-control"
                                   onChange={formik.handleChange}
                                   onBlur={formik.handleBlur}
                                   value={formik.values.name}
                               />
                               {formik.touched.name && formik.errors.name ? <div className="text-danger">{formik.errors.name}</div> : null}

                           <label htmlFor="email" className="mb-2 mt-2">الايميل</label>
                           <input
                               id="email"
                               name="email"
                               type="email"
                               className="form-control"
                               onChange={formik.handleChange}
                               onBlur={formik.handleBlur}
                               value={formik.values.email}
                           />
                           {formik.touched.email && formik.errors.email ? <div className="text-danger">{formik.errors.email}</div> : null}

                               <label htmlFor="company" className="mb-2 mt-2">الجهة</label>
                           <select
                               id="company"
                               name="company"
                               onChange={formik.handleChange}
                               className="form-select" aria-label="Default select example"
                               >
                               <CompaniesOptions token={token} id={data.company_id}></CompaniesOptions>

                           </select>
                       {formik.touched.company && formik.errors.company ? <div className="text-danger">{formik.errors.company}</div> : null}

                           <label htmlFor="auth" className="mb-2 mt-2">الصلاحية</label>

                           <select
                               id="auth"
                               name="auth"
                               onChange={formik.handleChange}
                               className="form-select" aria-label="Default select example"
                               value={formik.values.auth}>
                               <option value="0" >ادمين</option>
                               <option value="1" >مستخدم</option>

                           </select>
                           {formik.touched.auth && formik.errors.auth ? <div className="text-danger">{formik.errors.auth}</div> : null}

                           <label htmlFor="role" className="mb-2 mt-2">الدور</label>
                           <select
                               id="role"
                               name="role"
                               onChange={formik.handleChange}
                               className="form-select" aria-label="Default select example"
                               value={formik.values.role}>
                               <option value="0">مستخدم</option>
                               <option value="1">مراجع قابضة</option>
                               <option value="2">مراجع جهاز تنظيمي</option>
                               <option value="3">مراجع وزارة صحة</option>
                               <option value="4" >مراجع شركة تابعة</option>
                               <option value="5" >مراجع قابضة عام</option>
                               <option value="6" >مراجع جهاز تنظيمي عام</option>
                               <option value="7" >مراجع وزارة صحة عام</option>
                           </select>
                           {formik.touched.role && formik.errors.role ? <div className="text-danger">{formik.errors.role}</div> : null}
                           <label htmlFor="msg" className="mb-2 mt-2">الرسائل</label>
                           <select
                               id="msg"
                               name="msg"
                               onChange={formik.handleChange}
                               className="form-select" aria-label="Default select example"
                               value={formik.values.msg}>
                               <option selected disabled>اختر رسائل</option>
                               <option value="0">بدون رسائل</option>
                               <option value="1">كل الرسائل</option>
                               <option value="2">رسائل امداد مياه</option>
                               <option value="3">رسائل معالجة الصرف</option>
                               <option value="4">رسائل الحمأة</option>
                           </select>
                           {formik.touched.msg && formik.errors.msg ? <div className="text-danger">{formik.errors.msg}</div> : null}
                           <Button variant="success" type="submit" className="mt-4">
                               تعديل
                           </Button>
                       </div>
                   </form>

               </div>
            </div>
        </>
    )
}