import CardItem from "./CardItem";
import wsp from "../assets/frontend/navbarIcons/wsp.png";
import ssp from "../assets/frontend/navbarIcons/ssp.png";
import ssh from "../assets/frontend/navbarIcons/ssh.png";
import library from "../assets/frontend/navbarIcons/library.png"
import {useContext, useEffect, useState} from "react";
import axios from "axios";
import {User} from "../Context";
export default function Cards() {
    const user = useContext(User)
    const token = user.auth.token
    const [data,setData] = useState(
        {
            wsp : 0,
            ssp : 0,
            ssh : 0,
            library : 0
        }
    )

    useEffect(() => {
        async function getData() {
            try {

                let res = await axios("/dashboard/show",{
                    headers : {
                        Authorization : "Bearer " + token
                    }
                }).then((data) => {
                    setData(data.data.data)
                })
            }catch (err) {
                console.error(err)
            }
        }
        getData()
    },[])
    return (
        <>
            <div className="row row-cols-1 row-cols-md-2 row-cols-xl-4 mt-2">
                <CardItem  color='primary' name={"خطط مامونية المياه"} value={data.wsp} icon={<img src={wsp} alt="" style={{maxHeight:"50px",maxWidth:"50px",marginRight:"8px"}}/>} />
                <CardItem color='success' name={"خطط مامونية الصرف"} value={data.ssp} icon={<img src={ssp} alt="" style={{maxHeight:"50px",maxWidth:"50px",marginRight:"8px"}}/>} />
                <CardItem color='warning' name={"خطط مامونية الحمأة"} value={data.ssh} icon={<img src={ssh} alt="" style={{maxHeight:"50px",maxWidth:"50px",marginRight:"8px"}}/>} />
                <CardItem color='danger' name={"المكتبة الالكترونية"} value={data.library} icon={<img src={library} alt="" style={{maxHeight:"50px",maxWidth:"50px",marginRight:"8px"}}/>} />
            </div>
        </>
    )
}