import {useEffect, useState} from "react";
import axios from "axios";

export default function GetOptions(props) {
    const [optionsData,setOptionsData] = useState([]);

    useEffect(() => {

        async function getData() {
            try {
                if ( props.kind >= 0 ) {
                    await axios("/" + props.table + "/show1/" + props.kind,{
                        headers : {
                            Accept : "application/json",
                            Authorization : "Bearer " + props.token,
                        }
                    }).then((data) => {
                        if ( props.kind == 5 ) {
                                let users = data.data.filter((item) => item.role == 5 )
                                setOptionsData(users)
                        }
                    })
                }else {
                    await axios("/" + props.table + "/show",{
                        headers : {
                            Accept : "application/json",
                            Authorization : "Bearer " + props.token,
                        }
                    }).then((data) => {
                        if ( props.company) {
                            if ( props.table === 'user') {

                                let newData = data.data.filter((item) => item.company_id == props.company)
                            
                                setOptionsData(newData)
                            }else {
                                let newData = data.data.filter((item) => item.company == props.company)
                            
                                setOptionsData(newData)
                            }
                            
                        }else {
                            setOptionsData(data.data)
                        }
                        
                    })
                }
               
            }catch (err) {
                console.error(err)
            }
        }
        getData()
    },[props.company])
    return (
        <>
            <option  disabled selected={props.id ? false : true}> اختر </option>
            {
                optionsData.map((item,index) =>
                    <option key={index} value={item.id} selected={props.id === item.id ? true : false}>{item[props.field]}</option>
                )
            }
        </>
    )
}