import {useContext} from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import {User} from "../Context"
import axios from "axios";
import Cookies from "universal-cookie";
import {Link, Navigate, useNavigate} from "react-router-dom"
export default function Navbr() {
    const user = useContext(User)
    const cookie = new Cookies();
    const token = cookie.get("Bearer");
    const nav = useNavigate()
    async function  handleLogOut () {
        try {
            let res = await axios.post("/logout", {
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + token,
                }
            })
            if ( res.status === 200 ) {
                user.setAuth({})
                cookie.remove('Bearer')
                nav("/login");
            }
           
        }catch(err){
            console.error(err)
        }

    }
    return (
        <div style={{maxHeight:'100px',width : '100%'}}>
            <Navbar expand="lg" className="bg-body-tertiary shadow-sm" style={{backgroundColor:"white !important"}}>
                <Container>
                    <Link to="/home" >الرئيسية</Link>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <div className='d-flex justify-content-between mt-sm-0 mt-10' >
                            <i onClick={handleLogOut} className="fa-solid fa-sign-out text-default" style={{marginLeft:"15px",cursor:"pointer"}}></i>
                        
                            <h4 className='text-primary fs-6'
                             style={{marginRight:"10px",marginLeft:"10px"}}>
                                {user.auth.user.name}
                                </h4>
                            </div>
                            
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    )
}