import {useContext, useState} from "react";
import {User} from "../../Context";
import {useLocation, useNavigate} from "react-router-dom";
import {useFormik} from "formik";
import axios from "axios";
import {Oval} from "react-loader-spinner";
import {Card} from "primereact/card";
import TreatmentForm from "./TreatmentForm";

export default function EditTreatment() {
    const [loading,setLoading] = useState(false)
    const user = useContext(User)
    const token = user.auth.token
    const nav= useNavigate()
    const location = useLocation()
    const {data} = location.state

    const validate = values => {
        const errors = {};
        if (!values.system_name) {
            errors.system_name = 'حقل مطلوب';
        }

        return errors;
    }
    const formik = useFormik({
        initialValues: {
            system_name: data.system_name,
            system_kind : data.system_kind,
            system_dependency : data.system_dependency,
            company : data.company,
            water_source : data.water_source,
            near_stations : data.near_stations,
            receiving_position : data.receiving_position,
            date_created : data.date_created,
            construction_contractor : data.construction_contractor,
            design_power : data.design_power,
            real_power : data.real_power,
            emp_count : data.emp_count,
            station_size : data.station_size,
            station_components : data.station_components,
            power_alternative_source : data.power_alternative_source,
            serviced_population : data.serviced_population,
            net_tall : data.net_tall,
        },
        validate,
        onSubmit: async values => {
            try {
                setLoading(true)
                let res = await axios.post(`/sewage/update/${data.id}`,{
                        system_name : values.system_name,
                        system_kind : values.system_kind,
                        system_dependency : values.system_dependency,
                        company : values.company,
                        water_source : values.water_source,
                        near_stations : values.near_stations,
                        receiving_position : values.receiving_position,
                        date_created :  values.date_created ? new Date(values.date_created).getFullYear() + "-" + ( parseInt(new Date(values.date_created).getMonth())+1) + "-" + new Date(values.date_created).getDate() : "" ,
                        construction_contractor : values.construction_contractor,
                        design_power : values.design_power,
                        real_power : values.real_power,
                        emp_count : values.emp_count,
                        station_size : values.station_size,
                        station_components : values.station_components,
                        power_alternative_source : values.power_alternative_source,
                        serviced_population : values.serviced_population,
                        net_tall : values.net_tall,
                    },{
                        headers : {
                            Accept : "application/json",
                            Authorization : "Bearer " + token,
                        }}
                )
                if ( res.status === 200 ) {
                    nav('/controlPanel/treatmentData')
                }
            }catch(err) {
                console.error(err)
            }finally {
                setLoading(false)
            }
        },
    });
    return (
        <div className={"main"}>
            {loading && <Oval
                height={80}
                width={80}
                color="blue"
                wrapperStyle={{}}
                wrapperClass="spinner_container"
                visible={true}
                ariaLabel='oval-loading'
                secondaryColor="gray"
                strokeWidth={2}
                strokeWidthSecondary={2}

            />}
            <Card title={"تعديل نظام معالجة"}>
                <TreatmentForm formik={formik} actionName={"تعديل"} token={token}> </TreatmentForm>
            </Card>

        </div>
    )
}