import logo from '../../assets/frontend/logo.png'
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
let mm = gsap.matchMedia();
export default function HomePageHeader () {
    gsap.registerPlugin(ScrollTrigger)

    useGSAP(() => {
        // gsap.to('.logo', {
        //     x : window.innerWidth *.4,
        //     y : 0,
        //     scale:.6,
        //     duration: 5,
        //     delay: 2,
        //     scrollTrigger: {
        //         trigger: ".h1",
        //         start: "100% 20%",
        //         scrub: true,
        //        markers: true,
        //     }
        // })
        // mm.add("(max-width:500px)", () => {
        //     gsap.to('.logo', {
        //         x : window.innerWidth *.3,
        //         y : 0,
        //         scale:.6,
        //         duration: 3,
        //         delay: 1,
        //         scrollTrigger: {
        //             trigger: ".h1",
        //             start: "100% 20%",
        //             scrub: true,
        //            markers: true,
        //         }
        //     })
        // })
    })

    return <div className="home_header shadow-sm">
        <img src={logo} className='logo'/>
    </div>
}