import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
export default function ModalShow(props) {

    const handleAction = () => props.action(props.id)
    return (
        <Modal show={props.show} onHide={props.close}>
        <Modal.Header closeButton>
            <Modal.Title>{props.operationName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>هل انت متأكد من انك تريد {props.operationName}</Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={props.close}>
                Close
            </Button>
            <Button variant="primary" onClick={handleAction}>
                {props.operationText}
            </Button>
        </Modal.Footer>
    </Modal>)

}