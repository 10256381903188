
export default function FormItemN(props) {
    return (
        <>
            <label htmlFor={props.name} className="mb-2 fs-6">{props.label}</label>
            <input
                id={props.name}
                name={props.name}
                type="text"
                className="form-control"
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values}
            />
        </>
    )
}