import axios from "axios";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react"
import { User } from "../../Context";
import { Oval } from "react-loader-spinner";
import { DataTable } from "primereact/datatable";
import { Card } from "primereact/card";
import { Column } from "primereact/column";
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
export default function Notification () {
    const [logs,setLogs] = useState([]);
    const [fLogs,setFLogs] = useState([]);
    const user = useContext(User)
    const token = user.auth.token
    const [loading,setLoading] = useState(false)
    const [filterPlan,setFilterPlan] =useState('')
    const [plans,setPlans] = useState([]);
    const [plan,setPlan] = useState(null);
    const [waterPlans,setWaterPlans] = useState([]);
    const [sewagePlans,setSewagePlans] = useState([]);
    const [sludgePlans,setSludgePlans] = useState([]);

    const [field1,setField1] = useState('plan_name');
    const [field2,setField2] = useState('system_name');
    const filter = () => {
        console.log(plan.id)
        if ( filterPlan.name !=='') {
            if (filterPlan.name === 'خطط امداد المياه') {
                if ( plan !== null ) {
                    setFLogs(logs.filter(item => item.water_plan_id === plan.id))
                }else {
                    setFLogs(logs.filter(item => item.water_plan_id !== 0))
                }
            }else if(filterPlan.name === 'خطط انظمة المعالجة'){
                if ( plan !== null ) {
                    setFLogs(logs.filter(item => item.sewage_plan_id === plan.id ))
                }else{setFLogs(logs.filter(item => item.sewage_plan_id !== 0))}
            }else {
                if ( plan !== null ) {setFLogs(logs.filter(item => item.sludge_plan_id === plan.id))}else{setFLogs(logs.filter(item => item.sludge_plan_id !== 0))}

            }
        }
    }
    const filterClear = () => {
        setPlan(null)
        setFilterPlan(0)
       setFLogs([])
    }
    useEffect(() => {
        async function getWaterPlans () {
           try {
                let res = await axios("/waterPlan/show",{
                    headers : {
                        Accept : "application/json",
                        Authorization : "Bearer " + token,
                    }
                }).then((data) => setWaterPlans(data.data))
           }catch(Err) {
            console.log(Err)
           }
        }
        async function getSewagePlans () {
            try {
                let res = await axios("/sewagePlan/show",{
                    headers : {
                        Accept : "application/json",
                        Authorization : "Bearer " + token,
                    }
                }).then((data) => setSewagePlans(data.data))
            }catch(Err) {
                console.log(Err)
            }
        }
        async function getSludgePlans () {
            try {
                let res = await axios("/sludgePlan/show",{
                    headers : {
                        Accept : "application/json",
                        Authorization : "Bearer " + token,
                    }
                }).then((data) => setSludgePlans(data.data))
            }catch(Err) {
                console.log(Err)
            }
        }
        async function getData() {
            try {
            
                // setDateTimeFrom(new Date())
                //setDateTimeTo(new Date())
                if ( filterPlan !== 0 ) {
                setLoading(true)
                let res = await axios.get(`/planLog/getAll`, {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + token,
                    }
                })
               
                if ( res.status === 200 ) {
                    
                    setLogs(res.data)
                }
            }
            } catch (err) {
                console.error(err)
            }finally{
                setLoading(false)
            }
        }
        getData()
        getWaterPlans()
        getSewagePlans()
        getSludgePlans()
    },[])


    const handleChangePlans = (e) => {
        filterClear()
        setFilterPlan(e.value)
        if ( e.value.name !=='') {
            if (e.value.name === 'خطط امداد المياه') {
                setField1('plan_name')
                setField2('system_name')
                setPlans(waterPlans)
            }else if(e.value.name === 'خطط انظمة المعالجة'){
                setField1('sewage_plan_name')
                setField2('treatment_name')
                setPlans(sewagePlans)
            }else if(e.value.name === 'خطط الحمأة') {
                setField1('sludge_plan_name')
                setField2('sludge_name')
                setPlans(sludgePlans)
            }
        }
    }


    return (
        <div className={"main"}>
            {loading && <Oval
                height={80}
                width={80}
                color="blue"
                wrapperStyle={{}}
                wrapperClass="spinner_container"
                visible={true}
                ariaLabel='oval-loading'
                secondaryColor="gray"
                strokeWidth={2}
                strokeWidthSecondary={2}
            />}

            <Card title={"تنبيهات الخطط"}>
               <div className="d-flex mb-3">
                    <Dropdown value={filterPlan} onChange={(e) => handleChangePlans(e)} options={[{name:'خطط امداد المياه'},{name:'خطط انظمة المعالجة'},{name:'خطط الحمأة'}]} optionLabel="name"
                                    placeholder="اختر نوع خطة" className="w-full md:w-14rem" />
                    <Dropdown filter  value={plan} onChange={(e) => setPlan(e.value)} options={plans} optionLabel="plan_name" 
                                    placeholder="اختر  خطة" className="w-full md:w-14rem mx-2" />  
                    <Button icon="fa-solid fa-filter" className="mx-2" rounded severity="success" aria-label="Search" onClick={filter}/>    
                    <Button icon="fa-solid fa-eraser" rounded severity="danger" aria-label="CLear" onClick={filterClear}/> 
               </div>
               {/* <div className="flex-auto">
                                    <label className="font-bold block mb-2">
                                        من
                                    </label>
                                    <Calendar value={datetimeFrom} onChange={(e) => setDateTimeFrom(e.value)} dateFormat="dd-mm-yy"/>
                                </div>
                                <div className="flex-auto">
                                    <label className="font-bold block mb-2">
                                        الى
                                    </label>
                                <Calendar value={datetimeTo} onChange={(e) => setDateTimeTo(e.value)} dateFormat="dd-mm-yy"/>
                                </div> */}
                {/*expandedRows={expandedRows}*/}
                {/*onRowToggle={(e) => setExpandedRows(e.data)}*/}
                {/*rowExpansionTemplate={rowExpansionTemplate}*/}
                {/*value={water}*/}
                <DataTable
                    value={fLogs}
                    paginator={true} rows={10} rowsPerPageOptions={[10, 15, 20, 50]}
                >
                    {/*<Column expander={allowExpansion} style={{ width: '5rem' }} />*/}
                    <Column field={field1} header="كود الخطة" style={{textAlign:"right"}} sortable></Column>
                    <Column field={field2} header="اسم النظام" style={{textAlign:"right"}} sortable></Column>
                    <Column field="name" header="المستخدم" style={{textAlign:"right"}} sortable></Column>
                    <Column field="action" header="الاجراء" style={{textAlign:"right"}} sortable></Column>
                    <Column field="action_desc" header="وصف الاجراء" style={{textAlign:"right"}} sortable></Column>
                    <Column field="created_at" header="التاريخ" style={{textAlign:"right"}} sortable></Column>
                </DataTable>
            </Card>
        </div>
    )
}