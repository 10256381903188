
import { useNavigate} from "react-router-dom";
import { Button } from 'primereact/button';

import {useContext,  useState} from "react";
import {User} from '../../Context'
import axios from "axios";
import Cookies from "universal-cookie";
import {Oval} from "react-loader-spinner";
import { Divider } from 'primereact/divider';
import { Password } from 'primereact/password';
        
export default function ChangePassword () {
    
    const [loading,setLoading] = useState(false)
    const user = useContext(User);
    const nav = useNavigate();
    const cookie = new Cookies();
    const [showMsg,setShowMsg] = useState(false);
    const [oldPassword,setOldPassword] = useState('')
    const [newPassword,setNewPassword] = useState('')
    const token = cookie.get("Bearer");
    const footer = (
        <>
            <Divider />
            <p className="mt-2">اقتراحات</p>
            <ul className="pl-2 ml-2 mt-0 line-height-3">
                <li>At least one lowercase</li>
                <li>At least one uppercase</li>
                <li>At least one numeric</li>
                <li>Minimum 8 characters</li>
            </ul>
        </>
    );
    async function  handleLogOut () {
        try {
            let res = await axios.post("/logout", {
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + token,
                }
            })
            if ( res.status === 200 ) {
                user.setAuth({})
                cookie.remove('Bearer')
                nav("/login");
            }

        }catch(err){
            console.error(err)
        }

    }
        const handleSubmit = async (e) => {
            e.preventDefault();
           
            try {
                setLoading(true)
                let res = await axios.post('user/changePassword',{oldPassword:oldPassword,newPassword:newPassword},{
                    headers : {
                        Accept : "application/json",
                        Authorization : "Bearer " + token,
                    }}).catch((err) => {console.log(err);setShowMsg(true)})
                    if ( res.status === 200 ) {
                        handleLogOut ()
                    }
            }catch(ex) {

            }finally {
                setLoading(false)
            }
        }
    return (
        <>

            {loading && <Oval
                height={80}
                width={80}
                color="blue"
                wrapperStyle={{}}
                wrapperClass="spinner_container"
                visible={true}
                ariaLabel='oval-loading'
                secondaryColor="gray"
                strokeWidth={2}
                strokeWidthSecondary={2}

            />}

            <div className="main" style={{direction:'rtl',height:"100vh"}}>

               <form onSubmit={handleSubmit} className="row bg-white border border-light-2 rounded shadow-sm mx-auto" style={{maxWidth:"500px",marginTop:"100px"}}>
                    <h3 className="fs-5 mb-3 px-3 mt-1 text-center mt-4"> تغيير كلمة السر</h3>
                    <div className="d-flex flex-column p-4">
                        <div className="d-flex flex-column gap-2 my-2">
                            <label htmlFor="oldPassword">كلمة السر القديمة</label>
                            <Password required value={oldPassword} onChange={(e) => setOldPassword(e.target.value)} toggleMask />
                        </div>
                        <div className="d-flex flex-column gap-2 my-2">
                            <label htmlFor="newPassword">كلمة السر الجديدة</label>
                            <Password required value={newPassword} onChange={(e) => setNewPassword(e.target.value)}  toggleMask
                             footer={footer}
                                promptLabel="اكتب كلمة مرور قوية" weakLabel="كلمة المرور بسيطة" mediumLabel="كلمة مرور متوسطة" strongLabel="كلمة مرور قوية"
                            />

                        </div>
                    </div>
                    {showMsg ? <div className="text-danger text-center py-2">يوجد خطأ ....</div> : ""}
                    <Button type="submit" label="تغيير كلمة السر" severity="success" raised className="w-50 mx-auto my-3"/>
               </form>
            </div>
        </>
    )
}