// import dr_salah from '../../assets/frontend/testimonials/dr_salah.jpeg'
// import en_raslan from '../../assets/frontend/testimonials/en_raslan.jpg'
export default function Testimonials() {
    return (
        <>
            {/*======= Testimonials Section ======= */}
            {/*<section id="testimonials" className="testimonials section-bg">*/}
            {/*    <div className="container">*/}

            {/*        <div className="section-title" data-aos="fade-up">*/}
            {/*            <h2>الداعمين </h2>*/}
            {/*        </div>*/}

            {/*        <div className="testimonials-slider swiper" data-aos="fade-up" data-aos-delay="100">*/}
            {/*            <div className="swiper-wrapper">*/}

            {/*                <div className="swiper-slide">*/}
            {/*                    <div className="testimonial-wrap">*/}
            {/*                        <div className="testimonial-item">*/}
            {/*                            <img src={en_raslan}*/}
            {/*                                 className="testimonial-img shadow-sm" alt=""/>*/}
            {/*                            <h4 className="my-2">السيد المهندس</h4>*/}
            {/*                            <h3>ممدوح رسلان</h3>*/}
            {/*                            <p>*/}
            {/*                                <i className="bx bxs-quote-alt-left quote-icon-left"></i>*/}
            {/*                                 رئيس مجلس ادارة الشركة القابضة لمياه الشرب والصرف الصحى*/}
            {/*                                <i className="bx bxs-quote-alt-right quote-icon-right"></i>*/}
            {/*                            </p>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                </div>*/}


            {/*                <div className="swiper-slide">*/}
            {/*                    <div className="testimonial-wrap">*/}
            {/*                        <div className="testimonial-item">*/}
            {/*                            <img src={dr_salah}*/}
            {/*                                 className="testimonial-img shadow-sm" alt=""/>*/}
            {/*                            <h4 className="my-2">الاستاذ الدكتور المهندس</h4>*/}
            {/*                            <h3>صلاح بيومي</h3>*/}
            {/*                            <p>*/}
            {/*                                <i className="bx bxs-quote-alt-left quote-icon-left"></i>*/}
            {/*                                نائب رئيس مجلس ادارة الشركة القابضة لمياه الشرب والصرف الصحى*/}
            {/*                                <i className="bx bxs-quote-alt-right quote-icon-right"></i>*/}
            {/*                            </p>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                </div>*/}

            {/*                <div className="swiper-pagination"></div>*/}
            {/*            </div>*/}
            {/*            /!*End testimonial item*!/*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</section>*/}
        </>
    )
}