import wsp from '../../assets/frontend/navbarIcons/wsp-small.png'
import ssp from '../../assets/frontend/navbarIcons/ssp-small.png'
import ssh from '../../assets/frontend/navbarIcons/ssh-small.png'
export default function Counts() {
    return (
        <>
            {/*======= Counts Section =======*/}
            <section id="counts" className="counts">
                <div className="container">

                    <div className="row" >
                        <div className="image col-xl-5 d-flex align-items-stretch justify-content-center"
                             data-aos="fade-right" data-aos-delay="150">
                            <img src={wsp} alt="" className="img-fluid "
                                 style={{maxWidth:"600px",maxHeight:"300px"}}/>
                        </div>

                        <div className="col-xl-7 d-flex align-items-stretch pt-4 pt-xl-0" data-aos="fade-left" data-aos-delay="300">
                            <div className="content d-flex flex-column justify-content-center">
                                <div className="row text-center">
                                 <h3 className="mb-3">مأمونية مياه الشرب</h3>
                                    <p style={{ fontSize:"14px",textAlign:"justify",textJustify:"inter-word"}} className="lh-lg">
                                        تمثل أكثر السبل فعالية لضمان سلامة موارد مياه الشرب على الدوام، في اتباع منهج شامل لتقييم اﻟﻤﺨاطر وإدارتها، يغطي جميع المراحل التي تمر بها إمدادات المياه من أحواض التجميع إلى المستهلك. ويعرف هذا المنهج بخطط مأمونية المياه.<br/>
                                        كانت هذه هي الافتتاحية الخاصة بالفصل الرابع من الطبعة الثالثة من المبادئ التوجيهية لمنظمة الصحة العالمية بشأن جودة مياه الشرب (2004) واختيارها وتبنيها لفلسفة نهج WSP كما أن هذا النهج هو نهج تشاركي بين الفاعلين في خطط مأمونية المياه، ولقد بدأت مصر بتطبيق المنهج مبكرا منذ عام 2012 من خلال التعاون  والتكامل بين الجهات المختلفة والذي أدي إلي جعل مصر من أوائل الدول على مستوى الشرق الاوسط في تطبيق الخطط طبقا لتقرير منظمة الصحة العالمية  GLASS وتعد خطط مأمونية المياه أحد النماذج الفريدة للحوكمة الرشيدة بالدولة المصرية.
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="row" style={{marginTop:"50px"}}>
                        <div className="col-xl-7 d-flex align-items-stretch pt-4 pt-xl-0 order-last order-sm-first" data-aos="fade-left" data-aos-delay="300">
                            <div className="content d-flex flex-column justify-content-center">
                                <div className="row text-center">
                                    <h3>مأمونية تداول الصرف الصحي
                                    </h3>
                                    <p style={{ fontSize:"14px",textAlign:"justify",textJustify:"inter-word"}} className="lh-lg">
                                        إن ما تعانيه مصر من تحديات مائية غير مسبوقة، وما توليه القيادة السياسية من اهتمام بالبحث الدائم عن  مصادر غير تقليدية للمياه لسد الفجوة الواسعة بين موارد المياه المتاحة والاحتياجات المائية المتزايدة؛ يأتي على رأس هذه المصادر تحلية مياه البحر وإعادة استخدام مياه الصرف الآدمي المُعَالج وعليه، فأن تلك الاستثمارات الضخمة في البنية التحتية لمنظومات مياه الشرب والصرف الصحي تحتاج إلى منهجية شاملة بما يضمن من خلالها الحوكمة الرشيدة والإدارة المستدامة لتلك الأصول. وتماشيًا مع تلك السياسات الطموحة ولتحقيق الأهداف المرجوة منها، فإن الشركة القابضة وشركاتها التابعة كانت سبّاقة في تبني مفهوم خطط التداول الآمن للصرف الصحي، والذي تقوم عليه العمليات التشاركية بين الأطراف المعنية، اعتماداً على تقييم وإدارة المخاطر لمنظومة معالجة الصرف الصحي بغرض رفع كفاءة المحطات القائمة، وتنفيذ إجراءات احترازية أو تصحيحية تهدف إلى ضمان جودة السيب النهائي والحد من التأثيرات البيئة والصحية السلبية بيئيًا ومجتمعيًا، وهو ما اشتملت عليه الإرشادات الخاصة بإعداد خطط مأمونية تداول الصرف الصحي الصادرة عن منظمة الصحة العالمية  “WHO” بتاريخ 6/8/2015
                                    </p>
                                </div>
                            </div>

                        </div>
                        <div className="image col-xl-5 d-flex align-items-stretch justify-content-center" data-aos="fade-right" data-aos-delay="150">
                            <img src={ssp} alt="" className="img-fluid" style={{maxWidth:"600px",maxHeight:"300px"}} />
                        </div>
                    </div>

                    <div className="row " style={{marginTop:"50px"}}>
                        <div className="image col-xl-5 d-flex align-items-stretch justify-content-center" data-aos="fade-right" data-aos-delay="150">
                            <img src={ssh} alt="" className="img-fluid w-auto" />
                        </div>
                        <div className="col-xl-7 d-flex align-items-stretch pt-4 pt-xl-0" data-aos="fade-left" data-aos-delay="300">
                            <div className="content d-flex flex-column justify-content-center">
                                <div className="row text-center">
                                    <h3>مأمونية تداول الحمأة
                                    </h3>
                                    <p  style={{ fontSize:"14px",textAlign:"justify",textJustify:"inter-word"}} className="lh-lg">
                                        على الرغم من أن الحمأة هي واحدة من أجزاء خطط مأمونية تداول الصرف الصحي إلا أنه علي مدار فترة تطبيق خطط مأمونية تداول الصرف الصحي بالدولة المصرية الذي بدأ من عام 2019 تبين أنها تشغل أكبر مساحة بمحطات المعالجة المختلفة كما أنها خطوة تستهلك ما يقرب من 30-40% من تكاليف التشغيل، ولا تَلقَى في العادة الاهتمام اللازم بها، وهي في ذات الوقت ذات تأثير ضار على البيئة والصحة العامة ما لم يتم إدارتها بشكل جيد في اطار  التداول الآمن لها، والتعامل معها كمنتج يمكن الاستفادة منه، كما أن هذه الخطوة متفردة نظرًا لتشعب المتعلقين بالتعامل معها سواء من داخل المحطة من العمال والفنيين المختصين بالتشغيل والصيانة، أو الأطراف الخارجية مثل المتعهدين والعمالة الخارجية والمزارعين؛ بل والمحيطين. لذا كان حتما علينا تنفيذ خطط لمأمونية الحمأة بغرض التجهيز لإعداد خريطة عن المحافظات المختلفة على مستوي الجمهورية، والبدائل الحقيقية والفعّالة، وحجم الاستثمارات المطلوبة لتحقيق مأمونية تداول الحمأة.</p>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </section>
            {/*End .content*/}
        </>
    )
}