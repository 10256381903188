import img1 from '../../assets/frontend/team/01.jpg'
import img2 from '../../assets/frontend/team/02.jpg'
import img3 from '../../assets/frontend/team/03.jpg'
import img4 from '../../assets/frontend/team/04.jpg'
import img5 from '../../assets/frontend/team/05.jpg'
import img6 from '../../assets/frontend/team/06.jpg'
import img7 from '../../assets/frontend/team/07.jpg'
import img8 from '../../assets/frontend/team/08.jpg'
import img9 from '../../assets/frontend/team/09.jpg'
import img10 from '../../assets/frontend/team/10.jpg'
import img0 from '../../assets/frontend/team/3.jpg'
import dr_salah from '../../assets/frontend/testimonials/dr_salah.jpeg'
import en_raslan from '../../assets/frontend/testimonials/en_raslan.jpg'
export default function Team() {
    return (
        <>
            <section id="team0" className="team section-bg">
                <div className="container">
                    <div className="section-title mb-4" data-aos="fade-up">
                        <h2>الداعمين</h2>

                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-md-6"></div>
                        <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
                            <div className="member" data-aos="fade-up" data-aos-delay="100">
                                <div className="member-img">
                                    <img src={en_raslan} className="img-fluid" alt=""  style={{height:"300px"}}/>

                                </div>
                                <div className="member-info">
                                    <h6 className="my-2">السيد المهندس</h6>
                                        <h2>ممدوح رسلان</h2>
                                        <p>
                                            <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                                             رئيس مجلس ادارة الشركة القابضة لمياه الشرب والصرف الصحى
                                            <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                                        </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
                            <div className="member" data-aos="fade-up" data-aos-delay="100">
                                <div className="member-img">
                                    <img src={dr_salah} className="img-fluid" alt="" style={{height:"300px"}}/>

                                </div>
                                <div className="member-info">
                                    <h6 className="my-2">الاستاذ الدكتور المهندس</h6>
                                        <h2>صلاح بيومي</h2>
                                        <p>
                                            <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                                            نائب رئيس مجلس ادارة الشركة القابضة لمياه الشرب والصرف الصحى
                                            <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                                        </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6"></div>
                    </div>
                </div>
            </section>
            {/*======= Team Section ======= */}
            <section id="team" className="team section-bg">
                <div className="container">

                    <div className="section-title mb-4" data-aos="fade-up">
                        <h2>فريق العمل</h2>

                    </div>

                    <div className="row">
                        <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
                            <div className="member" data-aos="fade-up" data-aos-delay="100">
                                <div className="member-img">
                                    <img src={img1} className="img-fluid" alt="" />

                                </div>
                                <div className="member-info">
                                    <h4>د/محمود عبد الرحمن</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
                            <div className="member" data-aos="fade-up" data-aos-delay="100">
                                <div className="member-img">
                                    <img src={img2} className="img-fluid" alt="" />

                                </div>
                                <div className="member-info">
                                    <h4>د/محمود فؤاد</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
                            <div className="member" data-aos="fade-up" data-aos-delay="100">
                                <div className="member-img">
                                    <img src={img3} className="img-fluid" alt=""/>

                                </div>
                                <div className="member-info">
                                    <h4>د/أحمد جمال</h4>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
                            <div className="member" data-aos="fade-up" data-aos-delay="100">
                                <div className="member-img">
                                    <img src={img4} className="img-fluid" alt="" />

                                </div>
                                <div className="member-info">
                                    <h4>د/علاء فريد</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
                            <div className="member" data-aos="fade-up" data-aos-delay="100">
                                <div className="member-img">
                                    <img src={img5} className="img-fluid" alt="" />

                                </div>
                                <div className="member-info">
                                    <h4>د/محمد عبد المولي</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
                            <div className="member" data-aos="fade-up" data-aos-delay="100">
                                <div className="member-img">
                                    <img src={img6} className="img-fluid" alt="" />

                                </div>
                                <div className="member-info">
                                    <h4>د/وليد حسني</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
                            <div className="member" data-aos="fade-up" data-aos-delay="100">
                                <div className="member-img">
                                    <img src={img7} className="img-fluid" alt="" />

                                </div>
                                <div className="member-info">
                                    <h4>د/محمد السيد</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
                            <div className="member" data-aos="fade-up" data-aos-delay="100">
                                <div className="member-img">
                                    <img src={img8} className="img-fluid" alt="" />

                                </div>
                                <div className="member-info">
                                    <h4>د/علاء سالم</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
                            <div className="member" data-aos="fade-up" data-aos-delay="100">
                                <div className="member-img">
                                    <img src={img9} className="img-fluid" alt="" />

                                </div>
                                <div className="member-info">
                                    <h4>د/مصطفي خلف</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
                            <div className="member" data-aos="fade-up" data-aos-delay="100">
                                <div className="member-img">
                                    <img src={img10} className="img-fluid" alt="" />

                                </div>
                                <div className="member-info">
                                    <h4>د/حسن عبدالله</h4>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </section>
            {/*End Team Section*/}
            <section id="team2" className="team section-bg">
                <div className="container">
                    <div className="section-title mb-4" data-aos="fade-up">
                        <h2>فريق الدعم الفنى</h2>

                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
                            <div className="member" data-aos="fade-up" data-aos-delay="100">
                                <div className="member-img">
                                    <img src={img0} className="img-fluid" alt="" />

                                </div>
                                <div className="member-info">
                                    <h4>أ/طارق عبد الغفار</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
                            <div className="member" data-aos="fade-up" data-aos-delay="100">
                                <div className="member-img">
                                    <img src={img4} className="img-fluid" alt="" />

                                </div>
                                <div className="member-info">
                                    <h4>د/علاء فريد</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
                            <div className="member" data-aos="fade-up" data-aos-delay="100">
                                <div className="member-img">
                                    <img src={img5} className="img-fluid" alt="" />

                                </div>
                                <div className="member-info">
                                    <h4>د/محمد عبد المولي</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}