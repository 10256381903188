import { useState } from "react";
import Navbar from "./NavbarN";
import Sidebar from "./SidebarN";

import './styles.css'
import { Outlet } from "react-router-dom";
import ChartsBlocksN from "./ChartsBlocksN";
export default function DashboardN() {
    const [openSidebar, setOpenSidebar] = useState(true)
    const toggleSidebar = () => {
        setOpenSidebar(!openSidebar)
    }
    return <div className="mainN">
        <Sidebar toggleSidebar={toggleSidebar} open={openSidebar}></Sidebar>
        <div className="main-contents">
            
                
                <div className="bg-info" style={{ width: "100%", paddingBottom: '4.5rem',height:"250px" }}>
                <Navbar toggleSidebar={toggleSidebar} open={openSidebar}></Navbar>
                
                </div>
                
                <div style={{ marginTop:"-150px"}}>
              
                <Outlet context={[toggleSidebar, openSidebar]} />
                {/* <ChartsBlocksN></ChartsBlocksN> */}
                </div>
            


        </div>
    </div>
}