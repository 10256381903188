
import NBar from "./NBar";

export default function Header() {

    return (
        <>
            <section id="header" className="fixed-top d-flex align-items-center" style={{overflow:"visible"}}>
                {/* <div className="container d-flex align-items-center justify-content-between px-md-5">
                    <div className="logo">
                        <h1><a href="/home">WSP - SSP</a></h1>
                    </div>
                </div> */}
                <div className="w-100">
                <NBar />
                </div>
            </section>
            

         
        </>

    )
}