import {Link, useNavigate} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import {User} from "../../Context";
import axios from "axios";
import Cookies from "universal-cookie";
import { useLocation } from 'react-router-dom'
import { Menubar } from 'primereact/menubar';
import logo from "../../assets/frontend/navbarIcons/menassa.png";
import hcwwLogo from "../../assets/frontend/navbarIcons/hcwwLogo.png";
import wsp from "../../assets/frontend/navbarIcons/wsp-small.png";
import ssp from "../../assets/frontend/navbarIcons/ssp-small.png";
import ssh from "../../assets/frontend/navbarIcons/ssh-small.png";
import library from "../../assets/frontend/navbarIcons/library.png"
import { Avatar } from 'primereact/avatar';
import { Badge } from 'primereact/badge';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
export default function NBar() {
    const location = useLocation();
    const user = useContext(User)
    const cookie = new Cookies();
    const token = cookie.get("Bearer");
    const nav = useNavigate()
    const [chats,setChats] = useState([])
    const [visible, setVisible] = useState(false);
    const [run ,setRun] = useState(1)
    const items = [       
        {
            label:user.auth.user.name,
            icon: 'fa-solid fa-user',
            items : [
                {
                    label : 'تسجيل الخروج',
                    icon: 'fa-solid fa-power-off',
                    command : () => {handleLogOut()},
                },
                {
                    label : 'تغيير كلمة السر ',
                    icon: 'fa-solid fa-key',
                    command : () => {nav('/changePassword')},
                }
            ]
        },
        user.auth.user.auth === 0 && {
            label:<div  style={{fontSize:"12px"}}>لوحة التحكم</div> ,
            command : () => {nav('/controlPanelN')},
            icon: 'fa-solid fa-cog',
        },
        {
            label: <div style={{fontSize:"12px"}}><img src={ssh} alt="" style={{maxHeight:"50px",maxWidth:"35px",marginRight:"4px"}}/>خطط مأمونية الحمأة</div>,
            command : () => {nav('/home/sludgePlans')},
        },
        {
            label: <div style={{fontSize:"12px"}}><img src={ssp} alt="" style={{maxHeight:"25px",maxWidth:"25px",marginRight:"4px"}}/>خطط مأمونية تداول الصرف الصحي</div>,
            command : () => {nav('/home/sewagePlans')},
        },
        {
            label: <div style={{fontSize:"13px"}}><img src={wsp} alt="" style={{maxHeight:"25px",maxWidth:"25px",marginRight:"4px"}} />خطط مأمونية المياه</div>,
            command : () => {nav('/home/waterPlans')},
        },
        {
            label:<div  style={{fontSize:"12px"}}><img src={library} alt="" style={{maxHeight:"25px",maxWidth:"25px",marginRight:"4px"}}/>المكتبة الالكترونية </div> ,
            command : () => {nav('/home/libraryF')},
        },
        {
            label:<div  style={{fontSize:"12px"}}>الرئيسية</div> ,
            command : () => {nav('/home')},
            icon: 'fa-solid fa-home',
        },
        {
            label:<Avatar className="p-overlay-badge" icon="fa-solid fa-envelope text-warning">
                        <Badge value={chats.filter((item) => item.read ===0).length} style={{ color:"white", fontSize:"10px"}} className="bg-danger"/>
                    </Avatar>
                  ,
            command : () => {setVisible(true)},
        },

    ];
    useEffect(() => {
        async function getMessages() {
            try {
                await axios.get(`/chat/show/${user.auth.user.id}`, {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + token,
                    }}).then((data) => {
                        setChats(data.data)
                    })
            }catch(er){
                console.error(er)
            }
        }
        getMessages()
    },[run])
    async function  handleLogOut () {
        try {
            let res = await axios.post("/logout", {
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + token,
                }
            })
            if ( res.status === 200 ) {
                user.setAuth({})
                cookie.remove('Bearer')
                nav("/login");
            }

        }catch(err){
            console.error(err)
        }

    }
    const end = <>
        <img alt="hcwwLogo" src={hcwwLogo} height="40" className="mx-1"></img>
        <img alt="logo" src={logo} height="40" className="mx-1"></img>
    </>;
  
    const confirm1 = (msg,id,rd) => {
        async function setRead() {
            try {
                await axios.post(`/chat/setRead/${id}`,null, {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + token,
                    }})
                    setRun(run + 1)
            }catch(er){
                console.log(er)
            }
        }
        setRead();
        confirmDialog({
            message: <div>
                <p>{msg[0]}</p>
                <p>{msg[1]}</p>
                <p>{msg[2]}</p>
                <p>{msg[3]}</p>
            </div>,
            header: 'رسالة',
            icon: rd === 1 ? 'fa-solid fa-envelope-open' : "fa-solid fa-envelope" ,
            position : 'top',
            rtl:true,
          
        });
    };
    return (
        <>
        <ConfirmDialog />
        <div style={{direction:"ltr"}}>
            <Menubar model={items} end={end} style={{border:"none",backgroundColor:"white"}}/>
            
        </div>
        <Dialog header="الرسائل"  position={'top'} maximizable  visible={visible} style={{ width: '50vw'}} breakpoints={{ '960px': '75vw', '641px': '100vw' }} onHide={() => setVisible(false)}>

                    <div className="d-flex flex-column">
                        {chats.map((item,index) => 
                        {
                            let segs = item.msg.split('$ln$');
                            return (
                            <div key={index} className={item.read === 1 ? "row rounded shadow-sm mb-2 bg-secondary text-light" : "row rounded shadow-sm mb-2"} >
                            <div className="col-sm-1 my-sm-0 my-2"><i className={item.read === 0 ? "fa-solid fa-envelope text-success" : "fa-solid fa-envelope-open text-white" }></i></div>
                            <div className="col-sm-6 my-sm-0 my-2">
                                <span>{segs[0] } ... </span>
                            </div>
                            <div className="col-sm-3 my-sm-0 my-2">{new Date(item.created_at).toLocaleDateString('en-GB')}</div>
                            <div className="col-sm-2 my-sm-0 my-2"><Button onClick={() => confirm1(segs,item.id,item.read)} icon="fa-solid fa-eye" style={{ borderRadius:"50%"}} size="small" text severity={item.read === 0 ? "info" : "success"}  /></div>
                        </div>
                        )})}
                       
                    </div>
            </Dialog>
        </>
    )
}