import axios from 'axios';
import { Dialog } from 'primereact/dialog';
import {useEffect, useState} from 'react';
import {Oval} from "react-loader-spinner";
import { Dropdown } from 'primereact/dropdown';
export default function UploadFile(props) {
    const [file,setFile] = useState("")
    const [loading,setLoading] = useState(false)
    const [folder,setFolder] = useState(0)
    const [folders,setFolders]=useState([])
    useEffect(() => {
        async function getFolders() {
            try {
                await axios.get(`/libraryFolder/show/${props.id}`,{
                    headers : {
                        Accept : "application/json",
                        Authorization : "Bearer " + props.token,
                    }
                }).then((data) => {
                    setFolders(data.data)
                    setFolder(0)
                })
            }catch (err){
                console.log(err)
            }
        }
        if (props.show === true) {
            getFolders()
        }
    },[props.show])
   const submitHandler = async (e) => {

        e.preventDefault();

        setLoading(true)
                const data = new FormData()
                data.append('file', file)
                if ( folder !== 0 ) {
                    data.append("library_folder_id",folder.id)
                }


        try{
                let res = await axios.post(axios.defaults.baseURL + `/librarydoc/upload/${props.id}`, data, {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + props.token,
                    }
                })
                if (res.status === 200) {
                    props.run();
                    props.close();
                }

        }catch (err) {
            console.error(err)
        }finally {
            setLoading(false)
        }
   }
    const handleChange = (e) => {
        setFile(e.target.files[0])
    }
    return (
        <>
        
        <Dialog header="رفع ملف " visible={props.show} style={{ maxWidth: '80vw',direction:"rtl" }} onHide={props.close}>
       
            <div className="card">
            {loading && <Oval
            height={80}
            width={80}
            color="blue"
            wrapperStyle={{}}
            wrapperClass="spinner_container"
            visible={true}
            ariaLabel='oval-loading'
            secondaryColor="gray"
            strokeWidth={2}
            strokeWidthSecondary={2}
        />}
            <form onSubmit={submitHandler} encType="multipart/form-data" id="imageForm">
                <div className="m-2 w-100">
                    <Dropdown value={folder} onChange={(e) => setFolder(e.value)} options={folders} optionLabel="folder_name"
                              placeholder="اختر فولدر" style={{width:"90%"}} />
                </div>
                <div className="form-group p-4">
                    <label htmlFor="file" className='mb-2'>اختر ملف لرفعه</label>
                    <input
                      type="file"
                      name="file"                      
                      onChange={handleChange}
                      className="form-control"
                    />
                  </div>
                  <div className="card-footer">
                    <button type="submit" className="btn btn-success">
                        Upload
                    </button>
                </div>
            </form>
            </div>
        </Dialog>
        </>
    )
}