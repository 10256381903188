import { useContext, useState } from "react";
import { User } from "../../Context";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import axios from "axios";
import { Oval } from "react-loader-spinner";
import GetOptions from "../../components/GetOptions";
import { Button } from "react-bootstrap";
import { Calendar } from "primereact/calendar";
import { Card } from "primereact/card";
export default function EditSludgePlanN() {
    const [loading, setLoading] = useState(false)
    const user = useContext(User)
    const token = user.auth.token
    const nav = useNavigate()
    const location = useLocation()
    const { data } = location.state
    const validate = values => {
        const errors = {};
        if (!values.plan_name) {
            errors.plan_name = 'حقل مطلوب';
        }
        if (!values.sludge_id) {
            errors.sludge_id = 'حقل مطلوب';
        }
        if (!values.user_id) {
            errors.user_id = 'حقل مطلوب';
        }
        if (!values.version) {
            errors.version = 'حقل مطلوب';
        }
        if (!values.plan_start) {
            errors.plan_start = 'حقل مطلوب';
        }
        if (!values.reviewer) {
            errors.reviewer = 'حقل مطلوب';
        }
        return errors;
    };
    const formik = useFormik({
        initialValues: {
            plan_name: data.plan_name,
            sludge_id: data.sludge_id,
            user_id: data.user_id,
            version: data.version,
            plan_start: data.plan_start,
            reviewer: '',
        },
        validate,
        onSubmit: async values => {
            try {
                setLoading(true)
                let res = await axios.post(`/sludgePlan/update/${data.id}`, {
                    plan_name: values.plan_name,
                    sludge_id: values.sludge_id,
                    user_id: values.user_id,
                    version: values.version,
                    plan_start: values.plan_start ? new Date(values.plan_start).getFullYear() + "-" +
                        (parseInt(new Date(values.plan_start).getMonth()) + 1) + "-" + new Date(values.plan_start).getDate() : "",
                    reviewer: values.reviewer
                }, {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + token,
                    }
                }
                )
                if (res.status === 200) {
                    nav('/controlPanelN/sludgePlans')
                }
            } catch (err) {
                console.error(err)
            }
            finally {
                setLoading(false)
            }
        },
    });
    return (
        <div className="mainN" style={{ backgroundColor: "transparent" }}>
            {loading && <Oval
                height={80}
                width={80}
                color="blue"
                wrapperStyle={{}}
                wrapperClass="spinner_container"
                visible={true}
                ariaLabel='oval-loading'
                secondaryColor="gray"
                strokeWidth={2}
                strokeWidthSecondary={2}

            />}
            <Card title={" تعديل خطة مامونية الصرف الصحى"} style={{ margin: "0 auto", maxWidth: "500px" }}>
            <div  style={{overflow:"scroll",maxHeight:"500px" }}>
                <div className="row bg-white border border-light-2 rounded shadow-sm mx-auto" style={{ maxWidth: "500px" }}>
                    <h3 className="fs-6 m-3 text-center"> بيانات الخطة</h3>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="row p-3">
                            <label htmlFor="plan_name" className="mb-2">كود الخطة</label>
                            <input
                                id="plan_name"
                                name="plan_name"
                                type="text"
                                className="form-control"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.plan_name}
                            />
                            {formik.touched.plan_name && formik.errors.plan_name ? <div className="text-danger">{formik.errors.plan_name}</div> : null}

                            <label htmlFor="version" className="mb-2">اصدار الخطة</label>
                            <input
                                id="version"
                                name="version"
                                type="text"
                                className="form-control"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.version} />
                            {formik.touched.version && formik.errors.version ? <div className="text-danger">{formik.errors.version}</div> : null}


                            <label htmlFor="sludge_id" className="mb-2 mt-2">نظام معالجة الحمأة</label>
                            <select
                                id="sludge_id"
                                name="sludge_id"
                                onChange={formik.handleChange}
                                className="form-select" aria-label="Default select example"
                            >
                                <GetOptions token={token} table={"sludge"} field={"system_name"} id={data.sludge_id}></GetOptions>

                            </select>
                            {formik.touched.sludge_id && formik.errors.sluge_id ? <div className="text-danger">{formik.errors.sludge_id}</div> : null}
                            <label htmlFor="user_id" className="mb-2 mt-2">مستخدم بداية الخطة</label>
                            <select
                                id="user_id"
                                name="user_id"
                                onChange={formik.handleChange}
                                className="form-select" aria-label="Default select example"
                            >
                                <GetOptions token={token} table={"user"} field={"name"} id={data.user_id}></GetOptions>

                            </select>
                            <label htmlFor="reviewer" className="mb-2 mt-2">مراجع الخطة</label>
                            <select
                                id="reviewer"
                                name="reviewer"
                                onChange={formik.handleChange}
                                className="form-select" aria-label="Default select example">
                                <GetOptions token={token} table={"user"} field={"name"} kind={5}></GetOptions>

                            </select>
                            {formik.touched.reviewer && formik.errors.reviewer ? <div className="text-danger">{formik.errors.reviewer}</div> : null}

                            <label htmlFor="date_created" className="mx-2 fs-6"> تاريخ بدء الخطة</label>
                            <Calendar className="" dateFormat={"yy-mm-dd"} inputId={"plan_start"} name={"plan_start"}
                                value={formik.values.plan_start && new Date(formik.values.plan_start)} onChange={(e) => {
                                    formik.setFieldValue('plan_start', e.target.value);
                                }} />
                            {formik.touched.user_id && formik.errors.user_id ? <div className="text-danger">{formik.errors.user_id}</div> : null}
                            <Button variant="success" type="submit" className="mt-4">
                                تعديل الخطة
                            </Button>
                        </div>
                    </form>

                </div>
                </div>
            </Card>
        </div>
    )
}